import React, { Component } from 'react';
import { TouchableOpacity, StyleSheet, Text } from 'react-native';
import { Colors } from '../global/Constants.js';
import window from '../global/Layout.js'

export default class MainButton extends Component {

  render() {
    return (
      <TouchableOpacity onPress={this.props.onPress}
        style={[styles.mainButtonStyle, {
          backgroundColor:
            this.props.color ? (this.props.color) :
            ((this.props.active) ? (Colors.ppMainPurple) : (Colors.ppGreyDisabled)),
          borderColor:
            this.props.color ? (this.props.color) :
              ((this.props.active) ? (Colors.ppMainPurple) : (Colors.ppGreyDisabled)),
          borderRadius: (this.props.square) ? 5 : 10,
          width: (this.props.small) ? (window.window.width / 12) : (window.window.width / 6),
        }]}>
          <Text style={styles.buttonText}>{this.props.text}</Text>
      </TouchableOpacity>
    )
  }
}


const styles = StyleSheet.create({
  mainButtonStyle: {
    marginTop: 20,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: window.window.height / 16,
  },
  buttonText: {
    color: 'white',
    fontSize: window.window.height / 40,
    fontWeight: 'bold',
    textAlign: 'center'
  },
});
