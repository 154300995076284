import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { searchItem } from "../global/Functions";
import { SearchBar } from "react-native-elements";
import { getUser, getAllCourseIDs, getCourseList, getAllSessionIDs,
  getSessionList, getAllDatasetIDs, getDatasetList, getAllUserIDs,
  getUserList, getCourse} from "../custom-modules/backendAPI";
import SearchList from "./SearchList";
import window from "../global/Layout";

export default class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: "",
      allData: [],
      filteredData: [],
      user: {
        _id: "",
        roles: [],
        course_list: [],
        course_objects: [],
      },
    };
  }

  componentDidMount() {
    // fetch raw data & update
    getUser(sessionStorage.getItem("UserId"), true).then((_user) => {
      if (_user) {
        // if non-empty result
        this.setState({ user : _user});
      }
    });
    // Add all items to the search bar
    if (this.props.type == "sessions") {
      this.setSessions();
    } else if (this.props.type == "courses") {
      this.setCourses();
    } else if (this.props.type == "datasets") {
      this.setSessions();
    } else if (this.props.type == "members") {
      this.setMembers();
    }
  }

  setCourses() {
    getAllCourseIDs().then((_result) => {
      getCourseList(_result).then((_cResults) => {
        _cResults.forEach((item) => {
          this.state.allData.push({
            _id: item._id,
            code: item.code,
            text: item.code + " - " + item.title,
          });
          this.setState({
            ...this.state,
            allData: this.state.allData,
            filteredData: this.state.allData,
          });
        });
      });
    });
  }

  setSessions() {
    getAllSessionIDs().then((_result) => {
      getSessionList(_result).then((_sResults) => {
        _sResults.forEach((item) => {
          getCourse(item.course_id, true).then(_course => {
            if (_course) {
              this.state.allData.push({
                _id: item._id,
                text: item.title + " (" + _course.title + ")",
              });
            }
            this.setState({
              ...this.state,
              allData: this.state.allData,
              filteredData: this.state.allData,
            });
          })
        });
      });
    });
  }

  setDatasets() {
    getAllDatasetIDs().then((_result) => {
      getDatasetList(_result).then((_sResults) => {
        _sResults.forEach((item) => {
          this.state.allData.push({
            _id: item._id,
            text: item.title,
          });
        });
        this.setState({
          ...this.state,
          allData: this.state.allData,
          filteredData: this.state.allData,
        });
      });
    });
  }

  setMembers() {
    getAllUserIDs().then((_result) => {
      getUserList(_result).then((_sResults) => {
        _sResults.forEach((item) => {
          this.state.allData.push({
            _id: item._id,
            text: item.first_name + " " + item.last_name,
          });
        });
        this.setState({
          ...this.state,
          allData: this.state.allData,
          filteredData: this.state.allData,
        });
      });
    });
  }

  // Update search term
  updateSearch(newSearchTerm) {
    let filteredDataResult = searchItem(
      this.state.allData,
      newSearchTerm,
      "partial"
    );
    this.setState({
      ...this.state,
      searchTerm: newSearchTerm,
      filteredData: filteredDataResult,
    });
  }

  render() {
    return (
      <View style={styles.searchBarView}>
        <SearchBar
          placeholder={this.props.route.params.text}
          onChangeText={(text) => this.updateSearch(text)}
          value={this.state.searchTerm}
          lightTheme={true}
          maxLength={35}
        />
        <SearchList
          data={this.state.filteredData}
          user_id={sessionStorage.getItem("UserId")}
          change_id_list={this.props.change_id_list}
          limit_to_one={this.props.limit_to_one}
          update_settings={this.props.update_settings}
          entity_id={this.props.entity_id}
          type={this.props.type}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  searchBarView: {
    marginTop: 30,
    borderRadius: 30,
    marginBottom: 25,
    backgroundColor: "white",
    width: window.window.width / 1.5,
    height: window.window.height / 2,
  },
});
