import * as React from 'react';
import { StyleSheet, View, ScrollView, Text, FlatList } from 'react-native';
import { useState } from 'react';
import SideMenu from '../components/SideMenu';
import window from '../global/Layout';
import SeparatingLine from '../components/SeparatingLine';
import BackButton from '../components/BackButton';
import QuestionLiveComments from '../components/QuestionLiveComments';
import InstructorNotes from '../components/InstructorNotes';
import { Colors } from '../global/Constants';
import Logo from '../components/Logo';
import MainButton from '../components/MainButton';
import QuestionContainerStudent from '../components/QuestionContainerStudent';
import Swiper from 'react-native-web-swiper';
import { getPresentation } from '../custom-modules/backendAPI';
import MembersContainer from '../components/MembersContainer';

export default function PresentationScreen({route, navigation}) {


  const [presentation, setPresentation] = useState(route.params.presentation ?
                                route.params.presentation : null);
  const secondaryTitle = route.params.sessionTitle + " > " + presentation.title;
    

  React.useEffect(() => {
    loadData();
  }, [navigation])

  function loadData() {
    getPresentation(route.params.presentation._id, true).then(_pres => {
      if (_pres) {
        setPresentation(_pres);
      }
    })
  }
  
  return (
    <View style={styles.screenContainer}>
      <View style={{flexDirection: 'row', flex: 1}}>
        <SideMenu navigation={navigation} />
        <View style={styles.logoAndScrollContainer}>
          <Logo />
          <View style={styles.upperButtonContainer} />
          <BackButton backTo={"Session"} navigation={navigation}/>
          <View style={{marginStart: window.window.width / 6}} >
            <SeparatingLine />
          </View>
          <ScrollView style={styles.scrollViewContainer}>
            <Text style={styles.title}>{presentation.courseTitle} {presentation.courseCode}</Text>
            <Text style={styles.secondaryTitle}>{secondaryTitle}</Text>
            <Text style={styles.smallTitle}>Presenter information</Text>
            <View style={styles.memberContainer}>
              <View style={styles.presentationNameAndMembers}>
                  <Text style={styles.presentation_title}>{presentation.title}</Text>
                  <FlatList
                    data={presentation.presenter_objects}
                    scrollEnabled={true}
                    horizontal={true}
                    keyExtractor={(item) => item._id}
                    renderItem={({item}) => (
                      (presentation.presenter_objects[presentation.presenter_objects.length - 1].username != item.username)
                        ?
                          <Text style={styles.first_name}>{item.first_name}, </Text>
                        :
                          <Text style={styles.first_name}>{item.first_name}</Text>
                      )}
                  />
              </View>
              <MembersContainer presentation_id={presentation._id} navigation={navigation} />
            </View>
            { sessionStorage.getItem('Role') != "listener"
            ?
            // Live comments and instructor notes view for instructors
              <View style={styles.questionsNotesContainer}>
                <View>
                  <Text style={styles.smallTitle}>Live Comments</Text>
                  <View style={styles.questionsContainer}>
                    <Swiper>
                      {presentation.question_list.length == 0 &&
                        <View style={styles.noQuestionsMessage}>
                          <Text style={{fontWeight: 'bold', fontSize: 18}}>No questions exist</Text>
                        </View>
                      }
                      {presentation.question_list.map((question_id, i) => {
                        return (
                          <View key={i}>
                            <QuestionLiveComments question_id={question_id}
                              question_index={i+1}/>
                          </View>
                        )
                      })}
                    </Swiper>
                  </View>
                  {presentation.question_list.length > 0 &&
                    <View style={{alignSelf: 'flex-end'}}>
                      <MainButton active={true} text={"View All"} small={true}
                      square={false} onPress={() => {navigation.navigate("Questions", {
                        question_list: presentation.question_list,
                        presentation_id: presentation._id,
                        updateData: route.params.updateData,
                        session_id: presentation.session_id,
                      })}} />
                    </View>
                  }
                </View>
                <View>
                  <Text style={styles.smallTitle}>Notes for Presentation</Text>
                  <InstructorNotes presentation={presentation} />
                </View>
              </View>
            :
            // Questions view for students
            <View style={styles.questionsNotesContainer}>
              <QuestionContainerStudent questions={presentation.question_list}
              presentation={presentation}
              navigation={navigation}
              updateDate={route.params.updateData}
              />
            </View>
            }
          </ScrollView>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  logoAndScrollContainer: {
    justifyContent: 'center',
  },
  scrollViewContainer: {
    marginStart: window.window.width / 6,
    backgroundColor: Colors.backgroundColor,
  },
  screenContainer: {
    flex: 1,
    flexDirection: "column",
  },
  title: {
    fontSize: window.window.height / 30,
    marginTop: window.window.height / 30,
    marginEnd: window.window.width / 30,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  secondaryTitle: {
    fontSize: window.window.height / 45,
    marginTop: window.window.height / 45,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  presentation_title: {
    fontSize: window.window.height / 35,
    marginTop: 10,
    fontWeight: 'bold',
  },
  first_name: {
    fontSize: window.window.height / 50,
    marginTop: window.window.height / 35,
    fontWeight: 'bold',
  },
  smallTitle: {
    fontSize: window.window.height / 45,
    marginTop: window.window.height / 45,
    marginEnd: window.window.width / 30,
    marginBottom: 10,
    color: Colors.ppGreyText,
    fontWeight: 'bold',
  },
  upperButtonContainer: {
    alignSelf: 'flex-end',
    marginBottom: window.window.height / 20,
  },
  memberContainer: {
    marginTop: 30,
    borderRadius: 30,
    marginBottom: 25,
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: window.window.width / 1.5,
    height: window.window.height / 5,
  },
  questionsNotesContainer: {
    flexDirection: 'row',
    marginTop: 15,
    marginBottom: 50,
    width: window.window.width / 1.5,
    justifyContent: 'space-between',
  },
  questionsContainer: {
    borderRadius: 30,
    marginBottom: 10,
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: window.window.height / 30,
    width: window.window.width / 3.2,
    height: window.window.height / 1.5,
  },
  noQuestionsMessage: {
    marginTop: window.window.height / 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  presentationNameAndMembers: {
    width: window.window.width / 3,
    marginTop: window.window.height / 30,
    marginStart: 20
  },
});