import React, { Component } from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import window from '../global/Layout.js'


export default class CourseCard extends Component {

  render() {
    return (
      <TouchableOpacity style={styles.courseCard} onPress={this.props.onPress}>
        { !this.props.plusIcon
          ?
          <View style={{flex: 1}}>
            <View style={{flex: 3, backgroundColor: this.props.courseColor, borderRadius: 20}} />
            <View style={styles.courseCardContent}>
              { this.props.children }
            </View>
          </View>
          :
          <View style={styles.courseCardContentPlusButton}>
            { this.props.children }
          </View>
        }
      </TouchableOpacity>
    )
  }
}


const styles = StyleSheet.create({
  courseCard: {
    elevation: 4,
    borderRadius: 20,
    shadowRadius: 2,
    shadowOpacity: 0.4,
    shadowColor: '#232944',
    flexDirection: 'column',
    backgroundColor: 'white',
    shadowOffset: { width: 1, height: 1 },
    marginVertical: window.window.width / 70,
    marginHorizontal: window.window.width / 70,
    width: window.window.width / 6,
    height: window.window.width / 8,
  },
  courseCardContent: {
    flex: 1,
    marginVertical: 15,
    marginHorizontal: 15,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  courseCardContentPlusButton: {
    flex: 1,
    marginVertical: 15,
    marginHorizontal: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
