import React, { Component } from 'react';
import { StyleSheet, View, Text, FlatList,
  TouchableOpacity, Platform } from 'react-native';
import { Colors } from '../global/Constants';
import window from '../global/Layout.js';
import { getUser, getReactList, getResponse, updateResponse,
  createReact } from '../custom-modules/backendAPI';
import ReactionItem from './ReactionItem';
import { Reactions } from '../global/Constants';
import { UserId } from '../data/UserData';

export default class ReactionsContainer extends Component {

  constructor(props) {

    super(props);

    this.state = {
      response_object : this.props.response,

      user_of_comment : {},
      reaction_objects: null,

      react_count_dict: [],
      react_list_refresh: false,

      addReactionFlag: false,
    }
  }

  componentDidMount() {
    getUser(this.state.response_object.listener_id, true).then(_user => {
      if (_user) {
        this.setState({
          user_of_comment : _user,
        })
      }
    })
 
    // Get reactions for this comment
    getReactList(this.state.response_object.react_list).then(_result => {
      if (_result) {
        let react_count_dict = [
          {id: "1", count: 0, users: []},
          {id: "2", count: 0, users: []},
          {id: "3", count: 0, users: []},
          {id: "4", count: 0, users: []},
          {id: "5", count: 0, users: []},
        ]
        _result.map(item => {
          // Minus one in order to match 0-4 (not 1-5)
          react_count_dict[parseInt(item.react_code) - 1].count++;
          react_count_dict[parseInt(item.react_code) - 1].users
                                                      .push(item.listener_id);
        })
        this.setState({
          reaction_objects: _result,
          react_count_dict: react_count_dict
        })
      }
    })
  }

  // Add reaction for this comment to the database
  addReaction = (react_code) => {
    // Users can only add one reaction per type per comment
    if (this.state.react_count_dict[react_code-1].users.includes(UserId)) {
      if (Platform.OS == 'web') {
        alert("You already reacted with this emoji")
      } else {
        Alert.alert(
          "Error",
          "You already reacted with this emoji",
          [ { text: "OK" } ] )
      }
    } else {
      let newReaction = {
        _id: "0",
        listener_id: UserId,
        react_code: react_code,
        response_id: this.props.response._id,
      }
      
      createReact(newReaction).then(_id => {
        if (_id) { // if non-empty result
          // Update React
          getResponse(this.props.response._id).then(_result => {
            let react_list = _result.react_list;
            react_list.push(_id);
            updateResponse(this.props.response._id,
                          {react_list : react_list}).then(_update_result => {});
          })
          let newReactList = this.state.react_count_dict;
          newReactList[parseInt(react_code) - 1].count++;
          newReactList[parseInt(react_code) - 1].users.push(UserId);
          // Update react list in comment view
          this.setState({
            react_count_dict: newReactList,
            react_list_refresh: !this.state.react_list_refresh,     
            addReactionFlag: false,       
          });
        }
      })
    }
  }

  render() {
    return (
      <View style={styles.reactionsContainer}>
        <FlatList
        // Take into flatlist only reactions with count > 0
        data={this.state.react_count_dict
              .filter(react => react.count > 0)}
        numColumns={10}
        listKey={(item) => item.id}
        style={{ flexDirection: 'row' }}
        renderItem={({item}) => {
          // Minus one in order to match 0-4 (not 1-5)
          return (
          <ReactionItem
            reaction={Reactions[parseInt(item.id) - 1].reaction}
            count={item.count}
            react_code={item.id}
            addReactFunc={() => this.addReaction(item.id.toString())}
          />
          )
        }}
        ListFooterComponent={() => {
          if (sessionStorage.getItem('Role') == "listener") {
            return (
              <View>
              {!this.state.addReactionFlag ?
                <TouchableOpacity
                  onPress={() => {this.setState({ addReactionFlag : true })}} >
                  <View style={styles.reactionStyle}>
                    <Text>+</Text>
                  </View>
                </TouchableOpacity>
                :
                <View style={styles.addReactionPanel}>
                  <Text style={{color: 'white', fontWeight: 'bold', marginStart: window.window.width / 100}}>
                    SELECT
                  </Text>
                  {/* Possible reactions */}
                  <FlatList 
                    data={Reactions}
                    keyExtractor={item => item.id}
                    numColumns={5}
                    style={{marginStart: window.window.width / 90}}
                    renderItem={({ item }) => (
                      <ReactionItem
                        reaction={item.reaction}
                        count={""}
                        react_code={item.id.toString()}
                        addReactFunc={() => this.addReaction(item.id.toString())}
                      />
                    )}
                  />
                  <TouchableOpacity
                    style={styles.cancelButton}
                    onPress={() => {this.setState({ addReactionFlag : false })}}>
                      <Text style={styles.cancelText}>CANCEL</Text>
                  </TouchableOpacity>
                </View>
              }
              </View>
            )
          } else {
            return (null)
          }
        }}
        />
      </View>
      )
  }
}

const styles = StyleSheet.create({
  reactionsContainer: {
    flexWrap: 'wrap',
    marginTop: window.window.height / 60,
    marginStart: 15,
    width: window.window.width / 2.7,
  },
  reactionStyle: {  
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: window.window.width / 32,
    height: window.window.height / 35,
    marginRight: window.window.width / 50,
    marginBottom: window.window.height / 60,
    backgroundColor: 'white',
  },
  addReactionPanel: {
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: window.window.width / 3,
    height: window.window.height / 28,
    marginRight: window.window.width / 50,
    marginBottom: window.window.height / 60,
    backgroundColor: Colors.ppMainPurple,
  },
  cancelButton: {
    backgroundColor: 'white',
    color: Colors.ppMainPurple,
    borderRadius: 10,
    height: window.window.height / 35,
    justifyContent: 'center',
    marginEnd: 15,
  },
  cancelText: {
    color: Colors.ppMainPurple,
    fontWeight: 'bold',     
    marginStart: 15,
    marginEnd: 15,
  }
});
