// Custom Navigation Drawer / Sidebar with Image and Icon in Menu Options
// https://aboutreact.com/custom-navigation-drawer-sidebar-with-image-and-icon-in-menu-options/

import React, {Component} from 'react';
import UserIcon from "./UserIcon";
import { SafeAreaView, StyleSheet, View, Text, Platform,
  Linking, TouchableOpacity } from 'react-native';
import { DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer';
import { ProfilePicture, FirstName, set_user } from '../data/UserData'
import { Colors } from '../global/Constants.js';
import { FontAwesome } from '@expo/vector-icons';
import window from '../global/Layout.js'
import qs from 'qs';

const confirm_logout_message = "Are you sure you want to log out?";
const email_bug_report = "gfleissi@andrew.cmu.edu";
const subject_bug_report = "PeerPresents - Bug Report";

// const CustomSidebarMenu = (props) => {
export default class CustomSidebarMenu extends Component {

  constructor(props) {
    
    super(props);
    
    this.state = {
      greeting: "",
    }
  }

  // Click on report bug
  async report_bug() {
    let url = `mailto:${email_bug_report}`;

    const query = qs.stringify({
      subject: subject_bug_report,
    });
    if (query.length) {
      url += `?${query}`;
    }
    const can_open_link = await Linking.canOpenURL(url);
    if (!can_open_link) {
      throw new Error('Email url could not be opened')
    }
    return Linking.openURL(url);
  }

  // Logout dialog
  logout() {
    if (Platform.OS == 'web') {
      var response = confirm(confirm_logout_message);
      if (response) {
        set_user("", "", "null", "null");
        location.reload();
      }
    } else {
      Alert.alert(
        "Log out",
        confirm_logout_message,
        [ { text: "Yes", onPress: () => {
          set_user("", "", "null", "null");
          location.reload();
        } },
          { text: "No" }
      ] )
    }
  }

  componentDidMount() {
    var d = new Date();
    var time = d.getHours();

    if (time < 12) {
      this.setState({greeting: "Good morning"});
    } else if (time > 18) {
      this.setState({greeting: "Good evening"});
    } else if (time >= 12) {
      this.setState({greeting: "Good afternoon"});
    }
  }

  render() {
    return (
      <SafeAreaView>
        <View style={{alignSelf: 'center', marginTop: 30}}>
          <UserIcon
            userIconDim={window.window.height / 10}
            profile_pic={ProfilePicture} />
        </View>
        <Text style={[styles.greetingText]}>{this.state.greeting},</Text>
        <Text style={[styles.greetingText, {fontWeight: 'bold', fontSize: 22}]}>{FirstName}</Text>
        <DrawerContentScrollView {...this.props}>
          <DrawerItemList {...this.props} />
        </DrawerContentScrollView>
        <TouchableOpacity onPress={this.logout}
                          style={styles.logoutButton}
                          onFocus={() => this.setState({ bgColor: 'red' })}>
          <Text style={{color: 'white', fontWeight: 'bold', textAlign: 'center'}}>Log Out</Text>
        </TouchableOpacity>
        <TouchableOpacity style={{alignItems: 'center', marginTop: 25}} onPress={this.report_bug}>
          <FontAwesome name="bug" size={24} color="white" />
        </TouchableOpacity> 
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  greetingText: {
    color: 'white',
    fontSize: 15,
    alignSelf: 'center',
    marginBottom: 25,
    marginTop: 10,
  },
  logoutButton: {
    backgroundColor: Colors.ppMainPurple,
    borderColor: 'white',
    borderRadius: 10,
    borderWidth: 1,
    marginTop: 20,
    width: window.window.width / 10,
    height: window.window.height / 20,
    justifyContent: 'center',
    alignSelf: 'center', 
  }
});