import * as React from 'react';
import { StyleSheet, Text, View, Platform, Alert, TouchableOpacity,
  ScrollView, TouchableHighlight } from 'react-native';
import { Colors } from '../global/Constants';
// TODO dynamically select which to import 
// import { Modal } from 'react-native';      // native
import Modal from 'modal-react-native-web' // web
import {Picker} from '@react-native-picker/picker';
import { AdminEmails, ADMIN_EMAIL_KEY, Strings } from '../global/Constants'
import { createUser, getUserByEmail } from '../custom-modules/backendAPI';
import AddPhotoWithActionSheet from '../components/AddPhoto'
import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import { ENCRYPTION_KEY, set_user } from '../data/UserData'
import CryptoJS from "react-native-crypto-js";
import { RadioButton } from 'react-native-paper';
import window from '../global/Layout.js'
import TextBox from '../components/TextBox';
import MainButton from '../components/MainButton';
import Logo from '../components/Logo'


export default function Signup({route, navigation}) {
  
  // const CryptoJS = require('crypto-js');
  
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailAddress, setEmailAddress] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [profile_pic, setProfile_pic] = React.useState(null);
  const [role, setRole] = React.useState("listener");
  const [adminCode, setAdminCode] = React.useState("");
  
  const [consentYes, setConsentYes] = React.useState(false)
  const [consentNo, setConsentNo] = React.useState(false)
  const [consentModal, setConsentModal] = React.useState(false);

  const [errors, setErrors] = React.useState([]);

  function addError(newError) {
    let current_errors = errors;
    current_errors.push(newError)
    setErrors(current_errors);
  }

  function validateEmail() {
    const regexp = /\S+@\S+\.\S+/;
    return regexp.test(String(emailAddress).toLowerCase());
  }

  function validatePassword() {
    return password.length > 7;
  }

  function validateName() {
    return (firstName.length > 1 && lastName.length > 1);
  }

  async function checkEmailExists() {
    // let cypertext = CryptoJS.AES.encrypt(emailAddress, ADMIN_EMAIL_KEY).toString();
    // console.log(cypertext);
    await getUserByEmail(emailAddress).then(_user => {
      if (_user != false) {
        addError(" Email already exists");
      }
    })

  }

  function signUpInDB(consent) {
    // let cypertext = CryptoJS.AES.encrypt(password, 'secret').toString();
    // console.log(cypertext);
    // let bytes = CryptoJS.AES.decrypt(cypertext, 'secret');
    // let originalText = bytes.toString(CryptoJS.enc.Utf8);
    // console.log(originalText);

    let newUserObj = {
      _id: "0",
      username: emailAddress.substr(0, emailAddress.indexOf('@')),
      first_name: firstName,
      last_name: lastName,
      email: emailAddress,
      password: CryptoJS.AES.encrypt(password, ENCRYPTION_KEY).toString(),
      consent: consent,
      roles: [role],
      course_list: [],
      session_list: [],
      presentation_list: [],
    }
    if (profile_pic != null) {
      newUserObj.profile_pic = profile_pic;
    }
    createUser(newUserObj).then(_id => {
      if (_id) {
        console.log("User was created successfully!");
        if (profile_pic != null) {
          set_user(role, _id, profile_pic, firstName);
        } else {
          set_user(role, _id, "null", firstName);
        }
      }
    });
    // Navigating to the correct view according to role chosen
    if (role == 'listener') {
      navigation.navigate('StudentNavigation')
    } else if (role == 'admin') {
      navigation.navigate('AdminNavigation');
    }
  }

  // Check if sign up process is valid
  async function signUpUser() {
    // Clearing the array of errors
    let newErrors = errors;
    while(newErrors.length > 0) newErrors.pop();
    setErrors(newErrors);
    // Validating and setting errors if there are any
    // Check if email exists already
    await checkEmailExists();
    // Passwords mismatch
    if (password != confirmPassword) {
      addError(" Passwords do not match");
    }
    // Illegal email address
    if (!validateEmail()) {
      addError(" Email address is not valid")
    }
    // Illegal email address
    if (!validatePassword()) {
      addError(" Password must be 7 or more characters")
    }
    // Illegal name
    if (!validateName()) {
      addError(" Name is not valid")
    }
    if (role == "") {
      addError(" Please choose role")
    } else if (role != "listener") {
      if (!AdminEmails.includes(emailAddress)) {
        addError(" Email address is not registered in system")
      } else if (adminCode == "") {
        addError(" Administrators must enter code")
      } else {
        let bytesCode = CryptoJS.AES.decrypt(adminCode, ADMIN_EMAIL_KEY);
        let originalEmailAddress = bytesCode.toString(CryptoJS.enc.Utf8);
        if (originalEmailAddress != emailAddress) {
          addError(" Invalid code")
        }
      }
    }
    // Presenting the errors or signing up successfully
    if (errors.length == 0) {
      if (role == "listener" || role == "admin") {
        setConsentModal(true);
      } else {
        signUpInDB(false);
      }
    } else {
      if (Platform.OS == 'web') {
        alert(errors)
      } else {
        Alert.alert(
          "Error",
          errors,
          [ { text: "OK" } ] )
      }
    }

  }

  function setProfilePic(profile_pic_uri) {
    setProfile_pic(profile_pic_uri);
    set_user("", "", profile_pic_uri, "");
  }

  return (
    <ScrollView style={styles.scrollContainer}>
      <View style={styles.header}>
        <Logo />
      </View>
      <View style={styles.viewContainer}>
        <ActionSheetProvider>
          <AddPhotoWithActionSheet setProfilePic={setProfilePic} />
        </ActionSheetProvider>
        <Text style={{color: Colors.ppTextColor,
                      fontSize: 25,
                      fontWeight: 'bold',
                      marginBottom: 20}}>Create account</Text>
        <TextBox  fieldTitle={"FIRST NAME"}
          onChangeText={setFirstName}
          value={firstName}
          onSubmitEditing={signUpUser}
        />
        <TextBox  fieldTitle={"LAST NAME"}
          onChangeText={setLastName}
          value={lastName}
          onSubmitEditing={signUpUser}
        />
        <TextBox  fieldTitle={"EMAIL ADDRESS"}
          onChangeText={setEmailAddress}
          value={emailAddress}
          onSubmitEditing={signUpUser}
        />
        <TextBox  fieldTitle={"PASSWORD"}
          onChangeText={setPassword}
          value={password}
          onSubmitEditing={signUpUser}
          secureTextEntry={true}
        />
        <TextBox  fieldTitle={"CONFIRM PASSWORD"}
          onChangeText={setConfirmPassword}
          value={confirmPassword}
          onSubmitEditing={signUpUser}
          secureTextEntry={true}
        />
        <View>
          <Text style={styles.fieldTitle}>ROLE</Text>
          <View style={styles.dropdownMenuContainer}>
            <Picker
              selectedValue={role}
              onValueChange={value => setRole(value)}
              style={styles.picker}
            >
              <Picker.Item label="Admin" value="admin" />
              <Picker.Item label="Student" value="listener" />
            </Picker>
          </View>
        </View>
        {/* Admins only need to fill out the code field */}
        { (role == "admin") &&
          <TextBox  fieldTitle={"CODE"}
          onChangeText={setAdminCode}
          value={adminCode}
          onSubmitEditing={signUpUser}
        />
        }
        <MainButton onPress={signUpUser} text={"Sign up"} active={true} />

        <View style={{flexDirection: 'row', marginBottom: window.window.height / 20}}>
          <Text style={styles.loginText}>Already a member? </Text>
          <TouchableOpacity onPress={() => navigation.navigate("Log in")} >
            <Text style={[styles.loginText, {textDecorationLine: 'underline'}]}>Log in</Text>
          </TouchableOpacity>
        </View>
      </View>
      {/* User Agreement Modal */}
      <View style={styles.centeredView}>
        <Modal
          animationType="slide"
          transparent={true}
          visible={consentModal}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              {/* User Agreement */}
              <Text style={{fontWeight: 'bold'}}>User Agreement{"\n"}{"\n"}</Text>
              <Text>PeerPresents collects anonymous user data for research purposes,
                such as student questions, responses and tags.{"\n"}{"\n"}</Text>
              <Text>Researchers will never publish your personal information such
                as name, username, email address or profile picture.{"\n"}{"\n"}</Text>
              <Text>Do you agree to share anonymous data for research
                purposes?{"\n"}{"\n"}</Text>
              <View style={{flexDirection: 'row', marginBottom: 10}}>
                <RadioButton
                  value={consentYes}
                  status={ consentYes ? 'checked' : 'unchecked' }
                  onPress={() => {setConsentNo(false); setConsentYes(true);}}
                />
                <Text>
                  <Text style={{fontWeight: 'bold'}}>Yes</Text>{Strings.CONSENT_YES}
                </Text>
              </View>
              <View style={{flexDirection: 'row'}}>
                <RadioButton
                  value={consentNo}
                  status={ consentNo ? 'checked' : 'unchecked' }
                  onPress={() => {setConsentYes(false); setConsentNo(true);}}
                />
                <Text>
                  <Text style={{fontWeight: 'bold'}}>No</Text>{Strings.CONSENT_NO}
                </Text>
              </View>
              <TouchableHighlight
                style={styles.openButton}
                onPress={() => {
                  setConsentModal(false);
                  signUpInDB(consentYes);
                }}
              >
                <Text style={styles.textStyle}>Finish</Text>
              </TouchableHighlight>
              <TouchableHighlight
                style={styles.openButton}
                onPress={() => {
                  setConsentModal(false);
                }}
              >
                <Text style={styles.textStyle}>Close</Text>
              </TouchableHighlight>
              <Text style={{marginTop: 10}}>
                (Questions? Email ascook@memphis.edu){"\n"}{"\n"}
              </Text>
            </View>
          </View>
        </Modal>
      </View>
    </ScrollView>
  );
}


const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
    backgroundColor: Colors.backgroundColor,
  },
  picker: {
    borderWidth: 0,
    borderRadius: 10,
    fontSize: window.window.height / 50,
    width: window.window.width / 5,
    height: window.window.height / 20,
  },
  viewContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: window.window.height / 40,
  },
  centeredView: {
    flex: 1,
    marginTop: 22,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView: {
    shadowOffset: {
      width: 0,
      height: 2
    },
    flex: 0.5,
    margin: 20,
    padding: 15,
    elevation: 5,
    borderRadius: 20,
    shadowRadius: 3.84,
    shadowOpacity: 0.25,
    shadowColor: "#000",
    alignItems: "center",
    backgroundColor: "white",
    width: window.window.width / 2.5,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  fieldTitle: {
    color: Colors.ppTextColor,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  openButton: {
    padding: 10,
    elevation: 2,
    marginTop: 10,
    borderRadius: 20,
    backgroundColor: Colors.ppMainPurple,
  },
  loginText: {
    color: Colors.ppTextColor,
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 15,
  },
  dropdownMenuContainer: {
    borderWidth: 0,
    borderRadius: 10,
    marginBottom: 10,
    backgroundColor: 'white',
    width: window.window.width / 5,
    height: window.window.height / 20,
  },
  header: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});