import React, { Component } from 'react';
import { StyleSheet, TouchableOpacity, Image, View } from 'react-native';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import { MaterialIcons } from '@expo/vector-icons'
import { ProfilePicture } from '../data/UserData';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from "expo-image-manipulator";
import window from '../global/Layout.js'
import { Camera } from 'expo-camera';

const icon = (name) => <MaterialIcons key={name} name={name} size={20} />

class AddPhoto extends Component {
    
    constructor(props) {
        super(props);
    };

    state = {
        image: ProfilePicture,
    };


    // System image picker and image manipulator
    _pickImage = async () => {
        try {
          let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 1,
            allowsMultipleSelection: false,
          });
          if (!result.cancelled) {
            const manipResult = await ImageManipulator.manipulateAsync(
                result.uri,
                [{ resize: { width: 200} }],
                { compress: 0.5, format: 'jpeg', base64: true }
              );
            this.setState({ image: manipResult.uri });
            this.props.setProfilePic(manipResult.uri);
          }
        } catch (E) {
          console.log(E);
        }
    };

    // System camera and image manipulator
    // _takePicture = async () => {
    //     try {
    //       <Camera
    //         ref={ref => {
    //           this.camera = ref;
    //         }}
    //       />;
    //       snap = async () => {
    //         if (this.camera) {
    //           let result = await this.camera.takePictureAsync();
    //           if (!result.cancelled) {
    //             const manipResult = await ImageManipulator.manipulateAsync(
    //                 result.uri,
    //                 [{ resize: { width: 200} }],
    //                 { compress: 0.5, format: 'jpeg', base64: true }
    //               );
    //             this.setState({ image: manipResult.uri });
    //             this.props.setProfilePic(manipResult.uri);
    //           }
    //         }
    //       };
    //     } catch (E) {
    //       console.log(E);
    //     }
    //   };
    
    // The upload profile picture dialog
    _onOpenActionSheet = () => {
        const options = ['Take Photo', 'Choose rom Library', 'Cancel'];
        const icons = [icon('camera-alt'), icon('photo'), icon('cancel')];
        const showSeparators = true;
        const containerStyle = styles.AddPhotoContainer;
        const cancelButtonIndex = 2;
        
        this.props.showActionSheetWithOptions(
            {
            options,
            icons,
            cancelButtonIndex,
            showSeparators,
            containerStyle
            },
            buttonIndex => {
                if (buttonIndex == 0) { this._pickImage; }
                if (buttonIndex == 1) { this._pickImage; }
            },
        );
    };

    render() {
      return (
          <View>
              {!this.state.image
                ?
                  <TouchableOpacity
                    style={styles.addPhoto}
                    onPress={this._onOpenActionSheet}>
                      <MaterialIcons
                        name="add-a-photo"
                        color="black"
                        size={window.window.width / 38} />
                  </TouchableOpacity>
                :
                  <TouchableOpacity onPress={this._onOpenActionSheet}>
                    <Image
                      source={{uri: this.state.image }}
                      style={styles.addPhoto}/>
                  </TouchableOpacity>
              }
          </View>
        );
    }
}

const AddPhotoWithActionSheet = connectActionSheet(AddPhoto);

export default AddPhotoWithActionSheet;


const styles = StyleSheet.create({
  addPhoto: {
    marginBottom: 10,
    borderRadius: 150,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E2E2E2',
    width: (window.mobile) ?
        window.window.width / 8 :
        window.window.width / 16,
    height: (window.mobile) ?
        window.window.width / 8 :
        window.window.width / 16,
  },
  AddPhotoContainer: {
    width: window.window.width / 2,
    bottom:  -window.window.height * 1.1,
    left: -window.window.width / 4.8,
  }
});