import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../global/Constants';

export default class SeparatingLine extends Component {

  render() {
    return (
        <View style={styles.separatingLine} />
      )
  }
}

const styles = StyleSheet.create({
  separatingLine: {
    borderWidth: 0.5,
    borderColor: Colors.ppGreyText,
  },
});
