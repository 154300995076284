import React, { Component } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import window from '../global/Layout.js';
import { getUser, getReactList } from '../custom-modules/backendAPI';
import UserIcon from './UserIcon';
import ResponseButtonPanel from './ResponseButtonPanel.js';
import { Colors } from '../global/Constants';
import ReactionsContainer from './ReactionsContainer.js';
 
export default class CommentExtendedViewAdmin extends Component {

  constructor(props) {

    super(props);
    let date = new Date(this.props.response.publish_time);

    this.state = {
      response_object : this.props.response,
      user_of_comment : {},
      publish_time : date.toLocaleString("en-US"),
      reaction_objects: null,
      react_count_dict: [],

      /* Instructors comment on responses feature (PART 3/5) - removed for now, can bring back */
      // comment_on_response: this.props.response.instructor_comment,
      // comment_textbox_open : false,
    }
  }

  componentDidMount() {
    getUser(this.state.response_object.listener_id, true).then(_user => {
      if (_user) {
        this.setState({
          user_of_comment : _user,
        })
      }
    })
 
    // Get reactions for this comment
    getReactList(this.state.response_object.react_list).then(_result => {
      if (_result) {
        let react_count_dict = [
          {id: "1", count: 0, users: []},
          {id: "2", count: 0, users: []},
          {id: "3", count: 0, users: []},
          {id: "4", count: 0, users: []},
          {id: "5", count: 0, users: []},
        ]
        _result.map(item => {
          // Minus one in order to match 0-4 (not 1-5)
          react_count_dict[parseInt(item.react_code) - 1].count++;
          react_count_dict[parseInt(item.react_code) - 1].users
                                                      .push(item.listener_id);
        })
        this.setState({
          reaction_objects: _result,
          react_count_dict: react_count_dict
        })
      }
    })
  }

  /* Instructors comment on responses feature (PART 4/5) - removed for now, can bring back */
  // updateInstructorComment() {
  //   getResponse(this.props.response._id).then(_response => {
  //     if (_response) {
  //       updateResponse(this.props.response._id, {
  //         instructor_comment: this.state.comment_on_response
  //       }).then(res => {})
  //     }
  //   });
  //   this.setState({
  //     comment_textbox_open : !this.state.comment_textbox_open,
  //   })
  // }

  render() {
    return (
        <View>
          {!this.props.extended
          ?
          <View style={[styles.container, {width: window.window.width / 3.5}]}>
            <View style={{flexDirection: 'row', width: window.window.width / 4.7}}>
              <View style={{alignItems: 'center', marginStart: 15, marginTop: 15}}>
                <UserIcon userIconDim={window.window.height / 20}
                  profile_pic={this.state.user_of_comment.profile_pic} />
                <Text style={{marginTop: 5}}>{this.state.user_of_comment.first_name}</Text>
              </View>
              <Text style={{marginStart: 15, alignSelf: 'center', fontWeight: 'bold'}}>{this.state.response_object.text}</Text>
            </View>
            <ResponseButtonPanel response={this.props.response}
              change_comment_textbox_open={() => this.setState({comment_textbox_open : !this.state.comment_textbox_open})} />
          </View>
          :
            <View style={[styles.container, {width: window.window.width * (3/5)}]}>
              <View style={{flexDirection: 'row'}}>
                <View style={{alignItems: 'center', marginStart: 15, marginTop: 15}}>
                  <UserIcon userIconDim={window.window.height / 15}
                    profile_pic={this.state.user_of_comment.profile_pic} />
                  <Text>{this.state.user_of_comment.first_name}</Text>
                </View>
                <View style={{justifyContent: 'center'}}>
                  <Text style={{marginStart: 15, alignSelf: 'flex-start', marginTop: 15,
                  fontSize: 20}}>{this.state.response_object.text}</Text>
                  { this.props.extended &&
                    <View style={{flexDirection: 'column'}}>
                      <ReactionsContainer response={this.props.response} />
                      <Text style={{marginStart: 15, marginTop: 15}}>{this.state.publish_time}</Text>
                    </View>
                  }
                </View>
              </View>

              <ResponseButtonPanel response={this.props.response}
                  change_comment_textbox_open={() => this.setState({comment_textbox_open : !this.state.comment_textbox_open})} />
            </View>
          }
          
          {/* Instructors comment on responses feature (PART 5/5) - removed for now, can bring back */}
          
          {/* {this.state.comment_textbox_open && 
          // TODO: Consider moving to a separate component
            <View style={{flexDirection: 'row', marginStart: 25}}> 
              <TextInput
                style={styles.comment_on_response_textbox}
                onChangeText={text => this.setState({comment_on_response : text})}
                value={this.state.comment_on_response}
                onSubmitEditing={() => this.updateInstructorComment()}
                placeholder={"Add a comment on this response..."}
                maxLength={200}
              />
              <TouchableOpacity onPress={() => {this.updateInstructorComment()}}
                style={styles.comment_on_response_button}>
                  <Text style={styles.buttonText}>Save</Text>
              </TouchableOpacity>
            </View>
          } */}
        </View>
      )
  }
}

const styles = StyleSheet.create({
  container: {
    marginStart: 10,
    marginEnd: 10,
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  commentIcons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: window.window.width / 20,
  },
  reactionsContainer: {
    flexWrap: 'wrap',
    marginTop: window.window.height / 60,
    marginStart: 15,
  },
  comment_on_response_textbox: {
    marginStart: 10,
    marginEnd: 10,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.ppDarkGreyText,
    width: window.window.width / 4.5,
    height: window.window.height / 30,
  },
  buttonText: {
    color: 'white',
    fontSize: window.window.height / 80,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  comment_on_response_button: {
    backgroundColor: Colors.ppMainPurple,
    borderColor: Colors.ppMainPurple,
    borderRadius: 5,
    width: window.window.width / 18,
    height: window.window.height / 30,
    justifyContent: 'center',
    alignSelf: 'center',
  },
});
