import * as React from "react";
import { StyleSheet, Text, View, ScrollView } from "react-native";
import { Colors } from "../global/Constants";
import window from "../global/Layout";
import SideMenu from "../components/SideMenu";
import MainButton from "../components/MainButton";
import TextBox from "../components/TextBox";
import SeparatingLine from "../components/SeparatingLine";
import BackButton from "../components/BackButton";
import Search from "../components/Search";
import Logo from '../components/Logo';
import { createDataset } from "../custom-modules/backendAPI";

const DATASET_TITLE_LENGTH_MIN = 5;
const DATASET_TITLE_LENGTH_MAX = 35;
const error_message_title_length = "Dataset titles must be between 5 and 35 characters";

export default function CreateDatasetScreen({ route, navigation }) {

  const [datasetName, setDatasetName] = React.useState("");
  const [sessionList, setSessionList] = React.useState([]);

  // Create and add dataset to the database
  function addDataset() {
    if (datasetName.length < DATASET_TITLE_LENGTH_MIN ||
      datasetName.length > DATASET_TITLE_LENGTH_MAX) {
      if (Platform.OS == 'web') {
        alert(error_message_title_length)
      } else {
        Alert.alert("Error",
          error_message_title_length,
            [ { text: "OK" } ] )
      }
    } else { 
      let newDataset = {
        _id: "0",
        title: datasetName,
        session_list: sessionList,
        graph_list: [],
        instructor_list: [sessionStorage.getItem('UserId')],
      }
      addDatasetToDB(newDataset);
    }
  }

  function addDatasetToDB(dataset) {
    createDataset(dataset).then(_id => {
      if (_id) {
        console.log("Dataset created successfully");
        navigation.goBack();
      }
    })
  }

  return (
    <View style={styles.screenContainer}>
      <View style={{ flexDirection: "row", flex: 1 }}>
        <SideMenu navigation={navigation} />
        <View style={styles.logoAndScrollContainer}>
          <Logo />
          <View style={styles.upperButtonContainer} />
          <BackButton backTo={"Datasets"} navigation={navigation} />
          <View style={{marginStart: window.window.width / 6}} >
            <SeparatingLine />
          </View>
          <ScrollView style={styles.scrollViewContainer}>
            <Text style={styles.title}>Create New Dataset</Text>
            <TextBox
              fieldTitle={"Dataset Title"}
              onChangeText={(text) => setDatasetName(text)}
              value={datasetName}
              secureTextEntry={false}
              placeholder={"Dataset Title"}
              maxLength={35}
              mandatory={true}
            />
            <View style={{flexDirection: 'row'}}>
              <Text style={styles.fieldTitle}>Select Sessions to include in Dataset</Text>
              <Text style={{color: 'red', fontSize: 30}}>*</Text>
            </View>
            <Search type={"sessions"} route={route} limit_to_one={false}
                    change_id_list={setSessionList}
                    update_settings={() => {}}
            />
            <View style={styles.upperButtonContainer}>
              <MainButton
                small={true}
                active={true}
                text={"Create"}
                square={true}
                onPress={() => addDataset()}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  logoAndScrollContainer: {
    justifyContent: 'center',
  },
  scrollViewContainer: {
    width: window.window.width / 1.5,
    height: window.window.height,
    marginStart: window.window.width / 6,
    backgroundColor: Colors.backgroundColor,
  },
  upperButtonContainer: {
    alignSelf: "flex-end",
    marginBottom: window.window.height / 20,
  },
  screenContainer: {
    flex: 1,
    flexDirection: "column",
  },
  title: {
    fontSize: window.window.height / 30,
    marginTop: window.window.height / 30,
    marginBottom: 30,
    fontWeight: "bold",
  },
  fieldTitle: {
    color: Colors.ppTextColor,
    fontWeight: 'bold',
    fontSize: window.window.height / 35,
    marginTop: 10,
  },
  addData: {
    marginTop: 30,
    borderRadius: 30,
    marginBottom: 25,
    backgroundColor: "white",
    width: window.window.width / 1.5,
    height: window.window.height / 1.5,
  },
});
