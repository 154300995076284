import React, { Component } from 'react';
import { StyleSheet, Image } from 'react-native';
import window from '../global/Layout.js'

export default class Logo extends Component {

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Image style={styles.logo} source={require('../assets/images/logo.png')}/>
    )
  }
}

const styles = StyleSheet.create({
  logo: {
    resizeMode: 'contain',
    width: window.window.width / 6,
    height: window.window.height / 12,
    marginTop: window.window.height / 35,
    alignSelf: 'center',
  },
});
