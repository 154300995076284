import React, { Component } from "react";
import { View, FlatList, StyleSheet, ScrollView, Text, TouchableOpacity,
  TextInput } from "react-native";
import window from "../global/Layout";
import { CheckBox } from 'react-native-elements';

export default class TagsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items_list: this.props.data,
      newTag: "",
    };
  }

  addTag() {
    if (this.state.newTag.length > 0) {
      let current_tags = this.state.items_list;
      current_tags[this.state.newTag] = false;
      this.setState({
        items_list: current_tags,
        newTag: ""
      })
      this.props.changeData(this.state.items_list);
    }
  }

  render() {
    return (
      <View style={styles.container}>
        <Text style={{marginStart: 20, marginTop: 10, fontSize: 15}}>Select pre-prepared tags</Text>
        <ScrollView>
          <FlatList
            data={Object.keys(this.state.items_list)}
            scrollEnabled={true}
            initialNumToRender={3}
            style={{ marginStart: 20, height: window.window.height / 6 }}
            keyExtractor={(item) => item}
            extraData={this.state.items_list}
            renderItem={({ item }) => (
              <CheckBox
              title={item}
              checked={this.state.items_list[item]}
              containerStyle={{
                backgroundColor: "white",
                borderColor: "white",
              }}
              onPress={() => {
                let current_tags = this.state.items_list;
                current_tags[item] = !current_tags[item];
                this.setState({
                  items_list: current_tags
                })
              }}
            />
            )}
          />
          <View style={{flexDirection: "row", justifyContent: 'center',
                        marginBottom: 20, marginTop: window.window.height / 30,
                      }}>
            <TextInput
              style={styles.textInput}
              onChangeText={text => this.setState({ newTag : text })}
              value={this.state.newTag}
              onSubmitEditing={() => null}
              placeholder={"ADD NEW TAG"}
              maxLength={30}
            />
            <TouchableOpacity style={styles.createButton} onPress={() => this.addTag()}>
                <Text style={{color: "white", fontWeight: 'bold'}}>CREATE</Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 10,
    backgroundColor: "white",
    marginTop: 25,
    marginStart: 30,
    width: window.window.width / 5,
    height: window.window.height / 8,
  },
  textInput: {
    borderRadius: 10,
    borderColor: "grey",
    borderWidth: 1,
    borderRadius: 5,
    width: window.window.width / 10,
    height: window.window.height / 30,
  },
  createButton: {
    width: window.window.width / 20,
    height: window.window.height / 30,
    marginStart: 10,
    backgroundColor: "grey",
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5
  }
});
