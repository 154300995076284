import React, { Component } from 'react';
import { TouchableOpacity, StyleSheet, Text } from 'react-native';
import { Colors } from '../global/Constants.js';
import window from '../global/Layout.js'
import { MaterialCommunityIcons } from '@expo/vector-icons';


export default class EditButton extends Component {


  render() {
    return (
      <TouchableOpacity onPress={this.props.onPress}
        style={[styles.editButtonStyle, {marginTop: this.props.margin_top == true ? 0 : window.window.height / 30}]}>
          <MaterialCommunityIcons name="pencil-outline" size={window.window.height / 40} color={Colors.ppTextColor} />
          <Text style={styles.buttonText}>{this.props.text}</Text>
      </TouchableOpacity>
    )
  }
}

const styles = StyleSheet.create({
  editButtonStyle: {
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    borderColor: Colors.ppTextColor,
    width: window.window.width / 7,
    height: window.window.height / 25,
  },
  buttonText: {
    color: Colors.ppTextColor,
    marginStart: window.window.width / 130,
    fontSize: window.window.height / 70,
    fontWeight: 'bold',
  },
});
