import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Platform, TextInput,
  TouchableOpacity, Text } from 'react-native';
import { Colors } from '../global/Constants';
import Tag from '../components/Tag';
import window from '../global/Layout.js'
import { getTagList, getResponse, updateResponse, createTag,
  getSession } from '../custom-modules/backendAPI';

export default class TagsContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tag_list: [],
      tag_list_refresh: false,
      newTag: "",
      addTagFlag: false,
    }

  }

  populate_preprepared_taglist() {
    getSession(this.props.session_id).then(_sess => {
      if (_sess) {
        let new_tag_list = [];
        Object.keys(_sess.tags).forEach(tag => {
          if (_sess.tags[tag]) {
            new_tag_list.push([tag, false])
          }
        })
        this.setState({ tag_list : new_tag_list });
      }
    })
  }

  componentDidMount() {
    this.populate_preprepared_taglist();
    // Get tags for this comment
    getTagList(this.props.response.tag_list).then(_result => {
      if (_result) {
        let tag_list = this.state.tag_list;

        // Go over all tags from the response
        for (const tag of Object.entries(_result)) {
          let tag_exist_flag = false;
          // go over all tags from the "session settings"
          this.state.tag_list.forEach(session_tag => {

            // If the current tag from the response is found in the settings
            // - activate it in the component's tag_list (which comes from
            // the settings)
            if (session_tag[0] == tag[1].text) {
              tag_exist_flag = true;
              let index_of_tag = this.state.tag_list.indexOf(session_tag);
              tag_list[index_of_tag][1] = true;
            }
          })

          // Other tags that are in the response list but are
          // not part of the session settings - are added as active
          if (!tag_exist_flag) tag_list.push([tag[1].text, true]);
        }
        this.setState({ 
          tag_list: tag_list,
          tag_list_refresh: !this.state.tag_list_refresh,
        });
      }
    })
  }

  // Adding a pre-prepared greyed out tag
  addGreyedOutTag = (tag_name) => {
    let tag_active = false;
    let tag_index = 0;

    for (let i = 0; i < this.state.tag_list.length; i++) {
      if (this.state.tag_list[i][0] == tag_name) {
        tag_active = this.state.tag_list[i][1];
        tag_index = i;
        break;
      }
    }
    if (!tag_active) {
      let newTag = {
        _id: "0",
        presenter_id: sessionStorage.getItem('UserId'),
        response_id: this.props.response._id,
        presentation_id: this.props.response.presentation_id,
        text: tag_name,
      }
      this.addTagToDB(newTag, tag_index)
    }
  }

  addTagToDB(newTag, tag_index) {
    createTag(newTag).then(_id => { 
      if (_id) { // if non-empty result
        // Update tag
        getResponse(this.props.response._id).then(_result => {
          if (_result) {
            let tag_list = _result.tag_list;
            tag_list.push(_id);
            updateResponse(this.props.response._id,
              {tag_list : tag_list}).then(_update_result => {
                if (_update_result) {
                  let newTagList = this.state.tag_list;
                  newTagList[tag_index] = [newTag.text, true];
                  // Update tag list in comment view
                  this.setState({
                    tag_list: newTagList,
                    tag_list_refresh: !this.state.tag_list_refresh,  
                  });
                }
              });
          }
        })
      }
    })
  }

  // Verify the new tag
  verifyTag = () => {
    // Make sure new tag is legal
    if (this.state.newTag == "") {
      if (Platform.OS == 'web') {
        alert("Tag must not be empty")
      } else {
        Alert.alert("Error",
          "Tag must not be empty",
          [ { text: "OK" } ] )
      }
    } else if (this.state.newTag.includes(" ")) {
      if (Platform.OS == 'web') {
        alert("Tags must not contain spaces")
      } else {
        Alert.alert("Error",
          "Tags must not contain spaces",
          [ { text: "OK" } ] )
      }
    } else {
      this.addTag();
    }
  }
  
  // Add the new tag to the DB
  addTag = () => {
    let newTag = {
      _id: "0",
      presenter_id: sessionStorage.getItem('UserId'),
      response_id: this.props.response._id,
      presentation_id: this.props.response.presentation_id,
      text: this.state.newTag,
    }
  
    createTag(newTag).then(_id => { 
      if (_id) { // if non-empty result
        // Update tag
        getResponse(this.props.response._id).then(_result => {
          if (_result) {
            let tag_list = _result.tag_list;
            tag_list.push(_id);
            updateResponse(this.props.response._id,
                          {tag_list : tag_list}).then(_update_result => {});
          }
        })
  
        this.updateTagList(newTag, _id);
      }
    })
  }

  updateTagList(newTag, _id) {
    let newTagList = this.state.tag_list;

    let tag_exist_flag = false;
    
    this.state.tag_list.forEach(session_tag => {
      // If the current tag from the response is found in the settings
      // - activate it in the component's tag_list (which comes from
      // the settings)
      if (session_tag[0] == newTag.text) {
        tag_exist_flag = true;
        let index_of_tag = this.state.tag_list.indexOf(session_tag);
        newTagList[index_of_tag][1] = true;
      }
    })

    // Other tags that are in the response list but are
    // not part of the session settings - are added as active
    if (!tag_exist_flag) newTagList.push([newTag.text, true]);

    // Update tag list in comment view
    this.setState({
      tag_list: newTagList,
      newTag: "",
      addTagFlag: false,
      tag_list_refresh: !this.state.tag_list_refresh,            
    });
  }

  render() {
    return (
      <View style={styles.tagsContainer}>
        <FlatList
        data={this.state.tag_list}
        numColumns={10}
        keyExtractor={(item) => item.toString()}
        extraData={this.state.tag_list_refresh}
        style={{flexDirection: 'row',}}
        renderItem={({item}) => {
          let tagText = '#'.concat(item[0]);
          return (<Tag
                    addTag={false}
                    updateTag={false}
                    name={tagText}
                    active={item[1]}
                    addGreyedOutTag={this.addGreyedOutTag}
                  />)
        }}
        // Footer component - plus button or update button
        ListFooterComponent={
          <View>
            { !this.state.addTagFlag
            ?
              <Tag
                addTagFunc={() => {this.setState({ addTagFlag : true })}}
                addTag={true}
                name={"  +  "}
                active={true}
              />
            :
            // Add new tag button panel
              <View style={{ flexDirection: 'row' }}>
                <TextInput style={styles.tagStyle}
                  placeholder={"  New tag..."}
                  value={this.state.newTag}
                  maxLength={12}
                  onChangeText={text => this.setState({newTag: text})}
                />
                <TouchableOpacity onPress={() => this.verifyTag()} style={[styles.tagStyle, {width: window.window.width / 20, alignItems: 'center'}]}>
                  <Text style={{ color: Colors.ppMainPurple, fontWeight: 'bold', marginStart: 10, marginEnd: 10 }}>ADD</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => this.setState({ addTagFlag : false })} style={[styles.tagStyle, {width: window.window.width / 15, alignItems: 'center'}]}>
                  <Text style={{ color: Colors.ppMainPurple, fontWeight: 'bold', marginStart: 10, marginEnd: 10 }}>CANCEL</Text>
                </TouchableOpacity>
              </View>
            }
          </View>
        }
        />
      </View>
      )
  }
}

const styles = StyleSheet.create({
  tagsContainer: {
    flex: 1,
    marginBottom: window.window.height / 50,
    marginStart: 15,
  },
  tagStyle: {
    borderWidth: 3,
    borderRadius: 20,
    borderColor: Colors.ppMainPurple,
    justifyContent: 'space-evenly',
    height: window.window.height / 30,
    width: window.window.width / 10,
    marginRight: window.window.width / 120,
  },  
});
