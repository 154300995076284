import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../global/Constants';
import window from '../global/Layout.js'
import { FontAwesome } from '@expo/vector-icons';
import { getStarList, createStar, updateStar, getResponse,
  updateResponse } from '../custom-modules/backendAPI';
  import { UserId } from '../data/UserData';

export default class StarsContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      starsNumber: 0,
      starObjectId: null,
    }
  }

  // The stars are individual per person - each presenter
  // rates with their own star rating and the sort is done
  // according to the presenter's individual rating
  // It's done by design in order to nicely present whole
  // number stars

  componentDidMount() {
    // Get stars for this comment
    getStarList(this.props.response.star_list).then(_result => {
      if (_result) {
        for (let i = 0; i < _result.length; i++) {
          if (_result[i].presenter_id == UserId) {
            this.setState({
              starObjectId: _result[i]._id,
              starsNumber: _result[i].value,
            });
            break;
          }
        }
      }
    })
  }

  // Update stars number on database
  onStarsPress = (number) => {
    // Add a new star object (was 0)
    if (this.state.starsNumber == 0) {
      let newStarObj = {
        _id: "0",
        presenter_id: UserId,
        response_id: this.props.response._id,
        presentation_id: this.props.response.presentation_id,
        value: number,
      }
      createStar(newStarObj).then(_id => {
        // Updating response object with newly created id
        getResponse(this.props.response._id).then(_response => {
          let star_list = _response.star_list;
          star_list.push(_id);
          updateResponse(this.props.response._id, {
            star_list: star_list
          }).then(res => {})
        })
      })
    } else {
    // Update a current star object (was 1/2/3)
      if (this.state.starObjectId != null) {
        updateStar(this.state.starObjectId, {
          value: number
        }).then(res => {});
      }
    }
    this.setState({ starsNumber: number });
    // if (this.props.commentsScreen === "presenters") {
    //   this.props.commentChangedFunc();
    // }
  }

  render() {
    return (
        // Stars Rating
        <View style={styles.starRatingStyle}>

          {this.state.starsNumber > 0 ?
          <FontAwesome name="star" color={Colors.ppInstructorStar}
                      size={window.window.height / 30}
                      onPress={() => this.onStarsPress(1)} /> :
          <FontAwesome name="star-o" color={Colors.ppInstructorStar}
                      size={window.window.height / 30}
                      onPress={() => this.onStarsPress(1)} /> }

          {this.state.starsNumber > 1 ?
          <FontAwesome name="star" color={Colors.ppInstructorStar}
                      size={window.window.height / 30}
                      onPress={() => this.onStarsPress(2)} /> :
          <FontAwesome name="star-o" color={Colors.ppInstructorStar}
                      size={window.window.height / 30}
                      onPress={() => this.onStarsPress(2)} /> }
          
          {this.state.starsNumber > 2 ?
          <FontAwesome name="star" color={Colors.ppInstructorStar}
                      size={window.window.height / 30}
                      onPress={() => this.onStarsPress(3)} /> :
          <FontAwesome name="star-o" color={Colors.ppInstructorStar}
                      size={window.window.height / 30}
                      onPress={() => this.onStarsPress(3)} /> }

        </View>
      )
  }
}

const styles = StyleSheet.create({
  starRatingStyle: {
    flexDirection: 'row',
    marginRight: window.window.width / 50,
  },
});
