import React, { Component } from 'react';
import { TouchableOpacity, StyleSheet, Text, View } from 'react-native';
import { Colors } from '../global/Constants.js';
import window from '../global/Layout.js'

export default class SortComments extends Component {

  constructor(props) {
    super(props);

    this.state = {
      sort_by_time: true,
      sort_by_flagged: false,
      sort_by_stars: false,
    }
  }

  // Aux sort by function in order to change internal state of buttons
  sortBy(category) {
    if (category == "time") {
      this.setState({
        sort_by_time: true,
        sort_by_flagged: false,
        sort_by_stars: false,
      });
    } else if (category == "flagged") {
      this.setState({
        sort_by_time: false,
        sort_by_flagged: true,
        sort_by_stars: false,
      });
    } else if (category == "stars") {
      this.setState({
        sort_by_time: false,
        sort_by_flagged: false,
        sort_by_stars: true,
      });
    }
    this.props.sort_by_func(category);
  }

  render() {
    return (
      <View style={styles.container}>
        <Text style={[styles.buttonText, {color: Colors.ppTextColor}]}>Sort By</Text>
        <TouchableOpacity onPress={() => this.sortBy("time")}
          style={[styles.sortButton, {
            backgroundColor: (this.state.sort_by_time) ? (Colors.ppMainPurple) : "white",
          }]}>
            <Text style={[styles.buttonText, {
              color: (!this.state.sort_by_time) ? (Colors.ppMainPurple) : "white"
            }]}>Time</Text>
        </TouchableOpacity>
        {this.props.student
        ?
          <TouchableOpacity onPress={() => this.sortBy("stars")}
            style={[styles.sortButton, {
              backgroundColor: (!this.state.sort_by_time) ? (Colors.ppMainPurple) : "white",
            }]}>
              <Text style={[styles.buttonText, {
                color: (this.state.sort_by_time) ? (Colors.ppMainPurple) : "white"
              }]}>Stars</Text>
          </TouchableOpacity>
        :
          <TouchableOpacity onPress={() => this.sortBy("flagged")}
            style={[styles.sortButton, {
              backgroundColor: (!this.state.sort_by_time) ? (Colors.ppMainPurple) : "white",
            }]}>
              <Text style={[styles.buttonText, {
                color: (this.state.sort_by_time) ? (Colors.ppMainPurple) : "white"
              }]}>Flagged</Text>
          </TouchableOpacity>
        }
      </View>
    )
  }
}


const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    marginEnd: window.window.width / 20,
  },
  sortButton: {
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: window.window.height / 22,
    borderColor: Colors.ppMainPurple,
    borderRadius: 30,
    width: window.window.width / 18,
    marginStart: 10,
  },
  buttonText: {
    fontSize: window.window.height / 55,
    fontWeight: 'bold',
    textAlign: 'center'
  },
});
