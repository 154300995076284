import * as React from 'react';
import { StyleSheet, Text, View, ScrollView } from 'react-native';
import window from '../global/Layout'
import { Colors } from '../global/Constants';
import SideMenu from '../components/SideMenu';
import Logo from '../components/Logo';
import SimpleFlatList from '../components/SimpleFlatList';
import { getAllCourseIDs, getAllUserIDs, getCourse, getUser } from '../custom-modules/backendAPI';

export default function DashboardTab({navigation}) {

  React.useEffect(() => {
  }, [navigation])

  return (
    <View style={styles.screenContainer}>
      <View style={{flexDirection: 'row', flex: 1}}>
        <SideMenu navigation={navigation} />
        <View style={styles.logoAndScrollContainer}>
          <Logo />
          <ScrollView style={styles.scrollViewContainer}>
            <Text style={styles.title}>Upcoming Feedback Sessions</Text>
            <View style={styles.upcomingSessions}>
              <View style={styles.upcomingTitlesContainer}>
                <Text style={styles.upcomingTitle}>Session</Text>
                <Text style={styles.upcomingTitle}>Class</Text>
                <Text style={styles.upcomingTitle}>End Date</Text>
              </View>
              <SimpleFlatList
                screen={"dashboard"}
                navigation={navigation}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
}

DashboardTab.navigationOptions = {
  header: null,
};

const styles = StyleSheet.create({
  logoAndScrollContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  scrollViewContainer: {
    marginStart: window.window.width / 6,
    backgroundColor: Colors.backgroundColor,
  },
  screenContainer: {
    flex: 1,
    flexDirection: "column",
  },
  title: {
    fontSize: window.window.height / 30,
    marginTop: window.window.height / 30,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  upcomingSessions: {
    marginTop: 30,
    borderRadius: 30,
    marginBottom: 25,
    backgroundColor: 'white',
    width: window.window.width / 1.5,
    height: window.window.height / 1.5,
  },
  upcomingTitlesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 20,
    marginBottom: 30,
    marginTop: 15,
  },
  upcomingTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: Colors.ppGreyText,
  },
});