import React, { Component } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import { Colors } from '../global/Constants';

export default class UserIcon extends Component {

  constructor(props) {
    super(props);
        
  };
    
  render() {


    return (
      <View>
        <View>
          <View>
            { this.props.profile_pic != "null" && this.props.profile_pic != null
              ?
                <View>
                  <Image source={{uri: this.props.profile_pic }}
                    style={[styles.photoStyleNonClickableMembers,
                    {width: this.props.userIconDim, height: this.props.userIconDim,
                      borderWidth: 1}]} />
                </View>
              :
                <View>
                  <FontAwesome5
                      name="user-circle"
                      color={Colors.ppMainPurple}
                      size={this.props.userIconDim}
                      style={styles.photoStyleNonClickableMembers} />
                </View>
            }
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  photoStyleNonClickableMembers: {
    borderRadius: 150,
    alignItems: 'center',
    justifyContent: 'center',
  },
});