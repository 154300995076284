import * as React from "react";
import { StyleSheet, Text, View, ScrollView } from "react-native";
import window from "../global/Layout";
import { Colors } from "../global/Constants";
import SideMenu from "../components/SideMenu";
import BackButton from "../components/BackButton";
import SeparatingLine from "../components/SeparatingLine";
import MainButton from "../components/MainButton";
import Search from "../components/Search";
import Logo from '../components/Logo';

export default function AddMemberScreen({ route, navigation }) {
  return (
    <View style={styles.screenContainer}>
      <View style={{ flexDirection: "row", flex: 1 }}>
        <SideMenu navigation={navigation} />
        <View style={styles.logoAndScrollContainer}>
          <Logo />
          <View style={styles.upperButtonContainer} />
          <BackButton backTo={"Presentation"} navigation={navigation} onGoBack={() => route.params.onGoBack()} />
          <View style={{marginStart: window.window.width / 6}} >
            <SeparatingLine />
          </View>
          <ScrollView style={styles.scrollViewContainer}>
            <Text style={styles.title}>Add Members</Text>
            <View style={styles.addMembers}>
              <Search type={"members"} route={route} entity_id={route.params.presentation_id} />
            </View>
            <View style={styles.buttomButtonContainer}>
              <MainButton
                small={true}
                active={true}
                text={"Save"}
                square={true}
                onPress={() => {
                  route.params.onGoBack();
                  navigation.goBack();
                }}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
}

AddMemberScreen.navigationOptions = {
  header: null,
};

const styles = StyleSheet.create({
  logoAndScrollContainer: {
    justifyContent: 'center',
  },
  scrollViewContainer: {
    marginStart: window.window.width / 6,
    marginTop: window.window.height / 16,
    backgroundColor: Colors.backgroundColor,
  },
  screenContainer: {
    flex: 1,
    flexDirection: "column",
  },
  title: {
    fontSize: window.window.height / 30,
    marginBottom: 10,
    fontWeight: "bold",
  },
  addMembers: {
    marginTop: 30,
    borderRadius: 30,
    marginBottom: 25,
    backgroundColor: "white",
    width: window.window.width / 1.5,
    height: window.window.height / 2,
  },
  buttomButtonContainer: {
    alignSelf: "flex-end",
    marginBottom: window.window.height / 20,
    marginTop: window.window.height / 10,
  },
  upperButtonContainer: {
    alignSelf: 'flex-end',
    marginBottom: window.window.height / 20,
  },
});
