import React, { Component } from 'react';
import { StyleSheet, View, TouchableOpacity, Text } from 'react-native';
import { Colors } from '../global/Constants';
import { getResponse, updateResponse } from '../custom-modules/backendAPI';
import { Ionicons } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import window from '../global/Layout.js';

export default class ResponseButtonPanel extends Component {

  constructor(props) {

    super(props);

    this.state = {
      bookmarkMarked : this.props.response.instructor_bookmark,
      hiddenMarked : this.props.response.instructor_hidden,
      user_of_comment : {},

      /* Instructors comment on responses feature (PART 2/5) - removed for now, can bring back */
      // commentMarked : (this.props.response.instructor_comment != undefined
      //   && this.props.response.instructor_comment != ""),
      // comment_on_response: this.props.response.instructor_comment,
      // comment_textbox_open : false,
    }
  }

  updateInstructorBookmark() {
    let current_bookmark = this.props.response.instructor_bookmark;
    getResponse(this.props.response._id).then(_response => {
      if (_response) {
        updateResponse(this.props.response._id, {
          instructor_bookmark: !current_bookmark
        }).then(res => {})
      }
    });
    this.setState({ bookmarkMarked : !this.state.bookmarkMarked })
  }

  updateInstructorHidden() {
    let current_hidden = this.props.response.instructor_hidden;
    getResponse(this.props.response._id).then(_response => {
      if (_response) {
        updateResponse(this.props.response._id, {
          instructor_hidden: !current_hidden
        }).then(res => {})
      }
    });
    this.setState({ hiddenMarked : !this.state.hiddenMarked })
  }

  render() {
    return (
      <View style={styles.commentIcons}>

        <View style={{alignItems: 'center', marginEnd: window.window.width / 100, marginTop: 2}}>
          <TouchableOpacity onPress={() => {this.updateInstructorBookmark()}}>
            {this.state.bookmarkMarked ?
              <FontAwesome name="bookmark" size={24} color={Colors.ppInstructorStar} />
              :
              <FontAwesome name="bookmark-o" size={24} color="black" />
            }
          </TouchableOpacity>
          <Text>Bookmark</Text>
        </View>

        <View style={{alignItems: 'center'}}>
          <TouchableOpacity onPress={() => {this.updateInstructorHidden()}} >
            {this.state.hiddenMarked ?
              <Ionicons name="ios-eye-off" size={24} color="blue" />
              :
              <Ionicons name="ios-eye" size={24} color="black" />
            }
          </TouchableOpacity>
          <Text>Hide</Text>
        </View>

        {/* Instructors comment on responses feature (PART 1/5) - removed for now, can bring back */}
        
        {/* <TouchableOpacity onPress={() => {this.props.change_comment_textbox_open()}}>
          {this.state.commentMarked ?
            <Feather name="message-square" onPress={() => {}} size={24} color="green" />
          :
            <Feather name="message-square" onPress={() => {}} size={24} color="black" />
        }
        </TouchableOpacity> */}

      </View>
    )
  }
}

const styles = StyleSheet.create({
  commentIcons: {
    marginStart: 10,
    marginEnd: 5,
    flexDirection: 'row',
    justifyContent: 'center',
    width: window.window.width / 20,
  }
});
