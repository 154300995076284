import * as React from "react";
import DashboardTab from "../screens/DashboardTab";
import CoursesTab from "../screens/CoursesTab";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { Colors } from "../global/Constants";
import { StyleSheet } from "react-native";
import window from "../global/Layout";
import CustomSidebarMenu from "../components/CustomSidebarMenu";
import CourseScreen from "../screens/CourseScreen";
import AddCourseScreen from "../screens/AddCourseScreen";
import ProfileTab from "../screens/ProfileTab";
import PresentationScreen from "../screens/PresentationScreen";
import CreatePresentationScreen from '../screens/CreatePresentationScreen';
import { createStackNavigator } from '@react-navigation/stack';
import SessionScreen from '../screens/SessionScreen';
import QuestionScreen from '../screens/QuestionsScreen';
import AddQuestionScreen from "../screens/AddQuestionScreen";
import AddMemberScreen from "../screens/AddMemberScreen";

const Drawer = createDrawerNavigator();

export default function StudentNavigation({ navigation, route }) {
  return (
    <Drawer.Navigator
      initialRouteName="HomeAdmin"
      drawerStyle={styles.drawerStyle}
      drawerContentOptions={{
        activeTintColor: "white",
        inactiveTintColor: "white",
      }}
      drawerContent={(props) => <CustomSidebarMenu {...props} />}
    >
      <Drawer.Screen name="Student Dashboard">{DashboardStackScreen}</Drawer.Screen>
      <Drawer.Screen name="Courses">{CoursesStackScreen}</Drawer.Screen>
      <Drawer.Screen name="Profile">{ProfileStackScreen}</Drawer.Screen>
    </Drawer.Navigator>
  );
}

const DashboardStack = createStackNavigator();

function DashboardStackScreen({ route, navigation }) {
  return (
    <DashboardStack.Navigator
      screenOptions={{headerShown: false,
        cardStyle: {backgroundColor: Colors.backgroundColor}
      }}>
      <DashboardStack.Screen name="DashboardTab" component={DashboardTab} />
      <DashboardStack.Screen name="Session" component={SessionScreen} />
      <DashboardStack.Screen name="CreatePresentation" component={CreatePresentationScreen} />
      <DashboardStack.Screen name="Presentation" component={PresentationScreen} />
      <DashboardStack.Screen name="AddMember" component={AddMemberScreen} />
      <DashboardStack.Screen name="AddQuestion" component={AddQuestionScreen} />
      <DashboardStack.Screen name="Questions" component={QuestionScreen} />
    </DashboardStack.Navigator>
  );
}

const CoursesStack = createStackNavigator();

function CoursesStackScreen({ route, navigation }) {
  return (
 <CoursesStack.Navigator
    screenOptions={{headerShown: false,
      cardStyle: {backgroundColor: Colors.backgroundColor}
    }}>
      <CoursesStack.Screen name="Courses" component={CoursesTab} />
      <CoursesStack.Screen name="AddCourse" component={AddCourseScreen} />
      <CoursesStack.Screen name="Course" component={CourseScreen} />
      <CoursesStack.Screen name="Session" component={SessionScreen} />
      <CoursesStack.Screen name="CreatePresentation" component={CreatePresentationScreen} />
      <CoursesStack.Screen name="Presentation" component={PresentationScreen} />
      <CoursesStack.Screen name="AddMember" component={AddMemberScreen} />
      <CoursesStack.Screen name="AddQuestion" component={AddQuestionScreen} />
      <CoursesStack.Screen name="Questions" component={QuestionScreen} />
    </CoursesStack.Navigator>
  );
}

const ProfileStack = createStackNavigator();

function ProfileStackScreen({ route, navigation }) {
  return (
 <ProfileStack.Navigator
    screenOptions={{headerShown: false,
      cardStyle: {backgroundColor: Colors.backgroundColor}
    }}>
      <ProfileStack.Screen name="Profile" component={ProfileTab} />
    </ProfileStack.Navigator>
  );
}

const styles = StyleSheet.create({
  drawerStyle: {
    backgroundColor: Colors.ppMainPurple,
    width: window.window.width / 7,
  },
});
