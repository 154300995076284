import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import RichTextEditor from 'react-rte';
import MainButton from '../components/MainButton';
import window from '../global/Layout';
import { getPresentation,
  updatePresentation } from '../custom-modules/backendAPI';

export default class InstructorNotes extends Component {

  state = {
    value: RichTextEditor.createEmptyValue(),
    presentation_id: this.props.presentation._id,
  }

  componentDidMount = () => {
    getPresentation(this.state.presentation_id).then(_pres => {
      if (_pres && _pres.instructor_notes != undefined) {
        this.setState({value : RichTextEditor.createValueFromString(_pres.instructor_notes, 'html')})
      }
    })
  }

  onChange = (value) => {
    this.setState({value});
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(
        value.toString('html')
      );
    }
  };

  save = () => {
    getPresentation(this.state.presentation_id).then(_pres => {
      if (_pres) {
        let updated_pres = {};
        Object.assign(updated_pres, _pres);
        updated_pres.instructor_notes = this.state.value.toString('html');
        console.log(updated_pres);
        updatePresentation(this.state.presentation_id, updated_pres)
                        .then(update_pres_res => {
                          if (update_pres_res) {
                            console.log("Successfully updated presentation");
                          }
                        })
      }
    })
  }

  render() {
    return (
      <View>
        <View style={styles.notesContainer}>
          <RichTextEditor
            value={this.state.value}
            onChange={this.onChange}
            placeholder={"Enter notes here..."}
            editorStyle={{height: window.window.height / 1.8}}
          />
        </View>
        <View style={{alignSelf: 'flex-end'}}>
          <MainButton active={true} text={"Save"} small={true}
          square={false} onPress={() => {this.save(this.state.value)}} />
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  notesContainer: {
    borderRadius: 30,
    marginBottom: 10,
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: window.window.height / 30,
    width: window.window.width / 3.2,
    height: window.window.height / 1.5,
  },
});
