import React, {Component} from 'react';
import {FlatList, StyleSheet, TouchableOpacity, Text, View } from 'react-native';
import { getUser, getCourse, getPresentation, getAllDatasetIDs,
  getDatasetList, getDataset, getSession} from '../custom-modules/backendAPI';
import { Colors } from '../global/Constants';
import window from '../global/Layout';
import PresentationItem from './PresentationItem';

export default class SimpleFlatList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      list: [],
      changeFlag: false,
      screen: this.props.screen,
      course_objects: {},
    }
  }
  
  componentDidMount() {
    getUser(sessionStorage.getItem('UserId'), true).then(_user => {
      if (_user) { // if non-empty result
        this.loadData(_user.course_objects);
        this.setState({course_objects: _user.course_objects});
      }
    }).catch(_err => {
        console.error(`[DashboardTab] ${_err}`);
    });
  }

  createPresentationFunc() {
    this.props.navigation.navigate("CreatePresentation", {
      session_id: this.props.session_id,
      onGoBack: () => this.loadData(this.state.course_objects),
    })
  }

  // TODO: Split to two methods
  loadData(courses) {
    let dashboardSessions = [];
    let upcomingSessionsArray = [];
    let pastSessionsArray = [];
    let presentationsArray = [];
    let datasets = [];
    let dataset_sessions = [];

    if (this.state.screen == "datasets") {
      getAllDatasetIDs().then(_dataset_list => {
        if (_dataset_list) {
          getDatasetList(_dataset_list).then(_datasets => {
            if (_datasets) {
              _datasets.forEach(dataset => {
                if (dataset.instructor_list.includes(sessionStorage.getItem('UserId'))) {
                  datasets.push(dataset);
                }
              })
              this.setState({
                list : datasets,
                changeFlag : !this.state.changeFlag,
              });
            }
          })
        }
      })
    } else if (this.state.screen == "dataset_sessions") {
      getDataset(this.props.dataset_id).then(_dataset => {
        if (_dataset) {
          _dataset.session_list.forEach(_session_id => {
            dataset_sessions = this.state.list;
            getSession(_session_id).then(_session => {
              if (_session) {
                getCourse(_session.course_id).then(_course => {
                  if (_course) {
                    let item = {..._session, course_title: _course.title}
                    dataset_sessions.push(item);
                    this.setState({
                      list : dataset_sessions,
                      changeFlag : !this.state.changeFlag,
                    });
                  }
                })
              }
            })
          })
        }
      })
    } else {
      courses.forEach(course => {
        // Populating sessions into variable
        getCourse(course._id, true).then( _course => {
          if (_course.session_objects) {
            let sessionsWithCourses = _course.session_objects.map(item => ({...item, course_title: _course.title, course_code: _course.code }))
            sessionsWithCourses.forEach(session => {
              
              // dashboardSessions.push(session); // If want to add all sessions to dashboard sessions list
              
              // Only adding the upcoming sessions because the dashboard
              // shows the "Upcoming Feedback Sessions"
              if (new Date(session.end_datetime).getTime() > Date.now()) {
                dashboardSessions.push(session);
              }
              // If we are collecting sessions for the course page,
              // we make sure to only include sessions from the course with the correct id
              if (this.props.course_id && this.props.course_id == course._id) {
                if (new Date(session.end_datetime).getTime() > Date.now()) {
                  upcomingSessionsArray.push(session);
                } else {
                  pastSessionsArray.push(session);
                }
              }
              
              if (this.props.session_id && this.props.session_id == session._id) {
                if (session.presentation_list.length > 0) {
                  session.presentation_list.forEach(_pres_id => {
                    getPresentation(_pres_id, true).then(_pres => {
                      if (_pres) {
                        _pres.courseTitle = course.title;
                        _pres.courseCode = course.code;
                        _pres.sessionTitle = session.title;
                        presentationsArray.push(_pres);
                        this.setState({
                          list : presentationsArray,
                          changeFlag : !this.state.changeFlag,
                        });
                      }
                    })
                  })
                } else {
                  this.setState({
                    list : [],
                    changeFlag : !this.state.changeFlag,
                  });
                }
              }
            })
            
            // Setting the appropriate list as data for the Flatlist object
            switch (this.state.screen) {
              case "dashboard":
                this.setState({
                  list : dashboardSessions,
                  changeFlag : !this.state.changeFlag,
                });
                break;
              case "upcomingSessions":
                this.setState({
                  list : upcomingSessionsArray,
                  changeFlag : !this.state.changeFlag,
                });
                break;
              case "pastSessions":
                this.setState({
                  list : pastSessionsArray,
                  changeFlag : !this.state.changeFlag,
                });
                break;
            }
          }
        });
      });
    }
  }
  

  render() {
    return (
      <FlatList
        data={this.state.list}
        horizontal={false}
        scrollEnabled={true}
        extraData={this.state.changeFlag}
        contentContainerStyle={{marginHorizontal: 20}}
        keyExtractor={(item) => item._id}
        renderItem={({item}) => {
          if (this.state.screen == "dashboard") {
            return (
              <TouchableOpacity style={styles.container}
                onPress={() => {this.props.navigation.navigate('Session', {
                  session: item,
                  backToDashboard: true,
              })}}>
                <View style={styles.listTextViewSession}>
                  <Text style={[styles.item,
                        {color: item.start_datetime < new Date() ? Colors.ppGreyText : null}]}>{item.title}</Text>
                </View>
                <View style={styles.listTextView}>
                  <Text style={[styles.item,
                        {color: item.start_datetime < new Date() ? Colors.ppGreyText : null}]}>{item.course_title}</Text>
                </View>
                <View style={styles.listTextView}>
                  <Text style={[styles.item,
                        {color: item.start_datetime < new Date() ? Colors.ppGreyText : null}]}>{new Date(item.start_datetime).toDateString()}</Text>
                </View>
              </TouchableOpacity>
            )
          } else if (this.state.screen == "dataset_sessions") {
            return (
              <View style={styles.container}>
                <View style={styles.listTextViewSession}>
                  <Text style={styles.item}>{item.title}</Text>
                </View>
                <View style={styles.listTextView}>
                  <Text style={styles.item}>{item.course_title}</Text>
                </View>
                <View style={styles.listTextView}>
                  <Text style={styles.item}>{new Date(item.end_datetime).toDateString()}</Text>
                </View>
              </View>
            )
          } else if (this.state.screen == "pastSessions" || this.state.screen == "upcomingSessions") {
            return (
              <TouchableOpacity style={styles.container}
                onPress={() => {this.props.navigation.navigate('Session', {
                  session: item,
              })}}>
                  <Text style={styles.item}>{item.title}</Text>
                  <Text style={styles.item}>{new Date(item.start_datetime).toDateString()}</Text>
              </TouchableOpacity>
            )
          } else if (this.state.screen == "presentations") {
            return (
              <PresentationItem presentation={item}
                                update_data={() => this.loadData(this.state.course_objects)}
                                navigation={this.props.navigation} />
            )
          } else if (this.state.screen == "datasets") {
            return (
              <TouchableOpacity style={styles.container}
                onPress={() => {this.props.navigation.navigate('DatasetScreen', {
                  dataset: item,
              })}}>
                  <Text style={styles.item}>{item.title}</Text>
                  {/* <Text style={styles.item}>{new Date(item.end_datetime).toDateString()}</Text> */}
              </TouchableOpacity>
            )
          }
        }}
        ListFooterComponent={() => {
          if ((this.state.screen == "presentations") && (sessionStorage.getItem('Role') == "listener")) {
            return (
              <TouchableOpacity style={styles.createPresentationContainer}
                                onPress={() => this.createPresentationFunc()}>
                <Text style={{fontSize: window.window.height / 30}}>+</Text>
              </TouchableOpacity>
            )
          } else {
            return (null)
          }
        }}
      />
    )}
}


const styles = StyleSheet.create({
  item: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 30,
    color: Colors.ppTextColor,
    textAlign: 'left',
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 20,
    marginBottom: 30,
    marginTop: 15,
  },
  listTextViewSession: {
    flex: 0.2,
  },
  listTextView: {
    flex: 0.14,
  },
  createPresentationContainer: {
    width: window.window.width / 4,
    height: window.window.height / 20,
    marginBottom: window.window.height / 20,
    borderColor: Colors.ppDarkGreyText,
    borderRadius: 10,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginStart: 20,
  }
});