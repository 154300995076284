
export let UserId = sessionStorage.getItem('UserId');
export let Role = sessionStorage.getItem('Role');
export let ProfilePicture = sessionStorage.getItem('ProfilePicture');
export let FirstName = sessionStorage.getItem('FirstName');

export const ENCRYPTION_KEY = "fnd3DcmkcM4dmn92FnicJmk3m248ndNdhdn24r842nfNMFD";

export function set_user(role, user_id, profile_pic, firstName) {
    UserId = user_id;
    Role = role; // Role string or "" if none is chosen
    ProfilePicture = profile_pic;
    FirstName = firstName

    // Log out
    if (role == "" && user_id == "" && !profile_pic) {
        sessionStorage.removeItem('UserId');
        sessionStorage.removeItem('Role');
        sessionStorage.removeItem('ProfilePicture');
        sessionStorage.removeItem('FirstName');
        location.reload();
    // Log in
    } else {
        sessionStorage.setItem('UserId', user_id);
        sessionStorage.setItem('Role', role);
        sessionStorage.setItem('ProfilePicture', profile_pic);
        sessionStorage.setItem('FirstName', firstName);
    }
}