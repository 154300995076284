import * as React from "react";
import { StyleSheet, Text, View, Platform, ScrollView } from "react-native";
import { Colors } from "../global/Constants";
import { createCourse } from "../custom-modules/backendAPI";
import window from "../global/Layout";
import SideMenu from "../components/SideMenu";
import MainButton from "../components/MainButton";
import TextBox from "../components/TextBox";
import { Picker } from "@react-native-picker/picker";
import Logo from '../components/Logo';
import SeparatingLine from "../components/SeparatingLine";
import BackButton from "../components/BackButton";

const COLOR_OPTIONS = 16777215;
const COURSE_NAME_LENGTH_MIN = 5;
const COURSE_NUM_LENGTH_MIN = 2;

export default function CreateCourseScreen({ navigation }) {
  const [courseName, setCourseName] = React.useState("");
  const [courseNumber, setCourseNumber] = React.useState("");
  const [semester, setSemester] = React.useState("spring_2021");
  const [errors, setErrors] = React.useState([]);

  function addError(newError) {
    let current_errors = errors;
    current_errors.push(newError);
    setErrors(current_errors);
  }

  // Verify that the new course is valid
  async function verifyCourse() {
    try {
      let newErrors = errors;
      while (newErrors.length > 0) newErrors.pop();
      setErrors(newErrors);

      if (courseName == "") {
        addError(" Course name must not be empty");
      } else if (courseName.length < COURSE_NAME_LENGTH_MIN) {
        addError(" Course name must be 5 characters or more");
      }
      if (courseNumber == "") {
        addError(" Course number must not be empty");
      } else if (courseNumber.length < COURSE_NUM_LENGTH_MIN) {
        addError(" Course number must be 2 characters or more");
      }
      if (semester == "") {
        addError(" Course semester must not be empty");
      }

      // Presenting the errors or adding the course successfully
      if (errors.length == 0) {
        add_course();
      } else {
        if (Platform.OS == "web") {
          alert(errors);
        } else {
          Alert.alert("Error", errors, [{ text: "OK" }]);
        }
      }
    } catch (E) {
      console.log(E);
    }
  }

  // Add a new course to the database
  function add_course() {
    let newCourseObj = {
      _id: "0",
      title: courseName,
      code: courseNumber,
      // Randomly generating course color
      color: "#" + Math.floor(Math.random() * COLOR_OPTIONS).toString(16),
      semester: semester,
      instructor_list: [],
      student_list: [],
      session_list: [],
    };
    try {
      createCourse(newCourseObj).then((_id) => {
        if (_id) {
          console.log("Course was created successfully!");
          navigation.goBack();
        }
      });
    } catch (E) {
      console.log(E);
    }
  }

  return (
    <View style={styles.screenContainer}>
      <View style={{ flexDirection: "row", flex: 1 }}>
        <SideMenu navigation={navigation} />
        <View style={styles.logoAndScrollContainer}>
          <Logo />
          <View style={styles.upperButtonContainer}>
            <MainButton
              active={true}
              text={"Add Courses"}
              square={false}
              onPress={() => {
                navigation.navigate("AddCourse", {
                  addCourse: true,
                  text: "Search Courses",
                });
              }}
            />
          </View>
          <BackButton backTo={"Courses"} navigation={navigation} />
          <View style={{marginStart: window.window.width / 6}} >
            <SeparatingLine />
          </View>
          <ScrollView style={styles.scrollViewContainer}>
            <Text style={styles.title}>Create new course</Text>

            <TextBox
              fieldTitle={"Course name"}
              onChangeText={(text) => setCourseName(text)}
              onSubmitEditing={() => verifyCourse()}
              value={courseName}
              secureTextEntry={false}
              placeholder={"Course Name"}
              maxLength={35}
              mandatory={true}
            />
            <TextBox
              fieldTitle={"Course number"}
              onChangeText={(text) => setCourseNumber(text)}
              onSubmitEditing={() => verifyCourse()}
              value={courseNumber}
              secureTextEntry={false}
              placeholder={"Course Number"}
              maxLength={15}
              mandatory={true}
            />
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.fieldTitle}>Semester</Text>
              <Text style={{ color: "red", fontSize: 30 }}>*</Text>
            </View>
            <View style={styles.dropdownMenuContainer}>
              <Picker
                selectedValue={semester}
                onValueChange={(value, index) => setSemester(value)}
                style={styles.picker}
                placeholder={{ label: "Semester" }}
              >
                <Picker.Item label="Fall 2021" value="fall_2021" />
                <Picker.Item label="Spring 2022" value="spring_2022" />
              </Picker>
            </View>
            <View style={styles.upperButtonContainer}>
              <MainButton
                small={true}
                active={true}
                text={"Save"}
                square={true}
                onPress={() => verifyCourse()}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  logoAndScrollContainer: {
    justifyContent: 'center',
  },
  scrollViewContainer: {
    width: window.window.width / 1.5,
    height: window.window.height,
    marginStart: window.window.width / 6,
    backgroundColor: Colors.backgroundColor,
  },
  upperButtonContainer: {
    alignSelf: "flex-end",
    marginBottom: window.window.height / 20,
  },
  picker: {
    borderWidth: 0,
    borderRadius: 10,
    fontSize: window.window.height / 50,
    width: window.window.width / 5,
    height: window.window.height / 20,
  },
  screenContainer: {
    flex: 1,
    flexDirection: "column",
  },
  title: {
    fontSize: window.window.height / 30,
    marginTop: window.window.height / 30,
    marginBottom: 30,
    fontWeight: "bold",
  },
  fieldTitle: {
    color: Colors.ppTextColor,
    fontWeight: 'bold',
    fontSize: window.window.height / 40,
    marginBottom: 10,
  },
});
