import * as React from 'react';
import { StyleSheet, Text, View, ScrollView, FlatList } from 'react-native';
import { getUser} from '../custom-modules/backendAPI'
import window from '../global/Layout'
import { Colors } from '../global/Constants';
import SideMenu from '../components/SideMenu';
import Logo from '../components/Logo';
import CourseCard from '../components/CourseCard'
import { AntDesign } from '@expo/vector-icons';

export default function CoursesTab({navigation}) {

  // TODO change semester to be automatic
  const NUM_COURSES_COLS = (window.mobile) ? 1 : 3;

  const [user, setUser] = React.useState({
    _id: '',
    roles: [],
    course_list: [],
    course_objects: [],
  })

  React.useEffect(() => {
    loadData();
    const onFocus = navigation.addListener('focus', () => {
      loadData();
    });
    return onFocus;
  }, [navigation])


  function loadData(){
    // fetch raw data & update state
    getUser(sessionStorage.getItem('UserId'), true).then(_user => {
      if (_user) { // if non-empty result
        _user.course_objects.push("+");
        setUser(_user);
      }
    }).catch(_err => {
      console.error(`[CoursesTab] ${_err}`)
    })
  }

  function loadCourseCard(item) {
    if (item != "+") {
      return (
      <CourseCard
        key={item._id} 
        plusIcon={false}
        courseCode={item.code}
        courseColor={item.color}
        onPress={() => {
          navigation.navigate('Course', {
            title: item.code.concat(" ", item.title),
            user_id: user._id,
            course: user.course_objects.filter(obj => obj._id == item._id)[0],
            admin: false,
          })
        }}>
          <Text style={[styles.courseText, {color: Colors.ppDarkGreyText}]} >{item.code}</Text>
          <Text style={styles.courseText} >{item.title}</Text>
      </CourseCard>
      )
    } else {
      return (
      <CourseCard
        plusIcon={true}
        onPress = {() => {
          if (sessionStorage.getItem('Role') != "listener") {
            navigation.navigate('CreateCourse', {
              addCourse: true,
              title: "Add a course",
              text: "Search Course..."
            })
          } else {
            navigation.navigate("AddCourse", {
              addCourse: true,
              text: "Search Courses",
            });
          }
      }}>
        <View style={styles.plusButtonContainer}>
          <AntDesign
            name="plus"
            color={Colors.ppMainPurple}
            size={(window.mobile) ?
            window.window.width / 10 :
            window.window.width / 20} />
        </View>
      </CourseCard>
      )
    }
  }

  return (
    <View style={styles.screenContainer}>
      <View style={{ flexDirection: 'row', flex: 1 }}>
        <SideMenu navigation={navigation} />
        <View style={styles.logoAndScrollContainer}>
          <Logo />
          <ScrollView style={styles.scrollViewContainer}>
            <Text style={styles.title}>Courses</Text>
            <View>
              {/* All of the current courses cards */}
              <FlatList
                data={user.course_objects}
                numColumns={NUM_COURSES_COLS}
                keyExtractor={(item) => item._id}
                scrollEnabled={true}
                renderItem={({item}) => loadCourseCard(item)}
              />
            </View>
          </ScrollView>
        </View>
      </View>
  </View>
  );
}

CoursesTab.navigationOptions = {
  header: null,
};

const styles = StyleSheet.create({
  logoAndScrollContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  scrollViewContainer: {
    width: window.window.width / 1.5,
    height: window.window.height / 1.5,
    marginStart: window.window.width / 6,
    backgroundColor: Colors.backgroundColor,
  },
  screenContainer: {
    flex: 1,
    flexDirection: "column",
  },
  plusButtonContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  courseText: {
    marginTop: 5,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: window.window.width / 90,
  },
  title: {
    fontSize: window.window.height / 30,
    marginTop: window.window.height / 30,
    marginBottom: 10,
    fontWeight: 'bold',
  },
});