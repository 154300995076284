// Searches by the field "text" of each item in "allData" according to "searchTerm"


// Search course / member
// @type param can be 'partial' (for course/member screen search)
// or 'full' (for admin home screen search)
export function searchItem(allData, searchTerm, type){
    if (searchTerm == "") {
        return allData;
    }

    let filteredData = [...allData];
    let re = new RegExp(searchTerm, 'i');
    
    filteredData.forEach((item, idx) => {
        let dataToSearch = (type == 'full') ? item.title : item.text;
        if (dataToSearch.search(re) == -1) { // not a match
            delete filteredData[idx]
        }
    })

    let result = [];
    filteredData.forEach(item => {
        result.push(item);
    })
    return result;
}

// Searches by the all tags of each item in "allData" according to "searchTerm" using "tagsDictionary"

export function searchTag(allData, searchTerm, tagsDictionary){
    if (searchTerm == "") return allData;

    let filteredData = [...allData];
    let re = new RegExp(searchTerm, 'i');

    filteredData.forEach((item, idx) => {
        let tagsMatchFlag = false
        item.tag_list.forEach(tagId => {
            if (tagsDictionary[tagId].search(re) != -1) { // match
                tagsMatchFlag = true
            }
        })
        if (!tagsMatchFlag) {
            delete filteredData[idx]
        }
    })

    let result = [];
    filteredData.forEach(item => {
        result.push(item);
    })
    return result;
}