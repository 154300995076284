import { Dimensions } from 'react-native';

let width = Math.round(Dimensions.get('window').width);
let height = Math.round(Dimensions.get('window').height);

window.onresize = updateDimensions;

export function updateDimensions() {
  location.reload();
  width = Math.round(Dimensions.get('window').width);
  height = Math.round(Dimensions.get('window').height);
};

export default {
  window: {
    width,
    height,
  },
  mobile: width < 400,
};

