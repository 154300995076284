import * as React from "react";
import { StyleSheet, Text, View, Platform, ScrollView } from "react-native";
import { Colors } from "../global/Constants";
import { getSession, updateSession, getUser, updateUser,
  createPresentation, createQuestion, updatePresentation,
  getPresentation} from "../custom-modules/backendAPI";
import window from "../global/Layout";
import SideMenu from "../components/SideMenu";
import MainButton from "../components/MainButton";
import TextBox from "../components/TextBox";
import Logo from '../components/Logo';
import SeparatingLine from "../components/SeparatingLine";
import BackButton from "../components/BackButton";

const PRESENTATION_NAME_LENGTH_MIN = 5;
const PRESENTATION_NAME_LENGTH_MAX = 30;

export default function CreatePresentationScreen({ navigation, route }) {
  const [sessionId, setSessionId] = React.useState(route.params.session_id);
  const [presentationName, setPresentationName] = React.useState("");
  const [errors, setErrors] = React.useState([]);

  function addError(newError) {
    let current_errors = errors;
    current_errors.push(newError);
    setErrors(current_errors);
  }

  // Verify that the new course is valid
  async function verifyPresentation() {
    try {
      let newErrors = errors;
      while (newErrors.length > 0) newErrors.pop();
      setErrors(newErrors);

      if (presentationName == "") {
        addError(" Course name must not be empty");
      } else if (presentationName.length < PRESENTATION_NAME_LENGTH_MIN) {
        addError(" Course name must be 5 characters or more");
      } else if (presentationName.length > PRESENTATION_NAME_LENGTH_MAX) {
        addError(" Course name must not exceed 30 characters");
      }

      // Presenting the errors or adding the course successfully
      if (errors.length == 0) {
        addPresentationToSession();
      } else {
        if (Platform.OS == "web") {
          alert(errors);
        } else {
          Alert.alert("Error", errors, [{ text: "OK" }]);
        }
      }
    } catch (E) {
      console.log(E);
    }
  }

  function addPresentationToSession() {
    let newPresentation = {
        _id: "0",
        session_id: sessionId,
        title: presentationName,
        presenter_list: [sessionStorage.getItem('UserId')],
        listener_list: [],
        question_list: [],
        instructor_notes: "",
    }
    createPresentation(newPresentation).then(presentation_id => {
        if (presentation_id) { // if non-empty result
          getSession(sessionId).then(_sess => {
            if (_sess) {
              _sess.presentation_list.push(presentation_id);
              updateSession(sessionId, {
                presentation_list: _sess.presentation_list
              }).then(_p_result => {
                if (_p_result) {
                  var user_id = sessionStorage.getItem('UserId');
                  getUser(user_id, true).then(_user => {
                    if (_user) { // if non-empty result
                        /* Add the id of the presentation
                        to the user's presentations */
                        let updated_user = {};
                        Object.assign(updated_user, _user);
                        updated_user.presentation_list.push(presentation_id);
                        delete updated_user["course_objects"];
                        delete updated_user["session_objects"];
                        delete updated_user["presentation_objects"];
                        updateUser(user_id, updated_user).then(update_user_res => {
                            if (update_user_res) {

                              // Add the pre-prepared questions as questions
                              // in the new presentation
                              let promises = [];

                              if (_sess.universal_questions) {
                                _sess.universal_questions.forEach(ques_item => {
                                  let q_obj = {
                                    _id: "0",
                                    presentation_id: presentation_id,
                                    presenter_id: sessionStorage.getItem('UserId'),
                                    text: ques_item,
                                    response_list: [],
                                  }
                                  promises.push(
                                    createQuestion(q_obj).then(_ques_id => {
                                      if (_ques_id) {
                                        return _ques_id
                                      }
                                    })
                                  )
                                });
                              }
                              
                              // Using the Promise mechanism to gather all the
                              // newly created question IDs and inserting them
                              // into the newly created presentation as
                              // question_list.
                              // If we hadn't used Promises but merely populating
                              // an array, the next call to DB would not have
                              // had the data
                              Promise.all(promises).then((arr_list) => {
                                getPresentation(presentation_id).then(_presentation => {
                                  if (_presentation) {
                                    let new_question_list = _presentation.question_list.concat(arr_list);
                                    updatePresentation(presentation_id, { question_list : new_question_list }).then(_res_update => {
                                      setPresentationName("");
                                      route.params.onGoBack();
                                      navigation.goBack();
                                    });
                                  }
                                })
                              });
                            }
                        })
                    }
                  })
                }
              });
            }
          })
        }
    })
  }


  return (
    <View style={styles.screenContainer}>
      <View style={{ flexDirection: "row", flex: 1 }}>
        <SideMenu navigation={navigation} />
        <View style={styles.logoAndScrollContainer}>
          <Logo />
          <View style={styles.upperButtonContainer} />
          <BackButton backTo={"Session"} navigation={navigation} />
          <View style={{marginStart: window.window.width / 6}} >
            <SeparatingLine />
          </View>
          <ScrollView style={styles.scrollViewContainer}>
            <Text style={styles.title}>Create new presentation</Text>

            <TextBox
              fieldTitle={"Presentation name"}
              long={true}
              onChangeText={(text) => setPresentationName(text)}
              onSubmitEditing={() => verifyPresentation()}
              value={presentationName}
              secureTextEntry={false}
              placeholder={"Presentation Name"}
              maxLength={PRESENTATION_NAME_LENGTH_MAX}
              mandatory={true}
            />
            <View style={styles.upperButtonContainer}>
              <MainButton
                small={true}
                active={true}
                text={"Save"}
                square={true}
                onPress={() => verifyPresentation()}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  logoAndScrollContainer: {
    justifyContent: 'center',
  },
  scrollViewContainer: {
    width: window.window.width / 1.5,
    height: window.window.height,
    marginStart: window.window.width / 6,
    backgroundColor: Colors.backgroundColor,
  },
  upperButtonContainer: {
    alignSelf: "flex-end",
    marginBottom: window.window.height / 20,
  },
  screenContainer: {
    flex: 1,
    flexDirection: "column",
  },
  title: {
    fontSize: window.window.height / 30,
    marginTop: window.window.height / 30,
    marginBottom: 30,
    fontWeight: "bold",
  },
  fieldTitle: {
    color: Colors.ppTextColor,
    fontWeight: 'bold',
    fontSize: window.window.height / 40,
    marginBottom: 10,
  },
});
