// const uri_header = 'http://localhost:3010/api' // TODO: pull from config
const uri_header = 'https://peerpresents-test.andrew.cmu.edu/api' // TODO: pull from config
//const uri_header = 'https://128.2.25.149:3010/api' // TODO: pull from config

const urlMap = {
  user:         `${uri_header}/id/`,
  userByEmail:  `${uri_header}/email/`,
  course:       `${uri_header}/presentation/org/course/`,
  session:      `${uri_header}/presentation/org/session/`,
  presentation: `${uri_header}/presentation/org/presentation/`,
  question:     `${uri_header}/presentation/content/question/`,
  response:     `${uri_header}/audience/response/`,
  dataset:      `${uri_header}/audience/dataset/`,
  graph:        `${uri_header}/audience/graph/`,
  upvote:       `${uri_header}/audience/upvote/`,
  react:        `${uri_header}/audience/react/`,
  star:         `${uri_header}/audience/star/`,
  tag:          `${uri_header}/audience/tag/`,
}

/* GET / Read functions */

/**
 * Fetches entity model
 * 
 * @param {string} entType - One of the keys in urlMap
 * @param {string} id - Hexadecimal string: entity's ID
 * @param {boolean} deep - Set to true to fetch entity's referenced lists
 * 
 * @return {boolean|Object} false if request failed, otherwise entity Object
 */ 
async function entGet(entType, id, deep=false) {
  try { // set URL for fetch request
    var requestURL = `${urlMap[entType]}${id}`
  } catch (error) {
    console.error(`[backendAPI module] [GET ${entType}] Request setup failed. ${error}`)
    return false
  }

  try { // send fetch request
    var response = await fetch(requestURL)
    if (!response.ok) { throw new Error(`Status: ${response.status}`) }
  } catch (error) {
    console.error(`[backendAPI module] [GET ${entType}] Network request failed. ${error}`)
    return false
  }
  try { // process response
    var data = await response.json()
    if (!data) { throw new Error(`Empty response.`) }
    if (!data.result) { throw new Error(`Response missing result field.`) }
    if (!data.message) { throw new Error(`Response missing message field.`) }
  } catch (error) {
    console.error(`[backendAPI module] [GET ${entType}] Processing response failed. ${error}`)
    return false;
  }
  var _model = Object.assign({}, data.result) // copy model
  if (deep) { // fetch all referenced models by ID
    try {
      let _jobs = [];
      switch(entType) {
        case 'user':
          // get each course
          _jobs.push(entGetList('course', _model.course_list).then(res => {
            _model.course_objects = res
          }))

          // get each session
          _jobs.push(entGetList('session', _model.session_list).then(res => {
            _model.session_objects = res
          }))

          // get each presentation
          _jobs.push(entGetList('presentation', _model.presentation_list).then(res => {
            _model.presentation_objects = res
          }))

          break;
        case 'course':
          // get each instructor
          _jobs.push(entGetList('user', _model.instructor_list).then(res => {
            _model.instructor_objects = res
          }))

          // get each student
          _jobs.push(entGetList('user', _model.student_list).then(res => {
            _model.student_objects = res
          }))

          // get each session
          _jobs.push(entGetList('session', _model.session_list).then(res => {
            _model.session_objects = res
          }))

          break;
        case 'session':
          // get each presentation
          _jobs.push(entGetList('presentation', _model.presentation_list).then(res => {
            _model.presentation_objects = res
          }))
          break;
        case 'presentation':
          // get each presenter
          _jobs.push(entGetList('user', _model.presenter_list).then(res => {
            _model.presenter_objects = res
          }))

          // get each listener
          _jobs.push(entGetList('user', _model.listener_list).then(res => {
            _model.listener_objects = res
          }))

          // get each question
          _jobs.push(entGetList('question', _model.question_list).then(res => {
            _model.question_objects = res
          }))
          break;
        case 'question':
          // get each response
          _jobs.push(entGetList('response', _model.response_list).then(res => {
            _model.response_objects = res
          }))
          break;
        case 'response':
          // get each upvote
          _jobs.push(entGetList('upvote', _model.upvote_list).then(res => {
            _model.upvote_objects = res
          }))

          // get each react
          _jobs.push(entGetList('react', _model.react_list).then(res => {
            _model.react_objects = res
          }))

          // get each star
          _jobs.push(entGetList('star', _model.star_list).then(res => {
            _model.star_objects = res
          }))

          // get each tag
          _jobs.push(entGetList('tag', _model.tag_list).then(res => {
            _model.tag_objects = res
          }))
          break;
        case 'dataset':
          // get each session
          _jobs.push(entGetList('session', _model.session_list).then(res => {
            _model.session_objects = res
          }))

          // get each graph
          _jobs.push(entGetList('graph', _model.graph_list).then(res => {
            _model.graph_objects = res
          }))

          // get each instructor
          _jobs.push(entGetList('user', _model.instructor_list).then(res => {
            _model.instructor_objects = res
          }))
          break;
        case 'graph':
          break;
        case 'upvote':
          break;
        case 'react':
          break;
        case 'star':
          break;
        case 'tag':
          break;
        default:
          throw new Error(`Unknown entType: ${entType}`)
     }

      await Promise.all(_jobs)
    } catch (error) {
      console.error(`[backendAPI module] [GET ${entType}] Fetching lists failed. ${error}`)
      return false;
    }
  }
  return _model
}


/**
 * Fetches entity model
 * 
 * @param {string} email - Hexadecimal string: user's email
 * @param {boolean} deep - Set to true to fetch entity's referenced lists
 * 
 * @return {boolean|Object} false if request failed, otherwise entity Object
 */ 
async function getUserByEmail(email, deep=false) {
  try { // set URL for fetch request
    var requestURL = `${urlMap['userByEmail']}${email}`
  } catch (error) {
    console.error(`[backendAPI module] [GET USER] Request setup failed. ${error}`)
    return false
  }

  try { // send fetch request
    var response = await fetch(requestURL);
    if (!response.ok) { throw new Error(`Status: ${response.status}`) }
  } catch (error) {
    console.error(`[backendAPI module] [GET USER] Network request failed. ${error}`)
    return false
  }
  try { // process response
    var data = await response.json()
    if (!data) { throw new Error(`Empty response.`) }
    if (!data.result) { throw new Error(`Response missing result field.`) }
    if (!data.message) { throw new Error(`Response missing message field.`) }
  } catch (error) {
    console.error(`[backendAPI module] [GET USER] Processing response failed. ${error}`)
    return false;
  }
  var _model = Object.assign({}, data.result) // copy model
  if (deep) { // fetch all referenced models by ID
    try {
      let _jobs = [];
      // get each course
      _jobs.push(entGetList('course', _model.course_list).then(res => {
        _model.course_objects = res
      }))

      // get each session
      _jobs.push(entGetList('session', _model.session_list).then(res => {
        _model.session_objects = res
      }))

      // get each presentation
      _jobs.push(entGetList('presentation', _model.presentation_list).then(res => {
        _model.presentation_objects = res
      }))
      await Promise.all(_jobs)
    } catch (error) {
      console.error(`[backendAPI module] [GET USER] Fetching lists failed. ${error}`)
      return false;
    }
  }
  return _model
}

/**
 * Fetches lists of models by ID
 * 
 * @param {string} entType - One of the keys in urlMap
 * @param {string[]} idList  - Array of entity IDs (Hexadecimal strings)
 * 
 * @return {Object[]} Empty Array if failed, otherwise Array of model Objects
 */
async function entGetList(entType, idList) {
  let _list = []
  let promises = []

  try {
    // populate list
    if (idList.length >0) idList.forEach(_id => {
      promises.push(entGet(entType, _id));
    });

    let results = await Promise.all(promises) // wait for all fetch alls to complete

    results.forEach(res => {
      if (res) {
          _list.push(res)
      }
    })
  } catch (error) {
    console.error(`[backendAPI module] [entGetList ${entType}] ${error}`)
    return []
  }
  return _list
}

/**
 * Fetches a list of IDs for all models in the DB
 * 
 * @param {string} entType - One of the keys in urlMap
 * 
 * @return {Object[]} Array of entity IDs (Hexadecimal strings)
 */
async function entGetAllIDs(entType){
  try { // set URL for fetch request
    var requestURL = `${urlMap[entType]}`
  } catch (error) {
    console.error(`[backendAPI module] [entGetAllIDs ${entType}] Request setup failed. ${error}`)
    return false
  }

  try { // send fetch request
    var response = await fetch(requestURL)
  } catch (error) {
    console.error(`[backendAPI module] [entGetAllIDs ${entType}] Network request failed. ${error}`)
    return false
  }
  try {
    try{
      var data = await response.json()
    } catch (e) {
      throw new Error(`malformeted JSON response ${e}`)
    }
    if (!data) { throw new Error(`Empty response.`) }
    if (!data.result) { throw new Error(`Response missing result field.`) }
  } catch (error) {
    console.error(`[backendAPI module] [entGetAllIDs ${entType}] Processing response failed. ${error}`)
    return false;
  }
  return data.result
}

// Wrapper functions for entGet()
async function getUser(id, deep=false){
  return entGet('user', id, deep);
}
async function getCourse(id, deep=false){
  return entGet('course', id, deep);
}
async function getSession(id, deep=false){
  return entGet('session', id, deep);
}
async function getPresentation(id, deep=false){
  return entGet('presentation', id, deep);
}
async function getQuestion(id, deep=false){
  return entGet('question', id, deep);
}
async function getResponse(id, deep=false){
  return entGet('response', id, deep);
}
async function getDataset(id, deep=false){
  return entGet('dataset', id, deep);
}
async function getGraph(id, deep=false){
  return entGet('graph', id, deep);
}
async function getUpvote(id, deep=false){
  return entGet('upvote', id, deep);
}
async function getReact(id, deep=false){
  return entGet('react', id, deep);
}
async function getStar(id, deep=false){
  return entGet('star', id, deep);
}
async function getTag(id, deep=false){
  return entGet('tag', id, deep);
}

// Wrapper functions for entGetAllIDs() 
async function getAllUserIDs(){
  return entGetAllIDs('user')
}
async function getAllCourseIDs(){
  return entGetAllIDs('course')
}
async function getAllSessionIDs(){
  return entGetAllIDs('session')
}
async function getAllPresentationIDs(){
  return entGetAllIDs('presentation')
}
async function getAllQuestionIDs(){
  return entGetAllIDs('question')
}
async function getAllResponseIDs(){
  return entGetAllIDs('response')
}
async function getAllDatasetIDs(){
  return entGetAllIDs('dataset')
}
async function getAllGraphIDs(){
  return entGetAllIDs('graph')
}
async function getAllUpvoteIDs(){
  return entGetAllIDs('upvote')
}
async function getAllReactIDs(){
  return entGetAllIDs('react')
}
async function getAllStarIDs(){
  return entGetAllIDs('star')
}
async function getAllTagIDs(){
  return entGetAllIDs('tag')
}

// Wrapper functions for entGetList()
async function getUserList(idList) {
  return entGetList('user', idList)
}
async function getCourseList(idList) {
  return entGetList('course', idList)
}
async function getSessionList(idList) {
  return entGetList('session', idList)
}
async function getPresentationList(idList) {
  return entGetList('presentation', idList)
}

async function getQuestionList(idList) {
  return entGetList('question', idList)
}
async function getResponseList(idList) {
  return entGetList('response', idList)
}
async function getDatasetList(idList) {
  return entGetList('dataset', idList)
}
async function getGraphList(idList) {
  return entGetList('graph', idList)
}
async function getUpvoteList(idList) {
  return entGetList('upvote', idList)
}
async function getReactList(idList) {
  return entGetList('react', idList)
}
async function getStarList(idList) {
  return entGetList('star', idList)
}
async function getTagList(idList) {
  return entGetList('tag', idList)
}

/* Put / Update functions */

/**
 * 
 * @param {string} entType - One of the keys in urlMap
 * @param {string} id - Hexadecimal string: entity's ID
 * @param {Object} model - Object containing Entity fields to be updated
 * 
 * @return {boolean|string} false if request failed, otherwise entity's ID (Hexadecimal string)
 */
async function entUpdate(entType, id, model){
  try { // set URL for fetch request
    var requestURL = `${urlMap[entType]}${id}`
  } catch (error) {
    console.error(`[backendAPI module] [PUT ${entType}] Request setup failed. ${error}`)
    return false
  }
  try { // prep request body
    model = JSON.stringify(model)
    var body = JSON.stringify({model: model})
  } catch (error) {
    console.error(`[backendAPI module] [PUT ${entType}] Converting input model failed. ${error}`)
    return false
  }
  try { // send fetch request
    var response = await fetch(requestURL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    })
    if (!response.ok) { throw new Error(`Status: ${response.status}`) }
  } catch (error) {
    console.error(`[backendAPI module] [PUT ${entType}] Network request failed. ${error}`)
    return false
  }
  // successful update returns no response body

  return true
}

// Wrapper functions for entUpdate()
async function updateUser(id, model){
  return entUpdate('user', id, model);
}
async function updateCourse(id, model){
  return entUpdate('course', id, model);
}
async function updateSession(id, model){
  return entUpdate('session', id, model);
}
async function updatePresentation(id, model){
  return entUpdate('presentation', id, model);
}
async function updateQuestion(id, model){
  return entUpdate('question', id, model);
}
async function updateResponse(id, model){
  return entUpdate('response', id, model);
}
async function updateDataset(id, model){
  return entUpdate('dataset', id, model);
}
async function updateGraph(id, model){
  return entUpdate('graph', id, model);
}
async function updateUpvote(id, model){
  return entUpdate('upvote', id, model);
}
async function updateReact(id, model){
  return entUpdate('react', id, model);
}
async function updateStar(id, model){
  return entUpdate('star', id, model);
}
async function updateTag(id, model){
  return entUpdate('tag', id, model);
}



/* Post / Create functions */

/**
 * 
 * @param {string} entType - One of the keys in urlMap
 * @param {string} id - Hexadecimal string: entity's ID
 * @param {Object} model - Object containing Entity to be created (requires all fields in schema)
 * 
 * @return {boolean|string} false if request failed, otherwise entity's ID (Hexadecimal string)
 */
async function entCreate(entType, model){
  try { // set URL for fetch request
    var requestURL = `${urlMap[entType]}`
  } catch (error) {
    console.error(`[backendAPI module] [POST ${entType}] Request setup failed. ${error}`)
    return false
  }
  try { // prep request body
    model = JSON.stringify(model)
    var body = JSON.stringify({model: model})
  } catch (error) {
    console.error(`[backendAPI module] [POST ${entType}] Converting input model failed. ${error}`)
    return false
  }
  try { // send fetch request
    var response = await fetch(requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    })
    if (!response.ok) { throw new Error(`Status: ${response.status}`) }
  } catch (error) {
    console.error(`[backendAPI module] [POST ${entType}] Network request failed. ${error}`)
    return false
  }
  try { // process response
    try{
      var data = await response.json()
    } catch (e) {
      throw new Error(`malformeted JSON response ${e}`)
    }
    if (!data) { throw new Error(`Empty response`) }
    if (!data.result) { throw new Error(`Response missing result field`) }
    if (!data.message) { throw new Error(`Response missing message field`) }
    if (!data.result.id) { throw new Error(`Response result missing id field`) }
  } catch (error) { 
    console.error(`[backendAPI module] [POST ${entType}] Processing response failed. ${error}`)
    return false
  }

  return data.result.id
}

// Wrapper functions for entCreat()
async function createUser(model){
  return entCreate('user', model);
}
async function createCourse(model){
  return entCreate('course', model);
}
async function createSession(model){
  return entCreate('session', model);
}
async function createPresentation(model){
  return entCreate('presentation', model);
}
async function createQuestion(model){
  return entCreate('question', model);
}
async function createResponse(model){
  return entCreate('response', model);
}
async function createDataset(model){
  return entCreate('dataset', model);
}
async function createGraph(model){
  return entCreate('graph', model);
}
async function createUpvote(model){
  return entCreate('upvote', model);
}
async function createReact(model){
  return entCreate('react', model);
}
async function createStar(model){
  return entCreate('star', model);
}
async function createTag(model){
  return entCreate('tag', model);
}

/* Del / Delete functions */

/**
 * 
 * @param {string} entType - One of the keys in urlMap
 * @param {string} id - Hexadecimal string: entity's ID
 * 
 * @return {boolean} false if request failed, otherwise true
 */
async function entDelete(entType, id){

  switch (entType) {
    case 'course':
      deleteList('sessions', id);
    break;
    case 'session':
      deleteList('presentations', id);
    break;
    case 'presentation':
      deleteList('questions', id);
    break;
    case 'question':
      deleteList('responses', id);
    break;
    case 'response':
      deleteList('upvotes', id);
      deleteList('reacts', id);
      deleteList('stars', id);
      deleteList('tags', id);
    break;
    case 'dataset':
      deleteList('graphs', id);
    break;

    default: break;
  }

  try { // set URL for fetch request
    var requestURL = `${urlMap[entType]}${id}`
  } catch (error) {
    console.error(`[backendAPI module] [DELETE ${entType}] Request setup failed. ${error}`)
    return false
  }
  try { // send fetch request
    var response = await fetch(requestURL, {
      method: 'DELETE'
    })
    if (!response) { throw new Error(`Empty response from server: ${response}`)}
    if (!response.ok) { throw new Error(`Status: ${response.status}`) }
  } catch (error) {
    console.error(`[backendAPI module] [DELETE ${entType}] Network request failed. ${error}`)
    return false
  }

  // successful delete returns no response body

  return true
}

// Wrapper functions for deleting an entire list of entities

/**
* @param {string} id - Hexadecimal string: entity's ID
*/
async function deleteList(type, id) {

  switch(type) {

    case 'sessions': 
      getCourse(id).then(_res => {
        if (_res) {
          _res.session_list.forEach(_session_id => {
            try {
              deleteSession(_session_id).then({});
            } catch(E) {
              console.log(E);
            }
          }) 
        }
      });
      break;
    
    case 'presentations':
      getSession(id).then(_res => {
        if (_res) {
          _res.presentation_list.forEach(_presentation_id => {
            try {
              deletePresentation(_presentation_id).then({});
            } catch(E) {
              console.log(E);
            }
          }) 
        }
      });
      break;

    case 'questions':
      getPresentation(id).then(_res => {
        if (_res) {
          _res.question_list.forEach(_question_id => {
            try {
              deleteQuestion(_question_id).then({});
            } catch(E) {
              console.log(E);
            }
          }) 
        }
      });
      break;
    
    case 'responses':
      getQuestion(id).then(_res => {
        if (_res) {
          _res.response_list.forEach(_response_id => {
            try {
              deleteResponse(_response_id).then({});
            } catch(E) {
              console.log(E);
            }
          }) 
        }
      });
    break;

    case 'graphs':
      getDataset(id).then(_res => {
        if (_res) {
          _res.graph_list.forEach(_graph_id => {
            try {
              deleteGraph(_graph_id).then({});
            } catch(E) {
              console.log(E);
            }
          }) 
        }
      });
    break;

    case 'upvotes':
      getResponse(id).then(_res => {
        if (_res) {
          _res.upvote_list.forEach(_upvote_id => {
            try {
              deleteUpvote(_upvote_id).then({});
            } catch(E) {
              console.log(E);
            }
          }) 
        }
      });
    break;

    case 'reacts':
      getResponse(id).then(_res => {
        if (_res) {
          _res.react_list.forEach(_react_id => {
            try {
              deleteReact(_react_id).then({});
            } catch(E) {
              console.log(E);
            }
          }) 
        }
      });
    break;

    case 'stars':
      getResponse(id).then(_res => {
        if (_res) {
          _res.star_list.forEach(_star_id => {
            try {
              deleteStar(_star_id).then({});
            } catch(E) {
              console.log(E);
            }
          }) 
        }
      });
    break;

    case 'tags':
      getResponse(id).then(_res => {
        if (_res) {
          _res.tag_list.forEach(_tag_id => {
            try {
              deleteTag(_tag_id).then({});
            } catch(E) {
              console.log(E);
            }
          }) 
        }
      });
    break;

  }
}


// Wrapper functions for entDelete()

async function deleteUser(id) {
  return entDelete('user', id);
}
async function deleteCourse(id) {
  return entDelete('course', id);
}
async function deleteSession(id) {
  return entDelete('session', id);
}
async function deletePresentation(id) {
  return entDelete('presentation', id);
}
async function deleteQuestion(id) {
  return entDelete('question', id);
}
async function deleteResponse(id) {
  return entDelete('response', id);
}
async function deleteDataset(id) {
  return entDelete('dataset', id);
}
async function deleteGraph(id) {
  return entDelete('graph', id);
}
async function deleteUpvote(id) {
  return entDelete('upvote', id);
}
async function deleteReact(id){
  return entDelete('react', id);
}
async function deleteStar(id)  {
  return entDelete('star', id);
}
async function deleteTag(id) {
  return entDelete('tag', id);
}

export {
    getUser, getCourse, getSession, getPresentation,
    getQuestion, getResponse, getDataset, getGraph,
    getUpvote, getReact, getStar, getTag,

    getUserByEmail,

    getAllUserIDs, getAllCourseIDs, getAllSessionIDs,
    getAllPresentationIDs, getAllQuestionIDs, getAllResponseIDs,
    getAllDatasetIDs, getAllGraphIDs,
    getAllUpvoteIDs, getAllReactIDs, getAllStarIDs, getAllTagIDs,

    getCourseList, getSessionList, getPresentationList,
    getUserList, getQuestionList, getResponseList,
    getDatasetList, getGraphList,
    getUpvoteList, getReactList, getStarList, getTagList,
    
    updateUser, updateCourse, updateSession, updatePresentation,
    updateQuestion, updateResponse, updateDataset, updateGraph,
    updateUpvote, updateReact, updateStar, updateTag,
   
    createUser, createCourse, createSession, createPresentation,
    createQuestion, createResponse, createDataset, createGraph,
    createUpvote, createReact, createStar, createTag,
   
    deleteUser, deleteCourse, deleteSession, deletePresentation,
    deleteQuestion, deleteResponse, deleteDataset, deleteGraph,
    deleteUpvote, deleteReact, deleteStar, deleteTag,
}
