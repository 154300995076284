import * as React from "react";
import { StyleSheet, Text, View, ScrollView } from "react-native";
import { Colors } from "../global/Constants";
import window from "../global/Layout";
import SideMenu from "../components/SideMenu";
import MainButton from "../components/MainButton";
import SeparatingLine from "../components/SeparatingLine";
import BackButton from "../components/BackButton";
import Logo from "../components/Logo";
import { Picker } from "@react-native-picker/picker";
import { VictoryBar, VictoryChart, VictoryAxis, VictoryGroup } from "victory";
import SimpleFlatList from "../components/SimpleFlatList";

export default function DatasetScreen({ route, navigation }) {
  // will get assigned upon page load; currently has dummy variables
  const [variables, setVariables] = React.useState({
    titleVariable: "",
    totalAverageVariable: "",
    personTeamVariable: "",
  });

  const [dataset, setDataset] = React.useState(route.params.dataset)

  const sessionsData = [
    {
      session: "Game Design Project 1",
      class: "Game Design",
      date: "11/12/2020",
    },
    { session: "Final Project", class: "Social Web", date: "11/25/2020" },
    {
      session: "Documentation Design Final",
      class: "Design Educational Games",
      date: "12/04/2020",
    },
  ];

  const graphData = [
    [
      { x: 1, y: 6 },
      { x: 2, y: 4 },
      { x: 3, y: 2 },
    ],
    [
      { x: 1, y: 4 },
      { x: 2, y: 8 },
      { x: 3, y: 5 },
    ],
    [
      { x: 1, y: 2 },
      { x: 2, y: 6 },
      { x: 3, y: 8 },
    ],
    [
      { x: 1, y: 2 },
      { x: 2, y: 2 },
      { x: 3, y: 1 },
    ],
    [
      { x: 1, y: 1 },
      { x: 2, y: 4 },
      { x: 3, y: 6 },
    ],
    [
      { x: 1, y: 1 },
      { x: 2, y: 6 },
      { x: 3, y: 4 },
    ],
  ];

  return (
    <View style={styles.screenContainer}>
      <View style={{ flexDirection: "row", flex: 1 }}>
        <SideMenu navigation={navigation} />
        <View style={styles.logoAndScrollContainer}>
          <Logo />
          <View style={styles.upperButtonContainer}>
            <MainButton active={true} text={"Export Data"} square={false} />
          </View>
          <BackButton backTo={"Datasets"} navigation={navigation} />
          <View style={{marginStart: window.window.width / 6}} >
            <SeparatingLine />
          </View>
          <ScrollView style={styles.scrollViewContainer}>
            <Text style={styles.title}>{dataset.title}</Text>
            <View style={styles.session}>
              <View style={styles.sessionContainer}>
                <Text style={styles.sessionTitles}>Sessions in Dataset</Text>
                <Text style={styles.sessionTitles}>Class</Text>
                <Text style={styles.sessionTitles}>End Date</Text>
              </View>
              <SimpleFlatList
                screen={"dataset_sessions"}
                navigation={navigation}
                dataset_id={dataset._id}
              />
            </View>
            <View style={styles.graph}>
              <Text style={styles.graphText}>Select variables to visualize</Text>
              <Picker
                selectedValue={variables.titleVariable}
                onValueChange={(value) =>
                  setDataset({ ...variables, titleVariable: value })
                }
                style={styles.picker}
                placeholder={{ label: "Select data type" }}
              >
                <Picker.Item
                  style={styles.pickerItem}
                  label="Number of Questions Asked"
                  value="questionNum"
                />
                <Picker.Item
                  style={styles.pickerItem}
                  label="Number of Textual Feedbacks to Questions"
                  value="textFeedback"
                />
                <Picker.Item
                  style={styles.pickerItem}
                  label="Number of Emoji Reactions to Feedbacks"
                  value="emoji"
                />
                <Picker.Item
                  style={styles.pickerItem}
                  label="Number of Star Rating Reactions to Feedbacks"
                  value="star"
                />
              </Picker>
              {/* graph */}

              <VictoryChart
                height={window.window.height / 2}
                width={window.window.width / 2}
              >
                <VictoryAxis
                  // tickValues specifies both the number of ticks and where
                  // they are placed on the axis
                  tickValues={[1, 2, 3]}
                  tickFormat={sessionsData.map((value) => value.session)}
                />
                <VictoryAxis
                  dependentAxis
                  // tickFormat specifies how ticks should be displayed
                  tickFormat={(x) => x}
                />
                <VictoryGroup
                  offset={window.window.width / 70}
                  colorScale={"blue"}
                >
                  {graphData.map((data) => (
                    <VictoryBar data={data} />
                  ))}
                </VictoryGroup>
              </VictoryChart>

              <View style={styles.subPickers}>
                <Picker
                  selectedValue={variables.totalAverageVariable}
                  onValueChange={(value) =>
                    setDataset({ ...variables, totalAverageVariable: value })
                  }
                  style={styles.subPicker}
                  placeholder={{ label: "select variable" }}
                >
                  <Picker.Item
                    style={styles.subPickerItem}
                    label="total"
                    value="total"
                  />
                  <Picker.Item
                    style={styles.subPickerItem}
                    label="average"
                    value="average"
                  />
                </Picker>
                <Picker
                  selectedValue={variables.personTeamVariable}
                  onValueChange={(value) =>
                    setDataset({ ...variables, personTeamVariable: value })
                  }
                  style={styles.subPicker}
                  placeholder={{ label: "select variable" }}
                >
                  <Picker.Item
                    style={styles.subPickerItem}
                    label="from person"
                    value="from person"
                  />
                  <Picker.Item
                    style={styles.subPickerItem}
                    label="from team"
                    value="from team"
                  />
                </Picker>
              </View>
            </View>
            <View style={styles.lowerButtonContainer}>
              <MainButton active={true} text={"Add Graph"} square={false} />
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  logoAndScrollContainer: {
    justifyContent: 'center',
  },
  scrollViewContainer: {
    width: window.window.width / 1.5,
    height: window.window.height,
    marginStart: window.window.width / 6,
    backgroundColor: Colors.backgroundColor,
  },
  upperButtonContainer: {
    alignSelf: "flex-end",
    marginBottom: window.window.height / 20,
  },
  screenContainer: {
    flex: 1,
    flexDirection: "column",
  },
  title: {
    fontSize: window.window.height / 30,
    marginTop: window.window.height / 30,
    marginBottom: 30,
    fontWeight: "bold",
  },
  session: {
    marginTop: 30,
    borderRadius: 30,
    marginBottom: 25,
    backgroundColor: "white",
    width: window.window.width / 1.5,
    height: window.window.height / 3,
  },
  sessionContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: 20,
    marginBottom: 30,
    marginTop: 15,
  },
  sessionTitles: {
    fontWeight: "bold",
    fontSize: 20,
    color: Colors.ppGreyText,
  },
  graphText: {
    fontSize: 15,
    color: Colors.ppGreyText,
    marginTop: 20,
    marginLeft: 30,
  },
  picker: {
    marginTop: 30,
    marginLeft: 30,
    marginRight: 30,
    borderWidth: 1,
    borderRadius: 10,
    borderColor: Colors.ppGreyText,
    fontSize: window.window.height / 50,
    width: window.window.width / 1.6,
    height: window.window.height / 10,
    fontSize: 20,
    fontWeight: "bold",
    color: Colors.ppTextColor,
    paddingLeft: 10,
  },
  pickerItem: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 30,
    color: Colors.ppTextColor,
  },
  graph: {
    marginTop: 30,
    borderRadius: 30,
    marginBottom: 25,
    backgroundColor: "white",
    width: window.window.width / 1.5,
    height: window.window.height / 1.1,
  },
  subPickers: {
    marginVertical: 10,
    marginLeft: 30,
    marginRight: 30,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subPicker: {
    borderColor: Colors.ppGreyText,
    padding: 10,
    borderWidth: 1,
    borderRadius: 10,
    fontSize: 18,
  },
  lowerButtonContainer: {
    alignSelf: "flex-end",
    marginBottom: window.window.height / 20,
    marginTop: window.window.height / 20,
  },
});
