
// ************ VARIABLES ************

// AdminEmails

export const AdminEmails = [
    "gfleissi@andrew.cmu.edu",
    "ascook@memphis.edu",
    "jkemper@andrew.cmu.edu",
    "a.to@northeastern.edu",
    "spdow@ucsd.edu",
    "anna.cox@ucl.ac.uk",
    "tcorbett@andrew.cmu.edu",
    "kuba.maruszczyk.18@ucl.ac.uk",
    "leya.george.14@ucl.ac.uk",
    "tvonlind@andrew.cmu.edu",
    "harpstead@cmu.edu",
    "hammerj@andrew.cmu.edu",
    "lkirabo@andrew.cmu.edu",
    "aamonett@andrew.cmu.edu",

    "juliob1371@gmail.com",
    "Julius.Pratcher@icloud.com",
    "adamcolejones@icloud.com",
]

export const ADMIN_EMAIL_KEY = "nfakl3Fmkc3c985nDNFcnAMFf85niFnFN8bF84BBMK";


// Colors

const tintColor = '#2f95dc';

export const Colors = {
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  ppMainOrange: '#de813a',
  ppSecondaryOrange: '#fbab41',
  ppMainPurple: '#5355A1',
  ppStarsColor: '#f1c40f',
  ppTagColor: '#53607e',
  ppReactionColor: '#91a1c7',
  ppLighterBlue: '#5776A1',
  backgroundColor: '#F1F2F8',
  ppTextColor: '#15264E',
  ppGreyText: '#979797',
  ppDarkGreyText: '#717171',
  ppGreyDisabled: '#D6D8E1',
  ppInstructorStar: '#FB8B07',
  ppUserBlue: '#5776A1',
  ppRed: '#f52c50',
  ppLightGrey: '#c4c4c4',
}

// Reactions

export const Reactions = [
    {id: 1, reaction: '❤️'}, // Loving
    {id: 2, reaction: '👍'}, // Liking
    {id: 3, reaction: '💡'}, // Insightful
    {id: 4, reaction: '👏'}, // Clapping
    {id: 5, reaction: '❓'}, // Confused
]

// Strings

export const Strings = {
  CONSENT_QUESTION: "Do you agree to share anonymous data for research purposes?",
  CONSENT_YES: ", you can use my anonymous data for research",
  CONSENT_NO: ", I want to use PeerPresents without sharing",
  SEARCH_PROMPT: "Search by course name / number or session name / number",
}
