import React, { Component } from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import window from '../global/Layout.js'
import { Colors } from '../global/Constants'

export default class BackButton extends Component {

  render() {
    return (
      <TouchableOpacity style={{alignSelf: 'flex-start', marginStart: window.window.width / 6}} onPress={() => {
        if (this.props.onGoBack) this.props.onGoBack();
        this.props.navigation.goBack()
      }}>
        <Text style={styles.textStyle}>{'<'} Back to {this.props.backTo}</Text>
      </TouchableOpacity>
    )
  }
}

const styles = StyleSheet.create({
  textStyle: {
    color: Colors.ppDarkGreyText,
    fontSize: 20,
    marginBottom: 10,
  }
});
