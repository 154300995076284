import React, { Component } from 'react';
import { StyleSheet, View, Text, TextInput,
  TouchableOpacity, Platform } from 'react-native';
import { Colors } from '../global/Constants';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import CancelSaveDeletePanel from "../components/CancelSaveDeletePanel";
import window from "../global/Layout.js";
import { updateQuestion, updatePresentation,
  deleteQuestion } from "../custom-modules/backendAPI";
import { AntDesign } from '@expo/vector-icons';

const confirm_delete_message =
  "Are you sure you want to delete this question? This cannot be undone";

export default class QuestionStudent extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      editQuestion: false,
      questionText: this.props.question.text,
      questions: this.props.questions,
      presentation: this.props.presentation,

      errors: [],
    };
  }

  addError(newError) {
    let current_errors = this.state.errors;
    current_errors.push(newError)
    this.setState({ errors : current_errors });
  }
  
  // Verify that the question is valid
  async verifyQuestion() {
    try {

      // Clearing the array of errors
      let newErrors = this.state.errors;
      while(newErrors.length > 0) newErrors.pop();
      this.setState({ errors : newErrors});

      // text length invalid
      if (this.state.questionText.length < 1 || this.state.questionText.length > 500) {
        addError(" Question text must be between 1 and 500 characters")
      }
      
      if (this.state.errors.length == 0) {
        this.edit_question();
      } else {
        if (Platform.OS == 'web') {
          alert(this.state.errors)
        } else {
          Alert.alert(
            "Error",
            this.state.errors,
            [ { text: "OK" } ] )
        }
      }
    } catch (E) {
      console.log(E);
    }
  }

  // Edit question in the database
  edit_question() {
    updateQuestion(this.props.question._id, {text: this.state.questionText}).then( res => {
      if (res) {
        this.setState({ editQuestion : false});
        this.props.loadData();
      }
    })
    .catch( err => {
      // alert user that update question failed
      console.error(`Update question failed. ${err}`)
    })
  }

  // Delete question dialog
  delete_question_dialog() {
    if (Platform.OS == 'web') {
      var response = confirm(confirm_delete_message);
      if (response) {
        this.delete_question();
      }
    } else {
      Alert.alert(
        "Delete question",
        confirm_delete_message,
        [ { text: "Yes", onPress: () => {
          this.delete_question();
        } },
          { text: "No" }
      ])
    }
  }

  // Delete question in the database
  delete_question() {
    // Delete question
    deleteQuestion(this.props.question._id).then((res) => {
      // update Presentation's question list
      console.log(res);
      if (res) {
        // Get a copy of question_list with item._id removed
        let _list = this.props.presentation.question_list.filter((val) => {
          return val != this.props.question._id;
        });
        console.log(_list);
        updatePresentation(this.props.presentation._id, {
          question_list: _list,
        })
        .then((_res) => {
          console.log(`Updated question_list`);
          this.setState({ editQuestion : false });
          this.props.loadData();
        })
        .catch((err) => {
          console.error(
            `Failed to update presentation after delete question. ${err}`
          );
        });
      }
    })
  }

  render() {
    return (
      <View style={{flexDirection : 'row'}}>
        
        {/* Showing the question if the student is a listener or if the
        student is a presenter not editing the question */}
        {((this.state.presentation.presenter_list.includes(sessionStorage.getItem('UserId')) && !this.state.editQuestion) ||
        (!this.state.presentation.presenter_list.includes(sessionStorage.getItem('UserId')))) &&                          
          <View style={styles.questionTitle}>
            <View style={{width: window.window.width / (1.6), flexDirection: 'row', justifyContent: 'space-between', marginBottom: 30}}>
              <View style={{flexDirection: 'row'}}>
                <Text style={{fontSize: 35, fontWeight: 'bold', marginStart: 25, marginEnd: 20, color: 'black'}}>
                  Q{this.props.questions.indexOf(this.props.question) + 1}
                </Text>
                <Text style={{fontSize: 23, fontWeight: 'bold', marginEnd: 40, color: 'black', alignSelf: 'center'}}>
                  {this.props.question.text}
                </Text>
              </View>
              {(this.state.presentation.presenter_list.includes(sessionStorage.getItem('UserId')) && !this.state.editQuestion) &&
              <TouchableOpacity onPress={() => this.setState({ editQuestion : true})}
                style={styles.editButtonContainer}>
                  <MaterialCommunityIcons name="pencil-outline" size={window.window.height / 40} color={Colors.ppTextColor} />
              </TouchableOpacity>
              }
            </View>
          </View>
        }

        {/* Showing the text input box and the button panel if the student
        is a presenter editing the question text */}
        {this.state.presentation.presenter_list.includes(sessionStorage.getItem('UserId')) &&
          this.state.editQuestion &&  

          <View style={styles.questionTitle}>
            <View style={{width: window.window.width / (1.6), flexDirection: 'row', justifyContent: 'space-between'}}>
              <View style={{flexDirection: 'row'}}>
                <Text style={{fontSize: 35, fontWeight: 'bold', marginStart: 25, marginEnd: 20, color: 'black'}}>
                  Q{this.props.questions.indexOf(this.props.question) + 1}
                </Text>
                <TextInput style={styles.textInputEdit}
                  value={this.state.questionText}
                  maxLength={500}
                  onChangeText={text => this.setState({ questionText : text })}
                />
              </View>
              <View style={{justifyContent: 'center', flexDirection: 'row'}}>
                <View style={styles.upDownButtonContainer}>
                  <TouchableOpacity onPress={() => {this.props.moveUp(); this.setState({ editQuestion : false })}}>
                    <AntDesign name="arrowup" size={24} color={Colors.ppTextColor} />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => {this.props.moveDown(); this.setState({ editQuestion : false })}}>
                    <AntDesign name="arrowdown" size={24} color={Colors.ppTextColor} />
                  </TouchableOpacity>
                </View>
                <CancelSaveDeletePanel delete_option={true}
                                  cancel_func={() => this.setState({ editQuestion : false, questionText : this.props.question.text})}
                                  save_func={() => this.verifyQuestion()}
                                  delete_func={() => this.delete_question_dialog()} />
              </View>
            </View>
          </View>
        }
      </View>
    )
  }
}

const styles = StyleSheet.create({
  questionTitle: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: window.window.width * (1/3),
    height: window.window.height / 10,
    marginStart: window.window.width / 40,
    marginTop: window.window.height / 40,
    borderRadius: 30,
  },
  textInputEdit: {
    backgroundColor: 'white',
    width: window.window.width / 4,
    height: window.window.height / 20,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: Colors.ppMainPurple,
    marginEnd: 20
  },
  editButtonContainer: {
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    borderRadius: 5,
    borderColor: Colors.ppTextColor,
    marginEnd: window.window.width / 50,
    width: window.window.height / 25,
    height: window.window.height / 25,
  },
  upDownButtonContainer: {
    flexDirection: 'row',
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    borderColor: Colors.ppTextColor,
    marginEnd: window.window.width / 50,
    width: window.window.width / 25,
    height: window.window.height / 27,
  }
});
