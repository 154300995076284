import React, { Component } from "react";
import { View, FlatList, StyleSheet,
  ScrollView, TouchableOpacity, TextInput, Text } from "react-native";
import { Colors } from '../global/Constants';
import window from "../global/Layout";
import { FontAwesome } from '@expo/vector-icons';

export default class UniversalQuestions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions_list: this.props.initial_questions,
      indices: Array(this.props.initial_questions.length).fill().map((x,i)=>i),
    };
  }

  removeQuestion(index) {

    if (this.state.questions_list.length > 1) {
      let current_questions_list = this.state.questions_list;
      let current_indices = this.state.indices;
  
      if (index > -1) {
        current_questions_list.splice(index, 1);
        current_indices.pop();
      }
  
      this.setState({
        questions_list : current_questions_list,
        indices : current_indices
      });
      
      this.props.set_questions(current_questions_list);
    }
  }

  addQuestion() {
    let current_questions_list = this.state.questions_list;
    let current_indices = this.state.indices;

    current_questions_list.push("");
    let current_idx = current_indices[current_indices.length - 1]
    current_indices.push(current_idx + 1);

    this.setState({
      questions_list : current_questions_list,
      indices : current_indices
    });
    this.props.set_questions(current_questions_list);
  }

  renderUniversalQuestionComponent(index) {
    return (
      <View style={{ width: window.window.width / 1.7, marginTop: 20, marginBottom: 20 }}>
        <View style={{flexDirection: "row", justifyContent: "space-between"}}>
          <Text style={{fontSize: 18}}>Question {index + 1}</Text>
          <TouchableOpacity onPress={() => {this.removeQuestion(index)}}>
            <FontAwesome name="trash-o" size={24} color={Colors.ppGreyText} />
          </TouchableOpacity>
        </View>
        <TextInput
          style={{color: Colors.ppGreyText,
            textDecorationLine: 'underline',
            fontSize: 15, marginTop: 10, borderWidth: 1,
            borderRadius: 5, borderColor: Colors.ppGreyText}}
            onChangeText={text => {
              let current_questions_list = this.state.questions_list;
              current_questions_list[index] = text;
              this.setState({ questions_list : current_questions_list });
              this.props.set_questions(current_questions_list);
            }}
          value={this.state.questions_list[index]}
          onSubmitEditing={() => null}
          placeholder={"Insert question here..."}
          multiline={false}
          maxLength={200}
        />
      </View>
    )
  }

  render() {
    return (
      <View style={{flexDirection: 'column'}}>
        <TouchableOpacity style={styles.addAnotherQuestionContainer}
          onPress={() => {this.addQuestion()}}>
                <Text style={{color: "grey", fontWeight: "bold", textAlign: "center"}}>ADD ANOTHER QUESTION</Text>
        </TouchableOpacity>
        <View style={styles.container}>
          <ScrollView>
            <FlatList
              data={this.state.indices}
              scrollEnabled={true}
              keyExtractor={(item) => item}
              extraData={this.state.indices}
              contentContainerStyle={{ marginHorizontal: 20,
                height: window.window.height / 4 }}
              renderItem={({ item }) => (

                this.renderUniversalQuestionComponent(this.state.indices[item])
              )}
              />
          </ScrollView>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fafafa",
    marginTop: 30,
    borderRadius: 30,
    marginBottom: 25,
    backgroundColor: "white",
    width: window.window.width / 1.55,
    height: window.window.height / 1.5,
  },
  addAnotherQuestionContainer: {
    backgroundColor:"white",
    borderRadius: 60,
    width: window.window.width / 8,
    height: window.window.height / 25,
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'flex-end',
    justifyContent: 'center'
  },
});
