import * as React from "react";
import { StyleSheet, Text, View, Platform, TouchableOpacity,
  ScrollView } from "react-native";
import { Colors } from "../global/Constants";
import { getCourse, updateCourse,
  deleteCourse } from "../custom-modules/backendAPI";
import window from "../global/Layout";
import SideMenu from "../components/SideMenu";
import MainButton from "../components/MainButton";
import TextBox from "../components/TextBox";
import Logo from "../components/Logo";
import { Picker } from "@react-native-picker/picker";
import SeparatingLine from "../components/SeparatingLine";
import BackButton from "../components/BackButton";

const COURSE_NAME_LENGTH_MIN = 5;
const COURSE_NUM_LENGTH_MIN = 2;
const confirm_delete_message = "Are you sure you want to delete the course?"

export default function EditDeleteCourseScreen({ route, navigation }) {
  const [courseName, setCourseName] = React.useState(route.params.course.title);
  const [courseNumber, setCourseNumber] = React.useState(route.params.course.code);
  const [semester, setSemester] = React.useState(route.params.course.semester);
  const [errors, setErrors] = React.useState([]);

  function set_semester(value, index) {
    index == 0 ? setSemester("") : setSemester(value);
  }

  function addError(newError) {
    let current_errors = errors;
    current_errors.push(newError);
    setErrors(current_errors);
  }

  // Verify that the new course is valid
  async function verifyCourse() {
    try {
      let newErrors = errors;
      while (newErrors.length > 0) newErrors.pop();
      setErrors(newErrors);

      if (courseName == "") {
        addError(" Course name must not be empty");
      } else if (courseName.length < COURSE_NAME_LENGTH_MIN) {
        addError(" Course name must be 5 characters or more");
      }
      if (courseNumber == "") {
        addError(" Course number must not be empty");
      } else if (courseNumber.length < COURSE_NUM_LENGTH_MIN) {
        addError(" Course number must be 2 characters or more");
      }
      if (semester == "") {
        addError(" Course semester must not be empty");
      }

      // Presenting the errors or adding the course successfully
      if (errors.length == 0) {
        edit_course();
      } else {
        if (Platform.OS == "web") {
          alert(errors);
        } else {
          Alert.alert("Error", errors, [{ text: "OK" }]);
        }
      }
    } catch (E) {
      console.log(E);
    }
  }

  // Add a new course to the database
  function edit_course() {
    getCourse(route.params.course._id).then(_result => {
      _result.title = courseName;
      _result.code = courseNumber;
      _result.semester = semester;
      
      try {
        updateCourse(route.params.course._id, _result).then(_updated_result => {
          if (_updated_result) {
            console.log("Course was edited successfully!");
            navigation.navigate("Course", {
              course: _updated_result,
            })
          }
        });
      } catch (E) {
        console.log(E);
      }
    })
  }

  // Deleting a course
  function delete_course_dialog() {
    if (Platform.OS == 'web') {
      var response = confirm(confirm_delete_message);
      if (response) {
        delete_course();
      }
    } else {
      Alert.alert(
        "Delete course",
        confirm_delete_message,
        [ { text: "Yes", onPress: () => {
          delete_course();
        } },
          { text: "No" }
      ] )
    }
  }

  // Delete course in the database
  function delete_course() {
    try {
      deleteCourse(route.params.course._id).then(_res => {
        if (_res) {
          console.log("Course was deleted successfully!");
          navigation.navigate('Courses');
        }
      })
    } catch (E) {
      console.log(E);
    }
  }

  return (
    <View style={styles.screenContainer}>
      <View style={{ flexDirection: "row", flex: 1 }}>
        <SideMenu navigation={navigation} />
        <View style={styles.logoAndScrollContainer}>
          <Logo />
          <View style={styles.upperButtonContainer} />
          <BackButton backTo={"Course"} navigation={navigation} />
          <View style={{marginStart: window.window.width / 6}} >
            <SeparatingLine />
          </View>
          <ScrollView style={styles.scrollViewContainer}>
            <Text style={styles.title}>Edit course</Text>

            <TextBox
              fieldTitle={"Course name"}
              onChangeText={(text) => setCourseName(text)}
              onSubmitEditing={() => verifyCourse()}
              value={courseName}
              secureTextEntry={false}
              placeholder={"Course Name"}
              maxLength={35}
              mandatory={true}
            />
            <TextBox
              fieldTitle={"Course number"}
              onChangeText={(text) => setCourseNumber(text)}
              onSubmitEditing={() => verifyCourse()}
              value={courseNumber}
              secureTextEntry={false}
              placeholder={"Course Number"}
              maxLength={15}
              mandatory={true}
            />
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.fieldTitle}>Semester</Text>
              <Text style={{ color: "red", fontSize: 30 }}>*</Text>
            </View>
            <View style={styles.dropdownMenuContainer}>
              <Picker
                selectedValue={semester}
                onValueChange={(value, index) => set_semester(value, index)}
                style={styles.picker}
                placeholder={{ label: "Semester" }}
              >
                <Picker.Item label="Fall 2021" value="fall_2021" />
                <Picker.Item label="Spring 2022" value="spring_2022" />
              </Picker>
            </View>
            <View style={styles.upperButtonContainer}>
              <TouchableOpacity style={styles.deleteCourseContainer}
                  onPress={delete_course_dialog}>
                  <Text style={styles.deleteCourseText}>Delete Course</Text>
              </TouchableOpacity>
              <MainButton
                small={true}
                active={true}
                text={"Save"}
                square={true}
                onPress={() => verifyCourse()}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  logoAndScrollContainer: {
    justifyContent: 'center',
  },
  scrollViewContainer: {
    width: window.window.width / 1.5,
    height: window.window.height,
    marginStart: window.window.width / 6,
    backgroundColor: Colors.backgroundColor,
  },
  upperButtonContainer: {
    alignSelf: "flex-end",
    flexDirection: 'row',
    marginBottom: window.window.height / 20,
  },
  picker: {
    borderWidth: 0,
    borderRadius: 10,
    fontSize: window.window.height / 50,
    width: window.window.width / 5,
    height: window.window.height / 20,
  },
  screenContainer: {
    flex: 1,
    flexDirection: "column",
  },
  title: {
    fontSize: window.window.height / 30,
    marginTop: window.window.height / 30,
    marginBottom: 30,
    fontWeight: "bold",
  },
  fieldTitle: {
    color: Colors.ppTextColor,
    fontWeight: "bold",
    fontSize: 30,
    marginBottom: 10,
  },
  deleteCourseContainer: {
    marginTop: 20,
    width: window.window.width / 8,
    height: window.window.height / 16,
    borderColor: Colors.ppRed,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 5,
    marginEnd: window.window.width / 40,
    backgroundColor: 'white',
  },
  deleteCourseText: {
    color: Colors.ppRed,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: window.window.height / 50
  },
});
