import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Text } from 'react-native';
import { Colors } from '../global/Constants';
import window from '../global/Layout.js';
import { getQuestion, getResponse } from '../custom-modules/backendAPI';
import CommentViewAdmin from './CommentViewAdmin';

export default class QuestionLiveComments extends Component {
  
  constructor(props) {

    super(props);

    this.state = {
      question_index: this.props.question_index,
      question: {},
      changeFlag: false,
      response_list: [],
    }
  }

  // Will have to add her pulling the updated comments actively - web sockets?

  componentDidMount() {
    this.loadData();
    this.setState({
      changeFlag: !this.state.changeFlag,
    })
  }

  loadData() {
    // fetch question and responses data
    getQuestion(this.props.question_id, true).then(_question => {
      if (_question) { // if non-empty result
        this.setState({ question: _question });
        _question.response_list.forEach(_response_id => {
          let new_response_list = this.state.response_list;
          getResponse(_response_id, true).then(_response => {
            if (_response) { // if non-empty result
              new_response_list.push(_response);
              this.setState({
                response_list : new_response_list,
                changeFlag : !this.state.changeFlag
              })
            }
          })
        });
      }
    }).catch(_err => {
      console.error(`[QuestionLiveComments] ${_err}`)
    })
  }

  render() {
    return (
      <View>
        <View style={styles.questionTitle}>
          <Text style={{fontSize: 30, fontWeight: 'bold', marginEnd: 15}}>Q{this.state.question_index}</Text>
          <Text style={{fontSize: 15, fontWeight: 'bold'}}>{this.state.question.text}</Text>
        </View>
        <View style={{borderWidth: 0.5, borderColor: Colors.backgroundColor}} />
        <FlatList
          data={this.state.response_list}
          extraData={this.state.changeFlag}
          keyExtractor={item => item._id}
          scrollEnabled={true}
          style={{height: window.window.height / 2}}
          renderItem={({ item }) => (
            <CommentViewAdmin extended={false} response={item} profile_pic={item.listener_id.profile_pic}/>
          )}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  questionTitle: {
    flexDirection: 'row',
    alignContent: 'space-between',
    marginStart: window.window.width / 40,
    marginEnd: window.window.width / 40,
    marginTop: window.window.height / 30,
    marginBottom: window.window.height / 30,
  }
});
