import * as React from 'react';
import QuestionsCommentsLists from '../components/QuestionsCommentsLists';

export default function QuestionScreen({route, navigation}) {


  React.useEffect(() => {
  }, [navigation])

  
  return (
    <QuestionsCommentsLists question_list={route.params.question_list}
      navigation={navigation} presentation_id={route.params.presentation_id}
      updateData={route.params.updateData} session_id={route.params.session_id} />
  );
}