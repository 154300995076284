import React, {Component} from 'react';
import {Text, View, StyleSheet, TouchableOpacity} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import window from '../global/Layout.js'
import { Colors } from '../global/Constants';
import UserIcon from './UserIcon';
import { getUser } from "../custom-modules/backendAPI";

export default class SearchItem extends Component {

  state = {
    added: this.props.hasItem,
    profile_pic: null,
  }

  componentDidMount() {
    // If a list of members - find and save the profile picture to show in the search
    if (this.props.type == "members") {
      // fetch raw data & update
      getUser(this.props.data._id, true).then((_user) => {
        if (_user) {
          // if non-empty result
          this.setState({ profile_pic : _user.profile_pic });
        }
      });
    }
  }

  // Change icon of item (plus button or checkmark)
  onPress = () => {
    this.setState({ added : !this.state.added })
  }

  render() {
    return (
      <View>
        <View style={styles.container}>
          <TouchableOpacity onPress={() => {
              this.props.onPress(); // Change in backend
              this.onPress(); // Change added state - local icon
            }}>
            <View style={styles.searchItem}>
              {this.props.memberList &&
                <UserIcon userIconDim={window.window.height / 20}
                  profile_pic={this.state.profile_pic} />
              }
              <Text style={styles.text} numberOfLines={1}>
                {this.props.data.text}
              </Text>
              {!this.state.added
                ?
                  // Item was not added
                  <Ionicons name="ios-add" size={48} color={Colors.ppTextColor}/>
                :
                  // Item was added
                  <Ionicons name="ios-checkmark-circle-outline"
                            size={47} color={Colors.ppTextColor}/>
              }
            </View>
          </TouchableOpacity>
        </View>
        {/* <SeparatingLine/> */}
      </View>
    )
  }
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fafafa',
    height: window.window.width / 6,
  },
  searchItem: {
    overflow: 'hidden',
    alignItems: 'center',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    marginEnd: window.window.width / 50,
    marginTop: window.window.height / 50,
  },
  text: {
    flex: 1,
    fontSize: 24,
    marginStart: 20,
    overflow: 'hidden',
    fontWeight: 'bold',
    color: Colors.ppTextColor,
  },
});