import * as React from "react";
import { StyleSheet, Text, View, Platform, ScrollView } from "react-native";
import { createQuestion, updatePresentation } from "../custom-modules/backendAPI";
import { Colors } from "../global/Constants";
import window from "../global/Layout";
import SideMenu from "../components/SideMenu";
import MainButton from "../components/MainButton";
import TextBox from "../components/TextBox";
import Logo from '../components/Logo';
import SeparatingLine from "../components/SeparatingLine";
import BackButton from "../components/BackButton";

const QUESTION_TEXT_LENGTH_MIN = 1;
const QUESTION_TEXT_LENGTH_MAX = 500;

export default function AddQuestionScreen({ navigation, route }) {
  const [presentation, setPresentation] = React.useState(route.params.presentation);
  const [questionText, setQuestionText] = React.useState("");
  const [errors, setErrors] = React.useState([]);

  function addError(newError) {
    let current_errors = errors;
    current_errors.push(newError);
    setErrors(current_errors);
  }

  // Verify that the new course is valid
  async function verifyQuestion() {
    try {
      let newErrors = errors;
      while (newErrors.length > 0) newErrors.pop();
      setErrors(newErrors);

      if (questionText == "") {
        addError(" Course name must not be empty");
      } else if (questionText.length < QUESTION_TEXT_LENGTH_MIN) {
        addError(" Course name must be 1 characters or more");
      } else if (questionText.length > QUESTION_TEXT_LENGTH_MAX) {
        addError(" Course name must not exceed 500 characters");
      }

      // Presenting the errors or adding the course successfully
      if (errors.length == 0) {
        addQuestionToPresentation();
      } else {
        if (Platform.OS == "web") {
          alert(errors);
        } else {
          Alert.alert("Error", errors, [{ text: "OK" }]);
        }
      }
    } catch (E) {
      console.log(E);
    }
  }

  function addQuestionToPresentation() {
    let newQuestion = {
        _id: "0",
        presentation_id: presentation._id,
        presenter_id: sessionStorage.getItem('UserId'),
        text: questionText,
        response_list: [],
    }
    createQuestion(newQuestion).then((res) => {
      // update Presentation's question list
      if (res) {
        let _list = presentation.question_list;
        _list.push(res);
        updatePresentation(presentation._id, {
          question_list: _list,
        }).then((res) => {
          setQuestionText("");
          route.params.onGoBack();
          navigation.goBack();
        })
        .catch((err) => {
          console.error(
            `Failed to update presentation after create question. ${err}`
          );
        });
      }
    })
    .catch((err) => {
      // TODO alert user question create failed
      console.error(`Failed to create question. ${err}`);
    });
  }


  return (
    <View style={styles.screenContainer}>
      <View style={{ flexDirection: "row", flex: 1 }}>
        <SideMenu navigation={navigation} />
        <View style={styles.logoAndScrollContainer}>
          <Logo />
          <View style={styles.upperButtonContainer} />
          <BackButton backTo={"Presentation"} navigation={navigation} />
          <View style={{marginStart: window.window.width / 6}} >
            <SeparatingLine />
          </View>
          <ScrollView style={styles.scrollViewContainer}>
            <Text style={styles.title}>Add question</Text>

            <TextBox
              fieldTitle={"Question text"}
              long={true}
              onChangeText={(text) => setQuestionText(text)}
              onSubmitEditing={() => verifyQuestion()}
              value={questionText}
              secureTextEntry={false}
              placeholder={"Question Text"}
              maxLength={QUESTION_TEXT_LENGTH_MAX}
              mandatory={true}
            />
            <View style={styles.upperButtonContainer}>
              <MainButton
                small={true}
                active={true}
                text={"Save"}
                square={true}
                onPress={() => verifyQuestion()}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  logoAndScrollContainer: {
    justifyContent: 'center',
  },
  scrollViewContainer: {
    width: window.window.width / 1.5,
    height: window.window.height,
    marginStart: window.window.width / 6,
    backgroundColor: Colors.backgroundColor,
  },
  upperButtonContainer: {
    alignSelf: "flex-end",
    marginBottom: window.window.height / 20,
  },
  screenContainer: {
    flex: 1,
    flexDirection: "column",
  },
  title: {
    fontSize: window.window.height / 30,
    marginTop: window.window.height / 30,
    marginBottom: 30,
    fontWeight: "bold",
  },
  fieldTitle: {
    color: Colors.ppTextColor,
    fontWeight: 'bold',
    fontSize: window.window.height / 40,
    marginBottom: 10,
  },
});
