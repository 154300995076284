import * as React from "react";
import { StyleSheet, Text, View, Platform, ScrollView,
TouchableOpacity } from "react-native";
import { Colors } from "../global/Constants";
import { createSession, getCourse, getSession, updateSession,
  updateCourse, deleteSession, getAllDatasetIDs, getDatasetList,
  updateDataset} from "../custom-modules/backendAPI";
import window from "../global/Layout";
import SideMenu from "../components/SideMenu";
import MainButton from "../components/MainButton";
import TextBox from "../components/TextBox";
import { Picker } from "@react-native-picker/picker";
import SeparatingLine from "../components/SeparatingLine";
import Search from "../components/Search";
import BackButton from "../components/BackButton";
import Logo from "../components/Logo";
import TagsList from "../components/TagsList";
import DatePicker from "react-datepicker";
import UniversalQuestions from "../components/UniversalQuestions";
import { CheckBox } from 'react-native-elements';
import "react-datepicker/dist/react-datepicker.css";

const SESSION_NAME_LENGTH_MIN = 5;
const SESSION_NAME_LENGTH_MAX = 30;
const confirm_delete_message =
  "Are you sure you want to delete this session and all its presentations? This cannot be undone";

export default function CreateEditSessionScreen({ navigation, route }) {

  const [sessionName, setSessionName] = React.useState(route.params.create ? "" : route.params.session.title);
  const [endDate, setEndDate] = React.useState(route.params.create ? new Date() : new Date(route.params.session.start_datetime));
  const [sessionForSettings, setSessionForSettings] = React.useState([]);
  
  const [errors, setErrors] = React.useState([]);
  
  const [universalQuestionsBool, setUniversalQuestionsBool] = React.useState(route.params.create ? false : route.params.session.universal_questions_bool);
  const [universalQuestions, setUniversalQuestions] = React.useState(route.params.create ? [""] : route.params.session.universal_questions);
  const [presenterQuestionsBool, setPresenterQuestionsBool] = React.useState(route.params.create ? false : route.params.session.presenter_questions_bool);
  const [presenterQuestionsNum, setPresenterQuestionsNum] = React.useState(route.params.create ? 0 : route.params.session.presenter_questions_num);

  const [emoji, setEmoji] = React.useState(route.params.create ? true : route.params.session.emojis_feedback);
  const [starRating, setStarRating] = React.useState(route.params.create ? true : route.params.session.star_rating_feedback);
  const [tags, setTags] = React.useState(route.params.create ? true : route.params.session.tags_feedback);
  const [tagsArray, setTagsArray] = React.useState(route.params.create ?
    {"DoIt": true, "ConsiderIt": true, "DropIt": true} :
    (route.params.session.tags ? route.params.session.tags : {"DoIt": true, "ConsiderIt": true, "DropIt": true}));

  const [photo, setPhoto] = React.useState(route.params.create ? false : route.params.session.photo_media);
  const [qrCodeScan, setQrCodeScan] = React.useState(route.params.create ? false : route.params.session.qr_code_scan);
  const [shareContactInfo, setShareContactInfo] = React.useState(route.params.create ? false : route.params.session.share_contact_info);

  function addError(newError) {
    let current_errors = errors;
    current_errors.push(newError);
    setErrors(current_errors);
  }

  // Verify that the new course is valid
  async function verifySession() {

    // TODO: BASE ON RED STAR IN FIELDS!
    try {
      let newErrors = errors;
      while (newErrors.length > 0) newErrors.pop();
      setErrors(newErrors);

      if (sessionName == "") {
        addError(" Session name must not be empty");
      } else if (sessionName.length < SESSION_NAME_LENGTH_MIN
                  || sessionName.length > SESSION_NAME_LENGTH_MAX) {
        addError(" Session name must be between 5 and 30 characters");
      }
      if (!universalQuestionsBool && !presenterQuestionsBool) {
        addError(" At least one form of guiding questions must be selected");
      }
      if (universalQuestionsBool && (universalQuestions.length < 1)) {
        addError(" If selected, number of universal questions must be at least 1");
      }
      if (universalQuestionsBool && (universalQuestions.length > 0) && hasEmptyUniversalQuestion()) {
        addError(" Universal questions may not be blank");
      }
      if (presenterQuestionsBool && (presenterQuestionsNum < 1)) {
        addError(" If selected, number of presenter questions must be at least 1");
      }
      if (!emoji && !starRating && !tags) {
        addError(" At least one form of reaction to feedback must be selected");
      }

      // Presenting the errors or adding the course successfully
      if (errors.length == 0) {
        if (route.params.create) {
          add_session();
        } else {
          edit_session();
        }
      } else {
        if (Platform.OS == "web") {
          alert(errors);
        } else {
          Alert.alert("Error", errors, [{ text: "OK" }]);
        }
      }
    } catch (E) {
      console.log(E);
    }
  }

  function hasEmptyUniversalQuestion() {
    for(var i = 0; i < universalQuestions.length ; i++){
      if(universalQuestions[i] === "")   
         return true;
    }
    return false;
  }

  // Delete session dialog
  function delete_session_dialog() {
    if (Platform.OS == 'web') {
      var response = confirm(confirm_delete_message);
      if (response) {
        delete_session();
      }
    } else {
      Alert.alert(
        "Delete session",
        confirm_delete_message,
        [ { text: "Yes", onPress: () => {
          delete_session();
        } },
          { text: "No" }
      ])
    }
  }

  // Delete session in the database
  function delete_session() {
    try {
    // delete session
      deleteSession(route.params.session._id).then(res => {
        // update Course's session list
        if (res){
          getCourse(route.params.course_id, false).then(_course => {
            // get a copy session_list with item._id removed
            let _list = _course.session_list.filter(val =>
              { return val != route.params.session._id });
            updateCourse(route.params.course_id, {
              session_list: _list
            }).then(res => {
              getAllDatasetIDs().then(_dataset_list => {
                if (_dataset_list) {
                  getDatasetList(_dataset_list).then(_datasets => {
                    console.log(_datasets);
                    _datasets.forEach(dataset => {
                      console.log(dataset);
                      if (dataset.session_list.includes(route.params.session._id)) {
                        let new_session_list = dataset.session_list;
                        // Find the session index and remove it
                        console.log(dataset.session_list);
                        let session_index = dataset.session_list.indexOf(route.params.session._id);
                        if (session_index > -1) {
                          new_session_list.splice(session_index, 1);
                        }
                        console.log(new_session_list);
                        updateDataset(dataset._id, { session_list : new_session_list })
                                        .then(update_dataset_res => {})
                      }
                    })
                  })
                }
              })
              route.params.onGoBack();
              location.reload();
            }).catch(err => {
                console.error(`Failed to update course after delete session. ${err}`)
              })
          })
        }
      })
    } catch (E) {
      console.error(`Failed to delete presentation. ${err}`);
    }
  }

  function update_screen_according_to_session(session_id) {
    getSession(session_id).then(_session => {
      if (_session) {
        setEndDate(new Date(_session.end_datetime));
        setUniversalQuestionsBool(_session.universal_questions_bool);
        setUniversalQuestions(_session.universal_questions);
        setPresenterQuestionsBool(_session.presenter_questions_bool);
        setPresenterQuestionsNum(_session.presenter_questions_num);
        setEmoji(_session.emojis_feedback);
        setStarRating(_session.star_rating_feedback);
        setTags(_session.tags_feedback);
        setTagsArray(_session.tags);

        setPhoto(_session.photo_media);
        setQrCodeScan(_session.qr_code_scan);
        setShareContactInfo(_session.share_contact_info);
      }
    })
  }

  // Edit a session in the database
  function edit_session() {
    let updatedSessionObj = {
      title: sessionName,
      start_datetime: endDate,
      end_datetime: endDate,
      universal_questions_bool: universalQuestionsBool,
      universal_questions: universalQuestions,
      presenter_questions_bool: presenterQuestionsBool,
      presenter_questions_num: presenterQuestionsNum,
      emojis_feedback: emoji,
      star_rating_feedback: starRating,
      tags_feedback: tags,
      tags: tagsArray,
      photo_media: photo,
      qr_code_scan: qrCodeScan,
      share_contact_info: shareContactInfo,
    };
    try {
      updateSession(route.params.session._id, updatedSessionObj).then( res => {
        if (res) {
          console.log("Session was updated successfully!");
          // go back to previous screen
          route.params.onGoBack();
          navigation.goBack();
        }
      }).catch( err => {
        // alert user that update session failed
        console.error(`[EditSession] update session failed. ${err}`)
      })
    } catch (E) {
      console.log(E);
    }
  }

  // Add a new session to the database
  function add_session() {
    let newSessionObj = {
      _id: "0",
      course_id: route.params.course_id,
      title: sessionName,
      presentation_list: [],
      responses_num: 0,
      start_datetime: endDate,
      end_datetime: endDate,
      universal_questions_bool: universalQuestionsBool,
      universal_questions: universalQuestions,
      presenter_questions_bool: presenterQuestionsBool,
      presenter_questions_num: presenterQuestionsNum,
      emojis_feedback: emoji,
      star_rating_feedback: starRating,
      tags_feedback: tags,
      tags: tagsArray,
      photo_media: photo,
      qr_code_scan: qrCodeScan,
      share_contact_info: shareContactInfo,
    };
    try {
      createSession(newSessionObj).then(sess_id => {
        if (sess_id) {
          getCourse(route.params.course_id, false).then(_course => {
            if (_course) {
              let curr_sess_list = _course.session_list;
              curr_sess_list.push(sess_id);
              updateCourse(route.params.course_id, {
                session_list : curr_sess_list
              })
            }
          })
          console.log("Session was created successfully!");
          // go back to previous screen
          route.params.onGoBack();
          navigation.goBack();
          location.reload();
        }
      }).catch( err => {
        // alert user that create session failed
        console.error(`[CreateSession] create session failed. ${err}`)
      })
    } catch (E) {
      console.log(E);
    }
  }

  return (
    <View style={styles.screenContainer}>
      <View style={{ flexDirection: "row", flex: 1 }}>
        <SideMenu navigation={navigation} />
        <View style={styles.logoAndScrollContainer}>
          <Logo />
          <View style={styles.upperButtonContainer} />
          <BackButton backTo={"Course"} navigation={navigation} />
          <View style={{marginStart: window.window.width / 6}} >
            <SeparatingLine />
          </View>
          <ScrollView style={styles.scrollViewContainer}>
            <Text style={styles.title}>Create new session</Text>

            {/* Session name */}
            <TextBox
              fieldTitle={"Session name"}
              onChangeText={(text) => setSessionName(text)}
              onSubmitEditing={() => verifySession()}
              value={sessionName}
              secureTextEntry={false}
              placeholder={"Session Name"}
              maxLength={35}
              mandatory={true}
            />
            {/* Session end date */}
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.fieldTitle}>Session end date</Text>
              <Text style={{ color: "red", fontSize: 30, marginTop: 20 }}>*</Text>
            </View>
            <DatePicker
              selected={endDate}
              style={{width: window.window.width / 5}}
              onSelect={date => setEndDate(date)}
            />

            {/* Select from previous settings */}
            <Text style={styles.fieldTitle}>Select from previous settings</Text>
            <View style={styles.addSessions}>
              <Search type={"sessions"} route={route} limit_to_one={true} change_id_list={setSessionForSettings} update_settings={update_screen_according_to_session}/>
            </View>

            {/* Guiding questions */}
            <View style={{ flexDirection: "row", marginTop: window.window.height / 9 }}>
              <Text style={styles.fieldTitle}>Guiding Questions</Text>
              <Text style={{ color: "red", fontSize: 30, marginTop: 20 }}>*</Text>
            </View>
            <View style={{marginStart: 20}}>
              <View style={{flexDirection: "row", justifyContent: 'space-between'}}>
                <View>
                  <CheckBox
                    title={"Universal Questions"}
                    checked={universalQuestionsBool}
                    containerStyle={{
                      backgroundColor: Colors.backgroundColor,
                      borderColor: Colors.backgroundColor,
                    }}
                    onPress={() => { setUniversalQuestionsBool(!universalQuestionsBool) }}
                  />
                  <Text style={styles.helperText}>Every presentation team will see these questions</Text>
                </View>
              </View>
              { universalQuestionsBool &&
                <UniversalQuestions initial_questions={universalQuestions} set_questions={setUniversalQuestions} />
              }
              <View>
                <CheckBox
                  title={"Presenter Questions"}
                  checked={presenterQuestionsBool}
                  containerStyle={{
                    backgroundColor: Colors.backgroundColor,
                    borderColor: Colors.backgroundColor,
                  }}
                  onPress={() => { setPresenterQuestionsBool(!presenterQuestionsBool) }}
                />
                <Text style={styles.helperText}>Each presentation team creates their own questions</Text>
              </View>
              { presenterQuestionsBool &&
              <Picker
                selectedValue={presenterQuestionsNum}
                onValueChange={(value, index) => setPresenterQuestionsNum(parseInt(value))}
                style={styles.picker}
                enabled={presenterQuestionsBool}
                placeholder={{ label: "NUMBER OF QUESTIONS" }}
              >
                <Picker.Item label="NUMBER OF QUESTIONS" value={0} />
                <Picker.Item label="1" value={1} />
                <Picker.Item label="2" value={2} />
                <Picker.Item label="3" value={3} />
                <Picker.Item label="4" value={4} />
                <Picker.Item label="5" value={5} />
              </Picker>
              }
            </View>

            {/* Reaction to Feedback */}
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.fieldTitle}>Reaction to Feedback</Text>
              <Text style={{ color: "red", fontSize: 30, marginTop: 20 }}>*</Text>
            </View>
            <View style={{marginStart: 20}}>
              <View>
                <CheckBox
                  title={"Emoji"}
                  checked={emoji}
                  containerStyle={{
                    backgroundColor: Colors.backgroundColor,
                    borderColor: Colors.backgroundColor,
                  }}
                  onPress={() => { setEmoji(!emoji) }}
                />
                <Text style={styles.helperText}>Enable presenters to react to feedback with emojis</Text>
              </View>
              <View>
                <CheckBox
                  title={"Star Rating"}
                  checked={starRating}
                  containerStyle={{
                    backgroundColor: Colors.backgroundColor,
                    borderColor: Colors.backgroundColor,
                  }}
                  onPress={() => { setStarRating(!starRating) }}
                />
                <Text style={styles.helperText}>Enable presenters to react to feedback with star ratings</Text>
              </View>
              <View>
                <CheckBox
                  title={"Tags"}
                  checked={tags}
                  containerStyle={{
                    backgroundColor: Colors.backgroundColor,
                    borderColor: Colors.backgroundColor,
                  }}
                  onPress={() => { setTags(!tags) }}
                />
                <Text style={styles.helperText}>Enable presenters to react to feedback with tags</Text>
              </View>
              { tags &&
                <TagsList data={tagsArray} changeData={setTagsArray}/>
              }
            </View>

            {/* Upload Media */}
            <Text style={styles.fieldTitle}>Upload Media</Text>
            <View style={{marginStart: 20}}>
              <View>
                <CheckBox
                  title={"Photo"}
                  checked={photo}
                  containerStyle={{
                    backgroundColor: Colors.backgroundColor,
                    borderColor: Colors.backgroundColor,
                  }}
                  onPress={() => { setPhoto(!photo) }}
                />
                <Text style={styles.helperText}>Enable presenters to upload photos</Text>
              </View>
            </View>

            {/* Social */}
            <Text style={styles.fieldTitle}>Social</Text>
            <View style={{marginStart: 20}}>
                <View>
                  <CheckBox
                    title={"QR Code Scan"}
                    checked={qrCodeScan}
                    containerStyle={{
                      backgroundColor: Colors.backgroundColor,
                      borderColor: Colors.backgroundColor,
                    }}
                    onPress={() => { setQrCodeScan(!qrCodeScan) }}
                  />
                  <Text style={styles.helperText}>Generate a QR code for each presenting team</Text>
                </View>
                <View>
                  <CheckBox
                    title={"Share Contact Information"}
                    checked={shareContactInfo}
                    containerStyle={{
                      backgroundColor: Colors.backgroundColor,
                      borderColor: Colors.backgroundColor,
                    }}
                    onPress={() => { setShareContactInfo(!shareContactInfo) }}
                  />
                  <Text style={styles.helperText}>Enable presenters to share their contact information</Text>
                </View>
            </View>

            <View style={styles.upperButtonContainer}>
              { !route.params.create &&
                <View style={{marginEnd: window.window.width / 40}}>
                  <TouchableOpacity style={styles.deleteButtonContainer}
                    onPress={delete_session_dialog}>
                    <Text style={styles.deleteAccountText}>Delete</Text>
                  </TouchableOpacity>
                </View>
              }
              <MainButton
                small={false}
                active={true}
                text={route.params.create ? "Create" : "Update"}
                square={true}
                onPress={() => verifySession()}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  logoAndScrollContainer: {
    justifyContent: 'center',
  },
  scrollViewContainer: {
    width: window.window.width / 1.5,
    height: window.window.height,
    marginStart: window.window.width / 6,
    backgroundColor: Colors.backgroundColor,
  },
  upperButtonContainer: {
    alignSelf: "flex-end",
    marginBottom: window.window.height / 20,
    flexDirection: 'row',
  },
  deleteButtonContainer: {
    marginTop: window.window.height / 50,
    width: window.window.width / 12,
    height: window.window.height / 17,
    borderColor: Colors.ppRed,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 10,
    backgroundColor: 'white',
  },
  deleteAccountText: {
    color: Colors.ppRed,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: window.window.height / 50
  },
  picker: {
    borderWidth: 0,
    borderRadius: 10,
    marginTop: 20,
    marginStart: 20,
    fontSize: window.window.height / 50,
    width: window.window.width / 6,
    height: window.window.height / 25,
  },
  addSessions: {
    marginTop: 30,
    borderRadius: 30,
    marginBottom: window.window.height / 7,
    backgroundColor: "white",
    width: window.window.width / 1.5,
    height: window.window.height / 3.5,
  },
  screenContainer: {
    flex: 1,
    flexDirection: "column",
  },
  title: {
    fontSize: window.window.height / 30,
    marginTop: window.window.height / 30,
    marginBottom: 30,
    fontWeight: "bold",
  },
  fieldTitle: {
    color: Colors.ppTextColor,
    fontWeight: "bold",
    fontSize: window.window.height / 35,
    marginBottom: 10,
    marginTop: 20,
  },
  helperText: {
    color: Colors.ppGreyText,
  },
});
