import React, { Component } from 'react';
import { TouchableOpacity, StyleSheet, View, Text } from 'react-native';
import { Colors } from '../global/Constants';
import window from '../global/Layout.js'

export default class Tag extends Component {
  
  constructor(props : Props) {
    super(props);

    this.state = {
      tag_active : !props.active ? Colors.ppLightGrey : null,
    }
  }

  // Clicking on a tag - has various options
  tagClick() {
    if (this.props.addTag){
      this.props.addTagFunc();
    } else if (!this.props.active) {
      this.props.addGreyedOutTag(this.props.name.substring(1))
    }
  }


  render() {
    return (
        <TouchableOpacity onPress={() => this.tagClick()}>
          <View style={[styles.tagStyle, {backgroundColor: this.state.tag_active}]}>
            <Text style={{ color: Colors.ppMainPurple, fontWeight: 'bold', marginStart: 10, marginEnd: 10 }}>{this.props.name}</Text>
          </View>
        </TouchableOpacity>
      )
  }
}

const styles = StyleSheet.create({
  tagStyle: {
    borderWidth: 3,
    borderRadius: 20,
    borderColor: Colors.ppMainPurple,
    justifyContent: 'space-evenly',
    height: window.window.height / 30,
    marginRight: window.window.width / 120,
  },  
});
