import React, { Component } from 'react';
import { StyleSheet, View, TouchableOpacity, Text,
TextInput, Platform } from 'react-native';
import { Colors } from '../global/Constants';
import { Feather, MaterialCommunityIcons } from '@expo/vector-icons'
import window from '../global/Layout';
import CancelSaveDeletePanel from './CancelSaveDeletePanel';
import { updatePresentation, deletePresentation, getSession,
  updateSession, getUser, updateUser } from '../custom-modules/backendAPI';

const confirm_delete_message =
  "Are you sure you want to delete this presentation and all its questions? This cannot be undone";

export default class PresentationItem extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      editPresentation: false,
      presentationText: this.props.presentation.title,
      presentationTitle: this.props.presentation.title,
      presentation: this.props.presentation,
      session_title: "",

      errors: [],
    };
  }

  componentDidMount() {
    getSession(this.state.presentation.session_id).then(_sess => {
      if (_sess) {
        this.setState({ session_title : _sess.title });
      }
    })
  }

  addError(newError) {
    let current_errors = this.state.errors;
    current_errors.push(newError)
    this.setState({ errors : current_errors });
  }
  
  // Verify that the presentation is valid
  async verifyPresentation() {
    try {

      // Clearing the array of errors
      let newErrors = this.state.errors;
      while(newErrors.length > 0) newErrors.pop();
      this.setState({ errors : newErrors});

      // text length invalid
      if (this.state.presentationText.length < 5 || this.state.presentationText.length > 30) {
        addError(" Presentation title must be between 5 and 30 characters")
      }
      
      if (this.state.errors.length == 0) {
        this.edit_presentation();
      } else {
        if (Platform.OS == 'web') {
          alert(this.state.errors)
        } else {
          Alert.alert(
            "Error",
            this.state.errors,
            [ { text: "OK" } ] )
        }
      }
    } catch (E) {
      console.log(E);
    }
  }

  // Edit presentaiton in the database
  edit_presentation() {
    updatePresentation(this.props.presentation._id, {title: this.state.presentationText}).then( res => {
      if (res) {
        console.log(`Updated presentation_list`);
        this.setState({ editPresentation : false,
                        presentationTitle : this.state.presentationText});
        this.props.update_data();
      }
    })
    .catch( err => {
      // alert user that update presentation failed
      console.error(`Update presentation failed. ${err}`)
    })
  }

  // Delete presentation dialog
  delete_presentation_dialog() {
    if (Platform.OS == 'web') {
      var response = confirm(confirm_delete_message);
      if (response) {
        this.delete_presentation();
      }
    } else {
      Alert.alert(
        "Delete presentation",
        confirm_delete_message,
        [ { text: "Yes", onPress: () => {
          this.delete_presentation();
        } },
          { text: "No" }
      ])
    }
  }

  // Delete presentation in the database
  delete_presentation() {
    try {
      // Delete presentation
      deletePresentation(this.props.presentation._id).then((res) => {
        // Update Session's presentation list
        if (res) {
          // Get a copy of presentation_list (from session) with item._id removed
          // Remove that only if deleting the presentation was successful
          getSession(this.state.presentation.session_id).then(_sess => {
            if (_sess) {
              let _list = _sess.presentation_list.filter((val) => {
                return val != this.props.presentation._id;
              });
              updateSession(this.state.presentation.session_id, {
                presentation_list: _list,
              })
              .then((_res) => {
                console.log(_res);
                // Get a copy of presentation_list (from user) with item._id removed
                // Remove that only if deleting the presentation was successful
                getUser(sessionStorage.getItem('UserId')).then(_user => {
                  if (_user) {
                    let _list = _user.presentation_list.filter((val) => {
                      return val != this.props.presentation._id;
                    });
                    updateUser(sessionStorage.getItem('UserId'), {
                      presentation_list: _list,
                    })
                    .then((_res) => {
                      console.log(`Updated presentation_list`);
                      this.setState({ editPresentation : false });
                      this.props.update_data();
                    })
                    .catch((err) => {
                      console.error(
                        `Failed to update session after delete presentation. ${err}`
                      );
                    });
                  }
                })
              })
              .catch((err) => {
                console.error(
                  `Failed to update session after delete presentation. ${err}`
                );
              });
            }
          })
        }
      })
    } catch (E) {
      console.error(`Failed to delete presentation. ${err}`);
    }
  }

  render() {
    {/* Showing the presentation if the user is a non-presenter or if the
    user is a presenter not editing the presentation */}
    
    if ((this.state.presentation.presenter_list.includes(sessionStorage.getItem('UserId')) && !this.state.editPresentation) ||
    (!this.state.presentation.presenter_list.includes(sessionStorage.getItem('UserId')))) {
      return (
        <TouchableOpacity style={styles.presentationContainer}
          onPress={() => {this.props.navigation.navigate('Presentation', {
          presentation: this.state.presentation,
          updateData: this.props.update_data,
          sessionTitle: this.state.session_title,
        })}}
        >
          <View style={{width: window.window.width / (1.6), flexDirection: 'row', justifyContent: 'space-between', marginBottom: 30}}>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <View style={styles.userIconContainer}>
                { this.state.presentation.presenter_list.includes(sessionStorage.getItem('UserId')) &&
                  <Feather name="user" size={24} color={Colors.ppMainPurple} />
                }
              </View>
              <Text style={[styles.item, {marginStart: 20}]}>{this.state.presentationTitle}</Text>
            </View>
            {(this.state.presentation.presenter_list.includes(sessionStorage.getItem('UserId')) && !this.state.editPresentation) &&
            <TouchableOpacity onPress={() => this.setState({ editPresentation : true})}
              style={styles.editButtonContainer}>
                <MaterialCommunityIcons name="pencil-outline" size={window.window.height / 40} color={Colors.ppTextColor} />
            </TouchableOpacity>
            }
          </View>
        </TouchableOpacity>
        )

        {/* Showing the text input box and the button panel if the student
        is a presenter editing the presentation title */}
      } else if (
        this.state.presentation.presenter_list.includes(sessionStorage.getItem('UserId')) &&
        this.state.editPresentation) {
          return (
            <View style={styles.presentationContainer}>
              <View style={{width: window.window.width / (1.6), flexDirection: 'row', justifyContent: 'space-between', marginBottom: 30}}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <View style={styles.userIconContainer}>
                    { this.state.presentation.presenter_list.includes(sessionStorage.getItem('UserId')) &&
                      <Feather name="user" size={24} color={Colors.ppMainPurple} />
                    }
                  </View>
                  <TextInput style={[styles.textInputEdit, {marginStart: 20}]}
                    value={this.state.presentationText}
                    maxLength={30}
                    onChangeText={text => this.setState({ presentationText : text })}
                  />
                </View>
                <View style={{justifyContent: 'center'}}>
                  <CancelSaveDeletePanel delete_option={true}
                                    cancel_func={() => this.setState({ editPresentation : false, presentationText : this.props.presentation.title})}
                                    save_func={() => this.verifyPresentation()}
                                    delete_func={() => this.delete_presentation_dialog()} />
                </View>
              </View>
            </View>
          )
      }
  }
}

const styles = StyleSheet.create({
  presentationContainer: {
    flexDirection: 'row',
    marginStart: 20,
    marginBottom: 30,
    marginTop: 15,
  },
  userIconContainer: {
    width: window.window.width / 40,
  },
  item: {
    fontSize: 20,
    fontWeight: 'bold',
    color: Colors.ppTextColor,
    textAlign: 'left',
  },
  editButtonContainer: {
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    borderRadius: 5,
    borderColor: Colors.ppTextColor,
    marginEnd: window.window.width / 50,
    width: window.window.height / 25,
    height: window.window.height / 25,
  },
  textInputEdit: {
    backgroundColor: 'white',
    width: window.window.width / 4,
    height: window.window.height / 25,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: Colors.ppMainPurple,
    marginEnd: 20
  },
});
