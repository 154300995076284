import React, { Component } from 'react';
import { TouchableOpacity, StyleSheet, Text } from 'react-native';
import window from '../global/Layout.js'

export default class Reaction extends Component {

  render() {

    return (
      <TouchableOpacity style={styles.reactionStyle}
            onPress={() => this.props.addReactFunc()}>
        <Text>{this.props.reaction}</Text>
        { this.props.count != "" && <Text> {this.props.count}</Text>}
      </TouchableOpacity>
    )
  }
}


const styles = StyleSheet.create({
  reactionStyle: {  
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: window.window.width / 28,
    height: window.window.height / 35,
    marginEnd: 5,
    backgroundColor: 'white',
  },
});
