import React, { Component } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import window from '../global/Layout.js';
import { getUser, getPresentation,
  getSession } from '../custom-modules/backendAPI';
import UserIcon from './UserIcon';
import ReactionsContainer from './ReactionsContainer';
import { Colors } from '../global/Constants';
import TagsContainer from './TagsContainer.js';
import StarsContainer from './StarsContainer.js';
import { FontAwesome } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';

export default class CommentViewStudent extends Component {

  constructor(props) {

    super(props);

    this.state = {
      response_object : this.props.response,
      publish_time : this.props.response.publish_time,
      user_of_comment : {},
      reaction_objects: null,
      react_count_dict: [],
      presentation: {},
      session_show_reactions: false,
      session_show_tags: false,
      session_show_stars: false,
    }
  }

  componentDidMount() {
    getUser(this.state.response_object.listener_id, true).then(_user => {
      if (_user) {
        this.setState({ user_of_comment : _user });
      }
    })
    getPresentation(this.props.response.presentation_id, true).then(_pres => {
      if (_pres) {
        this.setState({ presentation : _pres });
        getSession(_pres.session_id).then(_sess => {
          if (_sess) {
            this.setState({ 
              session_show_reactions: _sess.emojis_feedback,
              session_show_tags: _sess.tags_feedback,
              session_show_stars: _sess.star_rating_feedback,
            })
          }
        })
      }
    })
  }


  render() {
    return (
        <View style={[styles.container, {width: window.window.width / 1.7}]}>
          <View style={{flexDirection: 'row'}}>
            <View style={{alignItems: 'center', marginStart: 15, marginTop: 15}}>
              <UserIcon userIconDim={window.window.height / 15}
                profile_pic={this.state.user_of_comment.profile_pic} />
              <Text style={{marginTop: 5}}>{this.state.user_of_comment.first_name}</Text>
            </View>
            <View style={{justifyContent: 'center'}}>
              <Text style={{marginStart: 15, alignSelf: 'flex-start', marginTop: 15,
              fontSize: 20}}>{this.state.response_object.text}</Text>
              <View style={{flexDirection: 'row', justifyContent: 'space-between', width: window.window.width / 1.8}}>
                { this.state.session_show_reactions ?
                  <ReactionsContainer response={this.props.response} />
                  :
                  <View style={styles.reactionsContainer} />
                }
                { this.state.session_show_stars && this.state.presentation.presenter_list && this.state.presentation.presenter_list.includes(sessionStorage.getItem('UserId')) &&
                  <StarsContainer response={this.props.response} />
                }
              </View>
              <View>
                { this.state.session_show_tags && this.state.presentation.presenter_list && this.state.presentation.presenter_list.includes(sessionStorage.getItem('UserId')) &&
                  <TagsContainer response={this.props.response} session_id={this.state.presentation.session_id}/>
                }
              </View>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                { this.props.response.instructor_bookmark &&
                  <FontAwesome style={{marginStart: 15}} name="bookmark" size={24} color={Colors.ppMainPurple} />
                }
                { this.props.response.instructor_hidden && (this.props.response.listener_id === sessionStorage.getItem('UserId')) &&
                  <Ionicons style={{marginStart: 15}} name="ios-eye-off" size={24} color={Colors.ppMainPurple} />
                }
                <Text style={{marginStart: 15}}>{(new Date(this.state.publish_time)).toLocaleString("en-US")}</Text>
              </View>
            </View>
          </View>
        </View>
      )
  }
}

const styles = StyleSheet.create({
  container: {
    marginStart: 10,
    marginEnd: 10,
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  reactionsContainer: {
    flexWrap: 'wrap',
    marginTop: window.window.height / 60,
    marginStart: 15,
  },
});
