import * as React from 'react';
import { Platform, StatusBar, StyleSheet, View } from 'react-native';
import { SplashScreen } from 'expo';
import * as Font from 'expo-font';
import { Ionicons } from '@expo/vector-icons';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import StudentNavigation from './navigation/StudentNavigation';
import AdminNavigation from './navigation/AdminNavigation';
// import InstructorBottomNavigation from './navigation/InstructorBottomNavigation';
// import ResearcherBottomNavigation from './navigation/ResearcherBottomNavigation';
// import useLinking from './navigation/useLinking';
import Login from './screens/Login';
import Signup from './screens/Signup';
import VerifyDetails from './screens/VerifyDetails';
import ResetPassword from './screens/ResetPassword';
import window from './global/Layout'

const Stack = createStackNavigator();

let initialRouteName = 'Log in';


export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);
  //const [initialNavigationState, setInitialNavigationState] = React.useState();
  const containerRef = React.useRef();
  //const { getInitialState } = useLinking(containerRef);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {

    if (sessionStorage.getItem('UserId')) {
      if (sessionStorage.getItem('Role') == "listener") {
        initialRouteName = 'StudentNavigation';
      } else {
        initialRouteName = 'AdminNavigation';
      // } else if (sessionStorage.getItem('Role') == "instructor") {
      //   initialRouteName = 'InstructorBottomNavigation';
      // } else if (role == 'researcher') {
      //   navigation.navigate('ResearcherBottomNavigation');
      }
    }

    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHide();

        //// Load our initial navigation state
        //setInitialNavigationState(await getInitialState());

        // Load fonts
        await Font.loadAsync({
          ...Ionicons.font,
          'space-mono': require('./assets/fonts/SpaceMono-Regular.ttf'),
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hide();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return null;
  } else {
    return (
      <View style={styles.container}>
        {Platform.OS === 'ios' && <StatusBar barStyle="default" />}
        <NavigationContainer ref={containerRef}>
          <Stack.Navigator initialRouteName={initialRouteName} screenOptions={{headerShown: false}}>
            <Stack.Screen
              name="StudentNavigation"
              component={StudentNavigation}
            />
            <Stack.Screen
              name="Sign up"
              component={Signup}
            />
            <Stack.Screen 
              name="Log in"
              component={Login}
            />
            <Stack.Screen 
              name="Verify Details"
              component={VerifyDetails}
            />
            <Stack.Screen 
              name="Reset Password"
              component={ResetPassword}
            />
            <Stack.Screen 
              name="AdminNavigation"
              component={AdminNavigation}
            />
          </Stack.Navigator>
        </NavigationContainer>
      </View>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: window.window.width,
    alignContent: 'center',
    alignSelf: 'center',
  },
  header: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
