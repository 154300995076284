import * as React from "react";
import { StyleSheet, Text, View, TouchableOpacity, Platform,
  ScrollView } from "react-native";
import { Colors } from "../global/Constants";
import window from "../global/Layout";
import SideMenu from "../components/SideMenu";
import CancelSaveDeletePanel from "../components/CancelSaveDeletePanel";
import Logo from '../components/Logo';
import UserIcon from "../components/UserIcon";
import { ProfilePicture } from '../data/UserData'
import { getUser, updateUser, deleteUser } from '../custom-modules/backendAPI';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { TextInput } from "react-native-paper";
import { set_user, Role } from '../data/UserData';

const confirm_delete_message =
      "Are you sure you want to delete your account? This cannot be undone";

export default function ProfileTab({ navigation }) {

  const [errors, setErrors] = React.useState([]);
  const [editFirstName, setEditFirstName] = React.useState(false);
  const [editLastName, setEditLastName] = React.useState(false);
  const [user, setUser] = React.useState({_id: '', username: '', email: '', roles: [] });
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");

  React.useEffect(() => {
    getUser(sessionStorage.getItem('UserId'), false).then(_user => {
      if (_user) { // if non-empty result
        setUser(_user);
        setFirstName(_user.first_name);
        setLastName(_user.last_name);
      }
    })
  }, [navigation])

  function addError(newError) {
    let current_errors = errors;
    current_errors.push(newError)
    setErrors(current_errors);
  }
  
  // Verify that the user is valid
  async function verifyUser() {
    try {

      // Clearing the array of errors
      let newErrors = errors;
      while(newErrors.length > 0) newErrors.pop();
      setErrors(newErrors);

      // Illegal name
      if (firstName.length < 2 || lastName.length < 2) {
        addError(" Name must be 2 characters or more")
      }
      // Name has spaces
      if (firstName.indexOf(' ') >= 0 || lastName.indexOf(' ') >= 0) {
        addError(" Name must not contain spaces")
      }
      
      if (errors.length == 0) {
        edit_user();
        setEditFirstName(false);
        setEditLastName(false);
      } else {
        if (Platform.OS == 'web') {
          alert(errors)
        } else {
          Alert.alert(
            "Error",
            errors,
            [ { text: "OK" } ] )
        }
      }
    } catch (E) {
      console.log(E);
    }
  }

  // Edit user in the database
  function edit_user() {
    getUser(sessionStorage.getItem('UserId')).then(_result => {
      _result.first_name = firstName;
      _result.last_name = lastName;
      // _result.profile_pic = profile_pic;
      // _result.consent = consentYes;
      try {
        updateUser(sessionStorage.getItem('UserId'), _result).then(_updated_result => {
          if (_updated_result) {
            set_user(Role, sessionStorage.getItem('UserId'), _result.profile_pic, firstName);
            console.log("User was edited successfully!");
          }
        });
      } catch (E) {
        console.log(E);
      }
    })
  }

  // Delete user dialog
  function delete_user_dialog() {
    if (Platform.OS == 'web') {
      var response = confirm(confirm_delete_message);
      if (response) {
        delete_user();
      }
    } else {
      Alert.alert(
        "Delete user",
        confirm_delete_message,
        [ { text: "Yes", onPress: () => {
          delete_user();
        } },
          { text: "No" }
      ])
    }
  }

  // Delete user in the database
  function delete_user() {
    try {
      deleteUser(sessionStorage.getItem('UserId')).then(_res => {
        if (_res) {
          set_user("", "", "null", "null");
          navigation.navigate('Log in');
          console.log("User was deleted successfully!");
        }
      })
    } catch (E) {
      console.log(E);
    }
  }

  return (
    <View style={styles.screenContainer}>
      <View style={{ flexDirection: "row", flex: 1 }}>
        <SideMenu navigation={navigation} />
        <View style={styles.logoAndScrollContainer}>
          <Logo />
          <ScrollView style={styles.scrollViewContainer}>
            <Text style={styles.title}>Profile</Text>
            <UserIcon
              userIconDim={window.window.height / 10}
              profile_pic={ProfilePicture} />
            <View style={styles.textContainer}>
              <View style={[styles.textContainer, {marginTop: 0, justifyContent: 'center'}]}>
                { !editFirstName ?
                  <View style={{flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', width: window.window.width / 1.5}}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                      <Text style={styles.titleText}>First Name:  </Text>
                      <Text style={styles.infoText}>{firstName}</Text>
                    </View>
                    <TouchableOpacity onPress={() => setEditFirstName(true)}
                      style={styles.editButtonContainer}>
                        <MaterialCommunityIcons name="pencil-outline" size={window.window.height / 40} color={Colors.ppTextColor} />
                    </TouchableOpacity>
                  </View>
                :
                  <View style={{flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', width: window.window.width / 1.5}}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                      <Text style={styles.titleText}>First Name:  </Text>
                      <TextInput style={styles.textInputEdit}
                        value={firstName}
                        maxLength={100}
                        onChangeText={text => setFirstName(text)}
                      />
                    </View>
                    <View>
                      <CancelSaveDeletePanel cancel_func={() => setEditFirstName(false)}
                                        save_func={() => verifyUser()}/>
                    </View>
                  </View>
                }
              </View>
            </View>
            <View style={styles.textContainer}>
              <View style={[styles.textContainer, {marginTop: 0, justifyContent: 'space-between'}]}>
                { !editLastName ?
                  <View style={{flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', width: window.window.width / 1.5}}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                      <Text style={styles.titleText}>Last Name:  </Text>
                      <Text style={styles.infoText}>{lastName}</Text>
                    </View>
                    <TouchableOpacity onPress={() => setEditLastName(true)}
                      style={styles.editButtonContainer}>
                        <MaterialCommunityIcons name="pencil-outline" size={window.window.height / 40} color={Colors.ppTextColor} />
                    </TouchableOpacity>
                  </View>
                :
                  <View style={{flexDirection: 'row', justifyContent: 'space-between', width: window.window.width / 1.5}}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                      <Text style={styles.titleText}>Last Name:  </Text>
                      <TextInput style={styles.textInputEdit}
                        value={lastName}
                        maxLength={100}
                        onChangeText={text => setLastName(text)}
                      />
                    </View>
                    <View>
                      <CancelSaveDeletePanel cancel_func={() => setEditLastName(false)}
                                        save_func={() => verifyUser()}/>
                    </View>
                  </View>
                }
              </View>
            </View>
            <View style={styles.textContainer}>
              <Text style={styles.titleText}>Email Address:  </Text>
              <Text style={styles.infoText}>{user.email}</Text>
            </View>
            <View style={styles.textContainer}>
              <Text style={styles.titleText}>Username:  </Text>
              <Text style={styles.infoText}>{user.username}</Text>
            </View>
            <View style={styles.upperButtonContainer}>
              <TouchableOpacity style={styles.deleteAccountContainer}
                onPress={delete_user_dialog}>
                <Text style={styles.deleteAccountText}>Delete Account</Text>
              </TouchableOpacity>
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  logoAndScrollContainer: {
    justifyContent: 'center',
  },
  scrollViewContainer: {
    width: window.window.width / 1.5,
    height: window.window.height,
    marginStart: window.window.width / 6,
    backgroundColor: Colors.backgroundColor,
  },
  upperButtonContainer: {
    alignSelf: "flex-end",
    marginBottom: window.window.height / 20,
  },
  screenContainer: {
    flex: 1,
    flexDirection: "column",
  },
  title: {
    fontSize: window.window.height / 30,
    marginTop: window.window.height / 30,
    marginBottom: 30,
    fontWeight: "bold",
  },
  textContainer: {
    flexDirection: 'row',
    backgroundColor: 'white',
    marginTop: window.window.height / 20,
    width: window.window.width / 1.5,
    height: window.window.height / 15,
    alignItems: 'center',
  },
  infoText: {
    fontSize: window.window.height / 50,
    fontWeight: 'bold',
  },
  titleText: {
    color: Colors.ppMainPurple,
    marginStart: window.window.width / 40,
    fontSize: window.window.height / 50,
    fontWeight: 'bold',
  },
  deleteAccountContainer: {
    marginTop: window.window.height / 50,
    width: window.window.width / 8,
    height: window.window.height / 17,
    borderColor: Colors.ppRed,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 10,
    backgroundColor: 'white',
  },
  deleteAccountText: {
    color: Colors.ppRed,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: window.window.height / 50
  },
  editButtonContainer: {
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    borderColor: Colors.ppTextColor,
    marginEnd: window.window.width / 50,
    width: window.window.height / 25,
    height: window.window.height / 25,
  },
  textInputEdit: {
    backgroundColor: 'white',
    width: window.window.width / 5.5,
    height: window.window.height / 28,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: Colors.ppMainPurple,
  }
});
