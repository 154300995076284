import React, { Component } from 'react';
import { View, TextInput, StyleSheet, Text } from 'react-native';
import window from '../global/Layout.js'
import { Colors } from '../global/Constants'

export default class TextBox extends Component {

  render() {
    return (
      <View>
        <View style={{flexDirection: 'row'}}>
          <Text style={styles.fieldTitle}>{this.props.fieldTitle}</Text>
          <Text style={{color: 'red', fontSize: 30}}>*</Text>
        </View>
        <TextInput
          style={[styles.textInput, {width: !this.props.long ? window.window.width / 5 : window.window.width / 2.5}]}
          onChangeText={text => this.props.onChangeText(text)}
          value={this.props.value}
          onSubmitEditing={() => this.props.onSubmitEditing()}
          secureTextEntry={this.props.secureTextEntry}
          placeholder={this.props.placeholder}
          maxLength={this.props.maxLength}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  textInput: {
    borderRadius: 10,
    marginBottom: 20,
    backgroundColor: 'white',
    fontSize: 20,
    height: window.window.height / 20,
  },
  fieldTitle: {
    color: Colors.ppTextColor,
    fontWeight: 'bold',
    fontSize: window.window.height / 35,
    marginBottom: 10,
  }
});
