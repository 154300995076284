import * as React from 'react';
import { StyleSheet, View, ScrollView, Text } from 'react-native';
import { useState } from 'react';
import SideMenu from '../components/SideMenu';
import window from '../global/Layout';
import SeparatingLine from '../components/SeparatingLine';
import BackButton from '../components/BackButton';
import EditButton from '../components/EditButton';
import Logo from '../components/Logo';
import { Colors } from '../global/Constants';
import SimpleFlatList from '../components/SimpleFlatList';
import MainButton from "../components/MainButton";
import { getQuestion, getResponse, getSession,
  getUser } from '../custom-modules/backendAPI';

export default function SessionScreen({route, navigation}) {


  const [session, setSession] = useState(route.params.session ?
                                route.params.session : null);

  React.useEffect(() => {
  }, [navigation])

  function loadSession() {
    getSession(route.params.session._id, true).then(_res => {
      if (_res) {
        setSession(_res);
      }
    })
  }

  function downloadCsv(data) {
    let csvContent = "data:text/csv;charset=utf-8," 
    + ["Response ID","Response Text","Listener ID","Listener Name",
    "Question ID","Question Text", "Presentation ID",
    "Presentation Title","Publish Time","Instructor Bookmark",
    "Instructor Hide","Instructor Comment"].join("\t") + "\n"
    + data.map(e => e.join("\t")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", session.title + ".csv");
    document.body.appendChild(link); // Required for FF
  
    link.click(); // This will download the data file named "[session_title].csv".
  }

  function exportSession() {   
    let data = [];
    
    getSession(route.params.session._id, true).then(_sess => {
      if (_sess) {
        // let presentation_ids = Array.from(_sess.presentation_objects, x => x._id)
        _sess.presentation_objects.forEach(_pres => {
          _pres.question_list.forEach(_ques_id => {
            getQuestion(_ques_id, true).then(_ques => {
              if (_ques) {
                _ques.response_list.forEach(_resp_id => {
                  getResponse(_resp_id, true).then(_resp => {
                    if (_resp) {
                      getUser(_resp.listener_id, true).then(_listener => {
                        if (_listener) {
                          let temp_arr = [];
                          temp_arr.push(_resp._id);
                          temp_arr.push(_resp.text);
                          temp_arr.push(_resp.listener_id);
                          temp_arr.push(_listener.first_name + " " + _listener.last_name);
                          temp_arr.push(_resp.question_id);
                          temp_arr.push(_ques.text);
                          temp_arr.push(_resp.presentation_id);
                          temp_arr.push(_pres.title);
                          temp_arr.push(_resp.publish_time);
                          temp_arr.push(_resp.instructor_bookmark);
                          temp_arr.push(_resp.instructor_hidden);
                          temp_arr.push(_resp.instructor_comment);
    
                          data.push(temp_arr);
    
                          if (data.length == _sess.responses_num) {
                            downloadCsv(data);
                          }
                        }
                      })

                    }
                  })
                });
              }
            })
          })
        });
      }
    })
  }

  return (
    <View style={styles.screenContainer}>
      <View style={{flexDirection: 'row', flex: 1}}>
        <SideMenu navigation={navigation} />
        <View style={styles.logoAndScrollContainer}>
          <Logo />
          <View style={styles.upperButtonContainer}>
            { (sessionStorage.getItem('Role') != "listener") &&
            <MainButton
              active={true}
              text={"Export Session"}
              square={false}
              onPress={() => {
                exportSession();
              }}
            />
            }
          </View>
          <BackButton backTo={route.params.backToDashboard ? "DashboardTab" : "Course"} navigation={navigation} />
          <View style={{marginStart: window.window.width / 6}} >
            <SeparatingLine />
          </View>
          <ScrollView style={styles.scrollViewContainer}>
            <Text style={styles.title}>{session.course_title} {session.course_code}</Text>
            <View style={styles.presentations}>
              <View style={styles.presentationTitlesContainer}>
                <Text style={styles.presentationTitle}>{session.title}</Text>
                { sessionStorage.getItem('Role') != "listener" &&
                  <EditButton margin_top={true} text={"EDIT SESSION"} onPress={() => {
                    navigation.navigate("CreateEditSession", {
                      session: session,
                      course_id: session.course_id,
                      create: false,
                      onGoBack: () => loadSession(),
                    });
                  }} />
                }
              </View>
              <SimpleFlatList
                screen={"presentations"}
                navigation={navigation}
                session_id={session._id}
              />
            </View>

          </ScrollView>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  logoAndScrollContainer: {
    justifyContent: 'center',
  },
  scrollViewContainer: {
    marginStart: window.window.width / 6,
    backgroundColor: Colors.backgroundColor,
  },
  screenContainer: {
    flex: 1,
    flexDirection: "column",
  },
  title: {
    fontSize: window.window.height / 30,
    marginTop: window.window.height / 30,
    marginEnd: window.window.width / 30,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  upperButtonContainer: {
    alignSelf: 'flex-end',
    marginBottom: window.window.height / 20,
  },
  presentations: {
    marginTop: 30,
    borderRadius: 30,
    marginBottom: 25,
    backgroundColor: 'white',
    width: window.window.width / 1.5,
    height: window.window.height / 1.5,
  },
  presentationTitlesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 20,
    marginBottom: 30,
    marginTop: 15,
  },
  presentationTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: Colors.ppGreyText,
  },
});