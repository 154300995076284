import * as React from 'react';
import { StyleSheet, Text, View, Platform, Alert, TouchableOpacity,
  ScrollView } from 'react-native';
import { Colors } from '../global/Constants';
import window from '../global/Layout.js'
import TextBox from '../components/TextBox';
import MainButton from '../components/MainButton';
import Logo from '../components/Logo'
import CryptoJS from "react-native-crypto-js";
import { ENCRYPTION_KEY } from '../data/UserData'
import { getUser, updateUser } from '../custom-modules/backendAPI';


export default function ResetPassword({route, navigation}) {
  
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [errors, setErrors] = React.useState([]);

  function addError(newError) {
    let current_errors = errors;
    current_errors.push(newError)
    setErrors(current_errors);
  }

  function validatePassword() {
    return password.length > 7;
  }

  // Check if sign up process is valid
  async function ValidateInput() {
    // Clearing the array of errors
    let newErrors = errors;
    while(newErrors.length > 0) newErrors.pop();
    setErrors(newErrors);
    // Validating and setting errors if there are any
    // Passwords mismatch
    if (password != confirmPassword) {
      addError(" Passwords do not match");
    }
    // Illegal email address
    if (!validatePassword()) {
      addError(" Password must be 7 or more characters")
    }
    // Presenting the errors or changing password successfully
    if (errors.length == 0) {
      // Change Password and give alert that password has changed
      let new_pass = CryptoJS.AES.encrypt(password, ENCRYPTION_KEY).toString();
      console.log(route.params.userId);
      console.log(new_pass);

      getUser(route.params.userId).then(_result => {
        _result.password = new_pass;
        try {
          updateUser(route.params.userId, _result).then(_updated_result => {
            if (_updated_result) {
              console.log(_updated_result);
              let success_msg = "Password successfully changed";
              
              if (Platform.OS == 'web') {
                alert(success_msg)
              } else {
                Alert.alert(
                  "Error",
                  success_msg,
                  [ { text: "OK" } ] )
                }
                // Go back to Login page
                navigation.navigate("Log in");
            } else {
              let unsuccess_msg = "Password change was unsuccessful";
              if (Platform.OS == 'web') {
                alert(unsuccess_msg)
              } else {
                Alert.alert(
                  "Error",
                  unsuccess_msg,
                  [ { text: "OK" } ] )
              }
            }
          });
        } catch (E) {
          console.log(E);
        }
      })
    } else {
      if (Platform.OS == 'web') {
        alert(errors)
      } else {
        Alert.alert(
          "Error",
          errors,
          [ { text: "OK" } ] )
      }
    }

  }

  return (
    <ScrollView style={styles.scrollContainer}>
      <View style={styles.header}>
        <Logo />
      </View>
      <View style={styles.viewContainer}>
        <Text style={{color: Colors.ppTextColor,
                      fontSize: 25,
                      fontWeight: 'bold',
                      marginBottom: 20}}>Reset Password</Text>
        <TextBox  fieldTitle={"NEW PASSWORD"}
          onChangeText={setPassword}
          value={password}
          onSubmitEditing={ValidateInput}
          secureTextEntry={true}
        />
        <TextBox  fieldTitle={"CONFIRM NEW PASSWORD"}
          onChangeText={setConfirmPassword}
          value={confirmPassword}
          onSubmitEditing={ValidateInput}
          secureTextEntry={true}
        />
        <MainButton onPress={ValidateInput} text={"Reset Password"} active={true} />

        <View style={{flexDirection: 'row', marginBottom: window.window.height / 20}}>
          <TouchableOpacity onPress={() => navigation.navigate("Log in")} >
            <Text style={[styles.loginText, {textDecorationLine: 'underline'}]}>Back to Log in</Text>
          </TouchableOpacity>
        </View>
      </View>
    </ScrollView>
  );
}


const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
    backgroundColor: Colors.backgroundColor,
  },
  viewContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: window.window.height / 40,
  },
  fieldTitle: {
    color: Colors.ppTextColor,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  loginText: {
    color: Colors.ppTextColor,
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 15,
  },
  header: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});