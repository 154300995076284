import React, { Component } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { SimpleLineIcons } from '@expo/vector-icons';
import { Colors } from '../global/Constants';
import window from '../global/Layout';
import { useDynamicStyle } from 'react-native-dynamic-styles';

export default class SideMenu extends Component {

  // sidebar = useDynamicStyle(
  //   () => ({
  //     flex: 0.4,
  //     height: window.window.height,
  //     backgroundColor: Colors.ppMainPurple,
  //   }),
  //   [window.window]
  // );

  render() {
    return (
      <View style={styles.sideBar}>
        <View style={{alignItems: 'center', marginTop: 20}}>
          <TouchableOpacity onPress={() => this.props.navigation.openDrawer()}>
            <SimpleLineIcons name="menu" size={30} color="white" />
          </TouchableOpacity>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  sideBar: {
    flex: 0.4,
    height: window.window.height,
    backgroundColor: Colors.ppMainPurple,
  },
});