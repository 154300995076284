import React, { Component } from 'react';
import { StyleSheet, View, TextInput, FlatList, Text,
  TouchableOpacity, Platform } from 'react-native';
import { Colors } from '../global/Constants';
import { getQuestion, updateQuestion, getResponse,
  createResponse, getSession, updateSession,
  getPresentation} from '../custom-modules/backendAPI';
import window from '../global/Layout';
import CommentViewAdmin from './CommentViewAdmin';
import CommentViewStudent from './CommentViewStudent';
import MainButton from './MainButton';
import UserIcon from './UserIcon';
import SortComments from './SortComments';
import { FirstName, ProfilePicture } from '../data/UserData';

export default class QuestionsCommentsLists extends Component {

  constructor(props) {
    super(props);

    this.state = {
      questionsList: new Map(),
      commentsList: new Map(),
      selectedQuestionIndex: 0,
      changeFlag: false,
      setupQuestionIndex: 0,
      instructor_response: "",
      presentation_id: this.props.presentation_id,

      curr_user_presenter: false,
    }
}

  componentDidMount() {
    this.loadData();
  }
  
  loadData() {
    getPresentation(this.props.presentation_id, true).then(_pres => {
      if (_pres) {
        this.setState({
          curr_user_presenter : _pres.presenter_list.includes(sessionStorage.getItem('UserId'))
        });
        this.props.question_list.forEach(question_id => {
          getQuestion(question_id, true).then(_question => {
            if (_question) { // if non-empty result
              this.setState({ changeFlag : !this.state.changeFlag })
              this.loadComments(_question);
            }
          }).catch(_err => {
            console.error(`[QuestionsCommentsLists] ${_err}`)
          })
        });
        this.setState({ setupQuestionIndex : 0 });
      }
    })
  }

  loadComments(_question) {
    if (this.state.questionsList.size < this.props.question_list.length) {
      let newQuestionList = new Map(this.state.questionsList);
      newQuestionList.set(this.state.setupQuestionIndex, _question);
      this.setState({
        questionsList: newQuestionList,
        changeFlag : !this.state.changeFlag,
      });
    }
    let questionComments = [];
    _question.response_list.forEach(_response_id => {
      getResponse(_response_id, true).then(_response => {
        if (_response) { // if non-empty result
          questionComments.push(_response);
        }
      })
    });
    let newCommentsList = new Map(this.state.commentsList);
    newCommentsList.set(this.state.setupQuestionIndex, questionComments);
    console.log(newCommentsList.get(this.state.setupQuestionIndex));
    this.setState({
      commentsList : newCommentsList,
      setupQuestionIndex: this.state.setupQuestionIndex + 1,
      changeFlag : !this.state.changeFlag,
    })
  }

  addResponseToDB(newResponse) {
    createResponse(newResponse).then(_id => {
      if (_id) { // if non-empty result
        // Update question
        getQuestion(this.state.questionsList.get(this.state.selectedQuestionIndex)._id).then(_result => {
          _result.response_list.push(_id);
          updateQuestion(this.state.questionsList.get(this.state.selectedQuestionIndex)._id, _result).then(_update_result => {
            if (_update_result) {
              getSession(this.props.session_id).then(_sess => {
                updateSession(this.props.session_id, {responses_num: _sess.responses_num + 1}).then(_res => {
                  this.loadData();
                });
              })
            }
          });
        })
      }
    })
    this.setState({
      instructor_response : "",
      changeFlag : !this.state.changeFlag

    });
    // this.props.updateData;
    this.textInput.clear(); // Clearing the comment text box
  }

  // Create and add comment to the database
  addResponse = () => {
    if (this.state.instructor_response == "") {
      if (Platform.OS == 'web') {
        alert("Comment must not be empty")
      } else {
        Alert.alert("Error",
          "Comment must not be empty",
          [ { text: "OK" } ] )
      }
    } else { 
      let newResponse = {
        _id: "0",
        listener_id: sessionStorage.getItem('UserId'),
        question_id: this.state.questionsList.get(this.state.selectedQuestionIndex)._id,
        presentation_id: this.state.presentation_id,
        text: this.state.instructor_response,
        upvote_list: [],
        react_list: [],
        star_list: [],
        tag_list: [],
        publish_time: new Date(),
        instructor_bookmark: false,
        instructor_hidden: false,
        instructor_comment: "",
      }
      this.addResponseToDB(newResponse);
    }
  }


// Sort responses by category
sortBy = (category) => {
  let newCommentsList = new Map(this.state.commentsList);
  let newQuestionList = [];

  if (category == "time") {
    newQuestionList = newCommentsList.get(this.state.selectedQuestionIndex).sort((a, b) => (new Date(b.publish_time).getTime() - new Date(a.publish_time).getTime()));
  } else if (category == "flagged") {
    newQuestionList = newCommentsList.get(this.state.selectedQuestionIndex).sort((a, b) => (a.instructor_bookmark === b.instructor_bookmark)? 0 : a.instructor_bookmark? -1 : 1);
  } else if (category == "stars") {
    newQuestionList = newCommentsList.get(this.state.selectedQuestionIndex).sort(function sortByStars(a, b) {
      // Find for "a" and "b" the stars by the current user, or use 0 if no stars recorded
      let a_stars = 0;
      let a_element = a.star_objects.find(element => element.presenter_id === sessionStorage.getItem('UserId'));
      if (a_element) a_stars = a_element.value;
      
      let b_stars = 0;
      let b_element = b.star_objects.find(element => element.presenter_id === sessionStorage.getItem('UserId'));
      if (b_element) b_stars = b_element.value;

      return b_stars - a_stars;
    })
  }
  
  newCommentsList.set(this.state.selectedQuestionIndex, newQuestionList);
  this.setState({
    commentsList : newCommentsList,
    changeFlag : !this.state.changeFlag,
  });
}

  render() {
    return (
      <View style={styles.screenContainer}>
        <View>
          <TouchableOpacity style={styles.backButton} onPress={() => this.props.navigation.goBack()}>
            <Text style={{color: 'white', fontWeight: 'bold', fontSize: 15}}>
              {'<'} Back to Presentation
            </Text>
          </TouchableOpacity>
          <FlatList
            data={Array.from(this.state.questionsList.keys())}
            scrollEnabled={true}
            extraData={this.state.changeFlag}
            style={styles.questionList}
            keyExtractor={(item) => item.toString()}
            renderItem={({item}) => (
              <TouchableOpacity style={[styles.questionTitle,
                  {backgroundColor: this.state.selectedQuestionIndex == item ? Colors.backgroundColor : null}]}
                  onPress={() => {this.setState({ selectedQuestionIndex: item })}}>
                <Text style={{fontSize: 35, fontWeight: 'bold', marginStart: 25, marginEnd: 20,
                      color: this.state.selectedQuestionIndex == item ? 'black' : 'white',
                      opacity: this.state.selectedQuestionIndex == item ? 1 : 0.5}}>
                  Q{item + 1}
                </Text>
                <Text style={{fontSize: 23, fontWeight: 'bold', marginEnd: 40,
                      color: this.state.selectedQuestionIndex == item ? 'black' : 'white',
                      opacity: this.state.selectedQuestionIndex == item ? 1 : 0.5}}>
                  {this.state.questionsList.get(item).text}
                </Text>
            </TouchableOpacity>
            )}
          />
        </View>
        <View>
          <View style={{flexDirection: 'row', marginStart: 50,
                marginTop: 50, alignItems: 'center', justifyContent: 'space-between'}}>
            <View style={{flexDirection: 'row'}}>
              <View style={{alignItems: 'center', marginEnd: 15, marginTop: 20 }}>
                <UserIcon userIconDim={window.window.height / 15}
                  profile_pic={ProfilePicture} />
                <Text style={{marginTop: 5}}>{FirstName}</Text>
              </View>
              <TextInput
                style={styles.textInput}
                onChangeText={text => this.setState({instructor_response : text})}
                ref={input => {this.textInput = input}}
                value={this.state.instructor_response}
                secureTextEntry={false}
                placeholder={"Add Comment..."}
                multiline
                numberOfLines={4}
                maxLength={500}
              />
              <View style={{alignSelf: 'flex-end'}}>
                <MainButton style={{height: window.window.height / 20}}
                  active={true} text={"Submit"} square={false} small={true}
                  onPress={() => this.addResponse()}
                />
              </View>
            </View>
            { this.state.curr_user_presenter &&
              <SortComments student={sessionStorage.getItem('Role') == "listener"} sort_by_func={this.sortBy}/>
            }
          </View>
          <FlatList
            data={this.state.commentsList.get(this.state.selectedQuestionIndex)}
            scrollEnabled={true}
            extraData={this.state.changeFlag}
            style={styles.commentsList}
            keyExtractor={(item) => item._id}
            renderItem={({item}) => (
              sessionStorage.getItem('Role') != "listener"
              ?
                <CommentViewAdmin extended={true} response={item} />
              :
                // If author of comment or response is not hidden - show response to user
                (!item.instructor_hidden || (item.listener_id === sessionStorage.getItem('UserId'))) &&
                <CommentViewStudent response={item} />
                
            )}
          />
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  textInput: {
    borderRadius: 10,
    marginTop: 20,
    marginEnd: 20,
    fontSize: 20,
    paddingLeft: 20,
    paddingTop: 20,
    backgroundColor: 'white',
    width: window.window.width / 4,
    height: window.window.height / 8,
  },
  backButton: {
    width: window.window.width * (1/3),
    height: window.window.height / 10,
    backgroundColor: Colors.ppMainPurple,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  questionList: {
    backgroundColor: Colors.ppMainPurple,
    width: window.window.width * (1/3),
  },
  commentsList: {
    width: window.window.width * (2/3),
    marginStart: window.window.width / 30,
    marginTop: window.window.height / 20,
  },
  questionTitle: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: window.window.width * (1/3),
    height: window.window.width / 10,
    marginStart: window.window.width / 40,
    borderRadius: 30,
  }
});
