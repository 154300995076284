import React, { Component } from "react";
import { View, Platform, FlatList, StyleSheet, ScrollView } from "react-native";
import SearchItem from "./SearchItem";
import { getUser, updateUser, getCourse, updateCourse, getPresentation,
updatePresentation, getSession } from "../custom-modules/backendAPI";

export default class SearchList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items_list: [],
      user: {},
    };
  }

  componentDidMount() {
    if (this.props.type == "courses") {
      // fetch raw data & update
      getUser(sessionStorage.getItem("UserId")).then((_user) => {
        if (_user) {
          let user_courses = [];
          _user.course_list.forEach(item => {
            user_courses.push(item);
          })
          console.log(user_courses);
          console.log(this.props.data);
          // if non-empty result
          this.setState({ items_list : user_courses,
                          user : _user});
        }
      });
    } else if (this.props.type == "members") {
      getPresentation(this.props.entity_id, true).then((_pres) => {
        if (_pres) {
          this.setState({ items_list : _pres.presenter_list})
        }
      })
    }
  }

  removeSession(itemId) {
    // Remove the id of the session from the sessions list
    let new_items_list = this.state.items_list;
    const index = new_items_list.indexOf(itemId);
    new_items_list.splice(index, 1);
    this.setState({ items_list: new_items_list });
    this.props.change_id_list(new_items_list);
  }
  
  addSession(itemId) {
    if (this.props.limit_to_one && this.state.items_list.length > 0) {
      if (Platform.OS == 'web') {
        alert("One session is already selected. You must unselect it in order to select a new one")
      } else {
        Alert.alert("Error",
          "One session is already selected. You must unselect it in order to select a new one",
          [ { text: "OK" } ] )
      }
    } else {
      // Add the id of the session to the sessions list
      let new_items_list = this.state.items_list;
      new_items_list.push(itemId);
      this.setState({ items_list: new_items_list });
      this.props.change_id_list(new_items_list);
      this.props.update_settings(itemId);
    }
  }

  removeCourse(_user, courseId) {
    // Remove the id of the course from the user's courses
    let updated_user = {};
    Object.assign(updated_user, _user);
    // Find the course index and remove it
    let course_index = _user.course_list.indexOf(courseId);
    if (course_index > -1) {
      updated_user.course_list.splice(course_index, 1);
    }
    delete updated_user["course_objects"];
    delete updated_user["session_objects"];
    delete updated_user["presentation_objects"];
    updateUser(this.props.user_id, updated_user).then(update_user_res => {
      if (update_user_res) {
        // Remove the id of the user from the course's student_list
        getCourse(courseId, true).then(_course => {
          let updated_course = {};
          Object.assign(updated_course, _course);
          // Find the user index and remove it
          let user_index = _course.student_list.indexOf(this.props.user_id);
          if (user_index > -1) {
            updated_course.student_list.splice(user_index, 1);
          }
          updateCourse(courseId, updated_course)
                          .then(update_course_res => {})
        })
      }
    })
  }

  addCourse(_user, courseId) {
    // Add the id of the course to the user's courses
    let updated_user = {};
    Object.assign(updated_user, _user);
    updated_user.course_list.push(courseId);
    delete updated_user["course_objects"];
    delete updated_user["session_objects"];
    delete updated_user["presentation_objects"];
    updateUser(this.props.user_id, updated_user).then(update_user_res => {
      if (update_user_res) {
        // Add the id of the user to the course's student_list
        getCourse(courseId).then(_course => {
          let updated_course = {};
          Object.assign(updated_course, _course);
          updated_course.student_list.push(this.props.user_id);
          updateCourse(courseId, updated_course)
                          .then(update_course_res => {})
        })
      }
    })
  }

  removeMember(user_id, presentation_id) {
    // Remove the id of the user from the presentation
    getPresentation(presentation_id).then(_presentation => {
      if (_presentation) {
        let presenter_list = _presentation.presenter_list;
        // Setting state of presenter list for the hasItem property of SearchItem
        
        // REMOVE USER
        // Find the user index and remove it
        let user_index = presenter_list.indexOf(user_id);
        if (user_index > -1) presenter_list.splice(user_index, 1);
        updatePresentation(presentation_id, {
          presenter_list: presenter_list
        }).then(res => {})
      }
    })

    /* Remove the course id, session id and
    presentation id from the user's lists */
    getUser(user_id, true).then(_user => {
      if (_user) { // if non-empty result
        let updated_user = {};
        Object.assign(updated_user, _user);

        // update presentation list
        if (updated_user.presentation_list.includes(presentation_id)) {
          // REMOVE PRESENTATION
          let presentation_index = updated_user.presentation_list
              .indexOf(presentation_id);
          if (presentation_index > -1) updated_user.presentation_list
              .splice(presentation_index, 1);
        }

        // update session list
        getPresentation(presentation_id).then(_presentation => {
          if (_presentation) {
            let session_id = _presentation.session_id;
            /* Do nothing. Leaving the session in the user's session_list.
              Optionally: check if this is the only presentation of
              the user in this session and if yes - remove it */
            
            // update course list
            getSession(session_id).then(_session => {
              if (_session) {
                let course_id = _session.course_id;

                if (updated_user.course_list.includes(course_id)) {
                  /* Do nothing. Leaving the course in the user's course_list.
                    Optionally: check if this is the only presentation and
                  if yes - remove it */
                }
                // finishing user update
                delete updated_user["course_objects"];
                delete updated_user["session_objects"];
                delete updated_user["presentation_objects"];
                updateUser(user_id, updated_user).then(res => {})
              }
            })
          }
        })
      }
    })
  }

  addMember(user_id, presentation_id) {
    // Remove the id of the user from the presentation
    getPresentation(presentation_id).then(_presentation => {
      if (_presentation) {
        let presenter_list = _presentation.presenter_list;
        // Setting state of presenter list for the hasItem property of SearchItem
        
        // ADD USER
        presenter_list.push(user_id);

        updatePresentation(presentation_id, {
          presenter_list: presenter_list
        }).then(res => {})
      }
    })

    /* Remove the course id, session id and
    presentation id from the user's lists */
    getUser(user_id, true).then(_user => {
      if (_user) { // if non-empty result
        let updated_user = {};
        Object.assign(updated_user, _user);

        // update presentation list
        if (!updated_user.presentation_list.includes(presentation_id)) {
          // ADD PRESENTATION
          updated_user.presentation_list.push(presentation_id);
        }

        // update session list
        getPresentation(presentation_id).then(_presentation => {
          if (_presentation) {
            let session_id = _presentation.session_id;

            if (!updated_user.session_list.includes(session_id)) {
              // ADD SESSION
              updated_user.session_list.push(session_id);
            }

            // update course list
            getSession(session_id).then(_session => {
              if (_session) {
                let course_id = _session.course_id;

                if (!updated_user.course_list.includes(course_id)) {
                  /* Only add the course to the user's list
                  if it doesn't exist already */
                  updated_user.course_list.push(course_id);
                }
                // finishing user update
                delete updated_user["course_objects"];
                delete updated_user["session_objects"];
                delete updated_user["presentation_objects"];
                updateUser(user_id, updated_user).then(res => {})
              }
            })
          }
        })
      }
    })
  }

  // Press on session item
  onItemPressFunc = (itemId) => {
    // Item includes already in the list
    if (this.state.items_list.includes(itemId)) {
      if (this.props.type == "courses") {
        this.removeCourse(this.state.user, itemId);
      } else if (this.props.type == "sessions") {
        this.removeSession(itemId);
      } else if (this.props.type == "members") {
        this.removeMember(itemId, this.props.entity_id);
      } else if (this.props.type == "datasets") {
        //
      }
    // Item does not include in the list
    } else {
      if (this.props.type == "courses") {
        this.addCourse(this.state.user, itemId);
      } else if (this.props.type == "sessions") {
        this.addSession(itemId);
      } else if (this.props.type == "members") {
        this.addMember(itemId, this.props.entity_id);
      } else if (this.props.type == "datasets") {
        //
      }
    }
  };

  render() {
    return (
      <View style={styles.container}>
        <ScrollView>
          <FlatList
            data={this.props.data}
            scrollEnabled={true}
            extraData={this.state.items_list}
            keyExtractor={(item) => item._id}
            contentContainerStyle={{ marginHorizontal: 20, marginBottom: 10}}
            renderItem={({ item }) => (
              <SearchItem
                data={item}
                type={this.props.type}
                hasItem={this.state.items_list.includes(item._id)}
                onPress={() => this.onItemPressFunc(item._id)}
                memberList={this.props.type == "members"}
              />
            )}
          />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fafafa",
  },
});
