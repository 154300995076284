import * as React from 'react';
import { StyleSheet, Text, View, Platform, TouchableOpacity,
  ScrollView } from 'react-native';
import { Colors } from '../global/Constants';
import { ENCRYPTION_KEY, set_user, Role } from '../data/UserData'
import { getUserByEmail } from '../custom-modules/backendAPI';
import CryptoJS from "react-native-crypto-js";
import window from '../global/Layout.js'
import TextBox from '../components/TextBox';
import MainButton from '../components/MainButton';
import Logo from '../components/Logo'

export default function Login({route, navigation}) {
  
  const [emailAddress, setEmailAddress] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errors, setErrors] = React.useState([]);

  const login_button = React.createRef();

  function addError(newError) {
    let current_errors = errors;
    current_errors.push(newError)
    setErrors(current_errors);
  }

  function LogIn() {
    // Navigating to view according user's role
    if (Role == "listener") {
      navigation.navigate('StudentNavigation');
    } else {
      navigation.navigate('AdminNavigation');
    }
  }
  
  // Verify that the user credentials are valid
  async function verifyUser() {
    
    let newErrors = errors;
    while(newErrors.length > 0) newErrors.pop();
    setErrors(newErrors);

    // Check for errors in login process
    await getUserByEmail(emailAddress).then(_user => {
      if (_user == false) {
        addError(" Email does not exist");
      } else {
        let bytesPassword = CryptoJS.AES.decrypt(_user.password, ENCRYPTION_KEY);
        let originalPassword = bytesPassword.toString(CryptoJS.enc.Utf8);
        if (password != originalPassword) {
          addError(" Incorrect password");
        } else {
          set_user(_user.roles[0], _user._id, _user.profile_pic, _user.first_name);
        }
      }
    })

    // Presenting the errors or signing in successfully
    if (errors.length == 0) {
      LogIn();
    } else {
      if (Platform.OS == 'web') {
        alert(errors)
      } else {
        Alert.alert(
          "Error",
          errors,
          [ { text: "OK" } ] )
      }
    }
  }

  return (
    <ScrollView style={styles.scrollContainer}>
      <View style={styles.header}>
        <Logo />
      </View>
      <View style={styles.viewContainer}>
        <TextBox fieldTitle={"EMAIL ADDRESS"}
                onChangeText={setEmailAddress}
                onSubmitEditing={verifyUser}
                value={emailAddress}
                secureTextEntry={false}/>
        <TextBox fieldTitle={"PASSWORD"}
                onChangeText={setPassword}
                onSubmitEditing={verifyUser}
                value={password}
                secureTextEntry={true}/>
        <MainButton onPress={verifyUser} text={"Log in"} active={true} />
        
        <View style={{flexDirection: 'row'}}>
          <Text style={styles.signUpText}>New member? </Text>
          <TouchableOpacity onPress={() => navigation.navigate("Sign up")} >
            <Text style={[styles.signUpText, {textDecorationLine: 'underline'}]}>Sign up</Text>
          </TouchableOpacity>
        </View>

        <View style={{flexDirection: 'row'}}>
          <TouchableOpacity onPress={() => navigation.navigate("Verify Details")} >
            <Text style={[styles.signUpText, {textDecorationLine: 'underline'}]}>Forgot Password?</Text>
          </TouchableOpacity>
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
    backgroundColor: Colors.backgroundColor,
  },
  viewContainer: {
    alignItems: 'center',
    alignContent: 'flex-start',
    justifyContent: 'center',
    marginTop: window.window.height / 20,
  },
  signUpText: {
    color: Colors.ppTextColor,
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 15,
  },
  header: {
    alignItems: 'center',
    justifyContent: 'center',
  }, 
});

