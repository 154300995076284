import React, { Component } from 'react';
import { StyleSheet, View, TouchableOpacity, Text,
  FlatList } from 'react-native';
import window from "../global/Layout";
import { Colors } from '../global/Constants';
import UserIcon from './UserIcon';
import { getPresentation } from '../custom-modules/backendAPI';

export default class MembersContainer extends Component {

  constructor(props) {
    
    super(props);
    
    this.state = {
      presentation : {presenter_list : []},
      change_flag : false,
    }
  }

  componentDidMount() {
    this.loadData();
  }
  
  loadData() {
    getPresentation(this.props.presentation_id, true).then(_pres => {
      if (_pres) {
        this.setState({
          presentation : _pres,
          change_flag : !this.state.change_flag,
        })
      }
    })
  }

  render() {
    return (
      <FlatList
        data={this.state.presentation.presenter_objects}
        style={styles.memberList}
        extraData={this.state.change_flag}
        scrollEnabled={true}
        horizontal={true}
        keyExtractor={(item) => item._id}
        renderItem={({item}) => (
          <View style={styles.memberItem}>
            <UserIcon
              userIconDim={window.window.height / 20}
              profile_pic={item.profile_pic} />
            <Text style={{marginTop: 10}}>{item.username}</Text>
          </View>
        )}
        ListHeaderComponent={() =>
          {
          return this.state.presentation.presenter_list.includes(sessionStorage.getItem('UserId'))
            &&
            <TouchableOpacity style={styles.addMemberButton}
                              onPress={() => this.props.navigation.navigate("AddMember", {
                                addCourse: false,
                                text: "Search Members",
                                presentation_id: this.props.presentation_id,
                                onGoBack: () => this.loadData(),
                              })}>
              <Text style={styles.plusButtonText}>+</Text>
            </TouchableOpacity>
          }
        }
      />
    )
  }
}

const styles = StyleSheet.create({
  memberList: {
    width: window.window.width / 3,
    marginTop: window.window.height / 20,
    marginEnd: 20
  },
  memberItem: {
    alignItems: 'center',
    flexDirection: 'column',
    marginEnd: 10,
  },
  addMemberButton: {
    borderRadius: 90,
    borderWidth: 0.5,
    borderColor: Colors.ppMainPurple,
    justifyContent: 'center',
    alignItems: 'center',
    marginEnd: 10,
    width: window.window.height / 20,
    height: window.window.height / 20,
  },
  plusButtonText: {
    fontSize: window.window.height / 30,
    color: Colors.ppMainPurple,
    marginBottom: window.window.height / 300,
  },
});
