import * as React from 'react';
import { StyleSheet, Text, View, ScrollView } from 'react-native';
import window from '../global/Layout'
import { Colors, Strings } from '../global/Constants';
import SideMenu from '../components/SideMenu';
import Logo from '../components/Logo';
import MainButton from '../components/MainButton';
import SimpleFlatList from '../components/SimpleFlatList';

export default function DataTab({navigation}) {

  React.useEffect(() => {
  }, [navigation])

  return (
    <View style={styles.screenContainer}>
      <View style={{flexDirection: 'row', flex: 1}}>
        <SideMenu navigation={navigation} />
        <View style={styles.logoAndScrollContainer}>
          <Logo />
          <View style={styles.upperButtonContainer}>
            <MainButton active={true} text={"Create a New Dataset"} 
                square={false} onPress={() => {navigation.navigate('CreateDatasetScreen', {
                  text: Strings.SEARCH_PROMPT,
            })}} />
          </View>
          <ScrollView style={styles.scrollViewContainer}>
            <Text style={styles.title}>Datasets</Text>
            <View style={styles.dataSets}>
              <View style={styles.dataSetsContainer}>
                <Text style={styles.dataSetsTitles}>Datasets</Text>
                <Text style={styles.dataSetsTitles}>Last modified</Text>
              </View>
              <SimpleFlatList
                screen={"datasets"}
                navigation={navigation}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
}

DataTab.navigationOptions = {
  header: null,
};

const styles = StyleSheet.create({
  logoAndScrollContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  screenContainer: {
    flex: 1,
    flexDirection: "column",
  },
  scrollViewContainer: {
    marginStart: window.window.width / 6,
    backgroundColor: Colors.backgroundColor,
  },
  upperButtonContainer: {
    alignSelf: 'flex-end',
    marginBottom: window.window.height / 20,
  },
  dataSets: {
    marginTop: 30,
    borderRadius: 30,
    marginBottom: 25,
    backgroundColor: 'white',
    width: window.window.width / 1.5,
    height: window.window.height / 1.5,
  },
  title: {
    fontSize: window.window.height / 30,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  dataSetsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 20,
    marginBottom: 30,
    marginTop: 15,
  },
  dataSetsTitles: {
    fontWeight: 'bold',
    fontSize: 20,
    color: Colors.ppGreyText,
  },
});