import * as React from 'react';
import { StyleSheet, Text, View, Platform, Alert, TouchableOpacity,
  ScrollView } from 'react-native';
import { Colors } from '../global/Constants';
import {Picker} from '@react-native-picker/picker';
import { getUserByEmail } from '../custom-modules/backendAPI';
import window from '../global/Layout.js'
import TextBox from '../components/TextBox';
import MainButton from '../components/MainButton';
import Logo from '../components/Logo'


export default function VerifyDetails({route, navigation}) {
  
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailAddress, setEmailAddress] = React.useState("");
  const [role, setRole] = React.useState("listener");
  
  const [errorMsg, setErrorMsg] = React.useState(" User details are incorrect");
  
  const [errors, setErrors] = React.useState([]);

  function addError(newError) {
    let current_errors = errors;
    current_errors.push(newError)
    setErrors(current_errors);
  }

  function validateEmail() {
    const regexp = /\S+@\S+\.\S+/;
    return regexp.test(String(emailAddress).toLowerCase());
  }

  function validateName() {
    return (firstName.length > 1 && lastName.length > 1);
  }

  async function checkEmailExists() {
    await getUserByEmail(emailAddress).then(_user => {
      if (_user == false) {
        addError(errorMsg);
      }
    })
  }

  async function verifyDetails() {
    await getUserByEmail(emailAddress).then(_user => {
      if (_user.first_name == firstName && _user.last_name == lastName
        && _user.roles.includes(role)) {
          navigation.navigate("Reset Password", {
            userId: _user._id
          })
      } else {
        // Clearing the array of errors
        let newErrors = errors;
        while(newErrors.length > 0) newErrors.pop();
        setErrors(newErrors);

        addError(errorMsg);

        if (Platform.OS == 'web') {
          alert(errors)
        } else {
          Alert.alert(
            "Error",
            errors,
            [ { text: "OK" } ] )
        }
      }
    })
  }

  // Check if all details are valid
  async function ValidateInput() {
    // Clearing the array of errors
    let newErrors = errors;
    while(newErrors.length > 0) newErrors.pop();
    setErrors(newErrors);
    // Validating and setting errors if there are any
    // Check if email exists already
    await checkEmailExists();
    // Illegal email address
    if (!validateEmail()) {
      addError(errorMsg)
    }
    // Illegal name
    if (!validateName()) {
      addError(errorMsg)
    }
    if (role == "") {
      addError(" Please choose role")
    }
    // Presenting the errors or switching to verifying details
    if (errors.length == 0) {
      verifyDetails()
    } else {
      if (Platform.OS == 'web') {
        alert(errors)
      } else {
        Alert.alert(
          "Error",
          errors,
          [ { text: "OK" } ] )
      }
    }

  }

  return (
    <ScrollView style={styles.scrollContainer}>
      <View style={styles.header}>
        <Logo />
      </View>
      <View style={styles.viewContainer}>
        <Text style={{color: Colors.ppTextColor,
                      fontSize: 25,
                      fontWeight: 'bold',
                      marginBottom: 20}}>Reset Password</Text>
        <TextBox  fieldTitle={"FIRST NAME"}
          onChangeText={setFirstName}
          value={firstName}
          onSubmitEditing={ValidateInput}
        />
        <TextBox  fieldTitle={"LAST NAME"}
          onChangeText={setLastName}
          value={lastName}
          onSubmitEditing={ValidateInput}
        />
        <TextBox  fieldTitle={"EMAIL ADDRESS"}
          onChangeText={setEmailAddress}
          value={emailAddress}
          onSubmitEditing={ValidateInput}
        />
        <View>
          <Text style={styles.fieldTitle}>ROLE</Text>
          <View style={styles.dropdownMenuContainer}>
            <Picker
              selectedValue={role}
              onValueChange={value => setRole(value)}
              style={styles.picker}
            >
              <Picker.Item label="Admin" value="admin" />
              <Picker.Item label="Student" value="listener" />
            </Picker>
          </View>
        </View>
        <MainButton onPress={ValidateInput} text={"Verify Details"} active={true} />

        <View style={{flexDirection: 'row', marginBottom: window.window.height / 20}}>
          <TouchableOpacity onPress={() => navigation.navigate("Log in")} >
            <Text style={[styles.loginText, {textDecorationLine: 'underline'}]}>Back to Log in</Text>
          </TouchableOpacity>
        </View>
      </View>
    </ScrollView>
  );
}


const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
    backgroundColor: Colors.backgroundColor,
  },
  picker: {
    borderWidth: 0,
    borderRadius: 10,
    fontSize: window.window.height / 50,
    width: window.window.width / 5,
    height: window.window.height / 20,
  },
  viewContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: window.window.height / 40,
  },
  fieldTitle: {
    color: Colors.ppTextColor,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  loginText: {
    color: Colors.ppTextColor,
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 15,
  },
  dropdownMenuContainer: {
    borderWidth: 0,
    borderRadius: 10,
    marginBottom: 10,
    backgroundColor: 'white',
    width: window.window.width / 5,
    height: window.window.height / 20,
  },
  header: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});