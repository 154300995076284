import React, { Component } from 'react';
import { TouchableOpacity, StyleSheet, Text, View } from 'react-native';
import { Colors } from '../global/Constants.js';
import window from '../global/Layout.js'
import { Octicons } from '@expo/vector-icons';

export default class CancelSaveDeletePanel extends Component {

  render() {
    return (
      <View style={{flexDirection: 'row'}}>
        <TouchableOpacity onPress={this.props.cancel_func} style={styles.editButtonStyle}>
            <Text style={styles.buttonText}>CANCEL</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={this.props.save_func} style={styles.editButtonStyle}>
            <Text style={styles.buttonText}>SAVE</Text>
        </TouchableOpacity>
        { this.props.delete_option == true &&
          <TouchableOpacity onPress={this.props.delete_func}
            style={[styles.editButtonStyle, {borderColor: Colors.ppRed,
                                              width: window.window.height / 27}]}>
            <Octicons name="trashcan" size={24} color={Colors.ppRed} />
          </TouchableOpacity>
        }
      </View>
    )
  }
}

const styles = StyleSheet.create({
  editButtonStyle: {
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    borderColor: Colors.ppTextColor,
    marginEnd: window.window.width / 50,
    width: window.window.width / 15,
    height: window.window.height / 27,
  },
  buttonText: {
    color: Colors.ppTextColor,
    fontSize: window.window.height / 70,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
