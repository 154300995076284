import React, { Component } from 'react';
import { StyleSheet, TouchableOpacity, FlatList, View, Text } from 'react-native';
import { Colors } from '../global/Constants';
import window from '../global/Layout.js';
import QuestionStudent from './QuestionStudent';
import { getPresentation, getQuestionList, getSession,
  updatePresentation } from '../custom-modules/backendAPI';
import MainButton from '../components/MainButton';

export default class QuestionContainerStudent extends Component {
  
  constructor(props) {

    super(props);

    this.state = {
      questions: [],
      presentation: this.props.presentation,
      changeFlag: false,

      total_univ_pres_ques_num: 0,
    }
  }

  loadData() {
    getPresentation(this.props.presentation._id, true).then(_presentation => {
      if (_presentation) {
        getQuestionList(_presentation.question_list).then(_result => {
          if (_result) {
            getSession(_presentation.session_id).then(_session => {
              if (_session) {
                let total_num_questions = _session.presenter_questions_num;
                if (_session.universal_questions_bool) {
                  total_num_questions += _session.universal_questions.length;
                }
                this.setState({
                  questions : _result,
                  total_univ_pres_ques_num : total_num_questions,
                  changeFlag : !this.state.changeFlag,
                })
              }
            })
          }
        })
      }
    })
  }

  componentDidMount() {
    this.loadData();
  }

  changeQuestionIndex(array, fromIndex, toIndex) {
    var element = array[fromIndex];
    array.splice(fromIndex, 1);
    array.splice(toIndex, 0, element);
    return array;
  }

  moveQuestionUp(question) {
    // Find index of question in questions
    let question_idx = this.state.questions.indexOf(question);
    // If index is 0, return
    if (question_idx == 0) return;
    // Move up by one
    let newArray = this.changeQuestionIndex(this.state.questions, question_idx, question_idx - 1);
    this.setState({
      questions : newArray,
      changeFlag : !this.state.changeFlag
    })
    let new_question_id_list = []
    newArray.forEach(element => {
      new_question_id_list.push(element._id);
    });
    // Updating the new order of questions in the database
    updatePresentation(this.props.presentation._id, {
      question_list: new_question_id_list
    }).then(res => {})
  }

  moveQuestionDown(question) {
    // Find index of question in questions
    let question_idx = this.state.questions.indexOf(question);
    // If index is last one, return
    if (question_idx == (this.state.questions.length - 1)) return;
    // Move down by one
    let newArray = this.changeQuestionIndex(this.state.questions, question_idx, question_idx + 1);
    this.setState({
      questions : newArray,
      changeFlag : !this.state.changeFlag
    })
    let new_question_id_list = []
    newArray.forEach(element => {
      new_question_id_list.push(element._id);
    });
    // Updating the new order of questions in the database
    updatePresentation(this.props.presentation._id, {
      question_list: new_question_id_list
    }).then(res => {})
  }

  render() {
    return (
      <View>
        <Text style={styles.smallTitle}>Questions</Text>
        <View style={[styles.questionsContainer, {width: window.window.width / 1.5}]}>
          <FlatList
            data={this.state.questions}
            scrollEnabled={true}
            extraData={this.state.changeFlag}
            style={styles.questionList}
            keyExtractor={(item) => item._id}
            renderItem={({item}) => (
              <QuestionStudent moveUp={() => this.moveQuestionUp(item)}
                                moveDown={() => this.moveQuestionDown(item)}
                                question={item}
                                questions={this.state.questions}
                                presentation={this.state.presentation}
                                loadData={() => this.loadData()}
              />
            )}
            ListFooterComponent={() => {
              if (this.state.questions.length < this.state.total_univ_pres_ques_num) {
                return (
                  this.state.presentation.presenter_list.includes(sessionStorage.getItem('UserId')) &&
                    <TouchableOpacity style={styles.addQuestionContainer}
                                      onPress={() => {this.props.navigation.navigate('AddQuestion', {
                                        presentation: this.state.presentation,
                                        onGoBack: () => this.loadData(),
                                      })}}>
                      <Text style={{fontSize: window.window.height / 30}}>+</Text>
                    </TouchableOpacity>
                )
              } else return (null)
            }}
          />
        </View>
        {this.state.presentation.question_list.length > 0 &&
          <View style={{alignSelf: 'flex-end'}}>
            <MainButton active={true} text={"View All"} small={false}
            square={false} onPress={() => {this.props.navigation.navigate("Questions", {
              question_list: this.state.presentation.question_list,
              presentation_id: this.state.presentation._id,
              updateData: this.props.updateData,
              session_id: this.state.presentation.session_id,
            })}} />
          </View>
        }
      </View>
    )
  }
}

const styles = StyleSheet.create({
  questionList: {
    width: window.window.width * (1/3),
  },
  addQuestionContainer: {
    width: window.window.width / 4,
    height: window.window.height / 20,
    marginTop: window.window.height / 20,
    marginBottom: window.window.height / 20,
    marginStart: window.window.width / 30,
    borderColor: Colors.ppDarkGreyText,
    borderRadius: 10,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  smallTitle: {
    fontSize: window.window.height / 45,
    marginTop: window.window.height / 45,
    marginEnd: window.window.width / 30,
    marginBottom: 10,
    color: Colors.ppGreyText,
    fontWeight: 'bold',
  },
  questionsContainer: {
    borderRadius: 30,
    marginBottom: 10,
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: window.window.height / 30,
    width: window.window.width / 3.2,
    height: window.window.height / 1.5,
  },
});
