import * as React from "react";
import { StyleSheet, View, ScrollView, Text } from "react-native";
import { useState } from "react";
import SideMenu from "../components/SideMenu";
import MainButton from "../components/MainButton";
import window from "../global/Layout";
import SeparatingLine from "../components/SeparatingLine";
import BackButton from "../components/BackButton";
import EditButton from "../components/EditButton";
import Logo from "../components/Logo";
import { Colors } from "../global/Constants";
import SimpleFlatList from "../components/SimpleFlatList";

export default function CourseScreen({ route, navigation }) {
  const [course, setCourse] = useState(
    route.params.course ? route.params.course : null
  );

  React.useEffect(() => {
    loadData();
    const onFocus = navigation.addListener('focus', () => {
      loadData();
    });
    return onFocus;
  }, [navigation]);
  
  function loadData() {    
    if (route.params.course) {
      setCourse(route.params.course);
    }
  }

  return (
    <View style={styles.screenContainer}>
      <View style={{ flexDirection: "row", flex: 1 }}>
        <SideMenu navigation={navigation} />
        <View style={styles.logoAndScrollContainer}>
          <Logo />
          <View style={styles.upperButtonContainer}>
            {(sessionStorage.getItem('Role') != "listener") &&
              <MainButton
                active={true}
                text={"Create Session"}
                square={false}
                onPress={() => {
                  navigation.navigate("CreateEditSession", {
                    course_id: course._id,
                    create: true,
                    onGoBack: () => loadData(),
                  });
                }}
              />
            }
          </View>
          <BackButton backTo={"Courses"} navigation={navigation} />
          <View style={{marginStart: window.window.width / 6}} >
            <SeparatingLine />
          </View>
          <ScrollView style={styles.scrollViewContainer}>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.title}>{course.title}</Text>
              {(sessionStorage.getItem('Role') != "listener") &&
                <EditButton text={"EDIT COURSE"} onPress={() => {navigation.navigate('EditCourse', {
                            course: course,
                })}} />
              }
            </View>
            <View style={styles.upcomingSessions}>
              <View style={styles.upcomingTitlesContainer}>
                <Text style={styles.upcomingTitle}>Upcoming Sessions</Text>
                <Text style={styles.upcomingTitle}>End Date</Text>
              </View>
              <SimpleFlatList
                screen={"upcomingSessions"}
                navigation={navigation}
                course_id={course._id}
              />
            </View>

            <View style={styles.upcomingSessions}>
              <View style={styles.upcomingTitlesContainer}>
                <Text style={styles.upcomingTitle}>Past Sessions</Text>
                <Text style={styles.upcomingTitle}>End Date</Text>
              </View>
              <SimpleFlatList
                screen={"pastSessions"}
                navigation={navigation}
                course_id={course._id}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  logoAndScrollContainer: {
    justifyContent: 'center',
  },
  scrollViewContainer: {
    marginStart: window.window.width / 6,
    backgroundColor: Colors.backgroundColor,
  },
  screenContainer: {
    flex: 1,
    flexDirection: "column",
  },
  title: {
    fontSize: window.window.height / 30,
    marginTop: window.window.height / 30,
    marginEnd: window.window.width / 30,
    marginBottom: 10,
    fontWeight: "bold",
  },
  upperButtonContainer: {
    alignSelf: "flex-end",
    marginBottom: window.window.height / 20,
    marginTop: window.window.height / 100,
  },
  upcomingSessions: {
    marginTop: 30,
    borderRadius: 30,
    marginBottom: 25,
    backgroundColor: "white",
    width: window.window.width / 1.5,
    height: window.window.height / 3,
  },
  upcomingTitlesContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: 20,
    marginBottom: 30,
    marginTop: 15,
  },
  upcomingTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: Colors.ppGreyText,
  },
});
